import React from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "shards-react";
import AxiosPost from "../common/AxiosPost";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withRouter } from "react-router-dom";

import SGActionsCard from "../dashboard_widgets/SGActionsCard";

class AddEditMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      id: "",
      stn_Challan_Number: "",
      delivery_Number: "",
      goods_Reciept_Number: "",
      product_Name: "",
      quantity: "",
      vehicle_Number: "",
      transporter: "",
      source_Plant: "",
      source_Place: "",
      destination_Plant: "",
      destination_Place: "",
      item_Name: "",
      sap_Number: "",
      customer_Name: "",
      product_Temp: "",
      weight_of_Unit: "",
      eway_Bill_URI: "",
      grnStn_Number: "",
      grnStn_URI: "",
      invoice_Number: "",
      invoice_URI: "",
      date_Of_Dispatch: "",
      showspinner: false,
      successmsg: false
    };
    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    //console.log("Material out submit function ");
    this.setState({ showspinner: true });
    //console.log(this.state);
    event.preventDefault();
    const data = { ...this.state, docking: "out", inorout: "out" };
    delete data["showspinner"];
    delete data["successmsg"];
    //console.log("material out submit data ", data);
    let url = null;
    if (this.props.history.location.state)
      url = `/${this.props.history.location.state.endpoint}`;
    else url = `/material_outward_register`;

    //console.log("url::::", url);
    AxiosPost(
      url,
      data,
      res => {
        window.scrollTo(0, 0);
        this.setState({ showspinner: false, successmsg: true });
        //console.log(res);
      },
      () => {
        this.setState({ showspinner: false });
      }
    );

    //  this.props.onFormSubmit(this.state);
    //   this.setState(this.initialState);
  }
  render() {
    let pageTitle;
    pageTitle = "Material Out";

    if (this.state.successmsg)
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10
            }}
          >
            <Col md="3">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
          </Row>
        </div>
      );

    return (
      <Card small className="m-3 m-md-4">
        <LoadingOverlay
          active={this.state.showspinner}
          spinner
          text="Processing..."
        >
          <CardBody>
            <CardTitle className="p-3">{pageTitle}</CardTitle>
            <div className="p-3">
              <Row>
                <Col sm={12}>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="stn_Challan_Number">
                          <Form.Label>STN Challan Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="stn_Challan_Number"
                            value={this.state.stn_Challan_Number}
                            onChange={this.handleChange}
                            placeholder="STN Challan Number"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="delivery_Number">
                          <Form.Label>Delivery Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="delivery_Number"
                            value={this.state.delivery_Number}
                            onChange={this.handleChange}
                            placeholder="Delivery Number"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="goods_Reciept_Number">
                          <Form.Label>Goods Reciept Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="goods_Reciept_Number"
                            value={this.state.goods_Reciept_Number}
                            onChange={this.handleChange}
                            placeholder="Goods Reciept Number"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="productName">
                          <Form.Label>Product Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="product_Name"
                            value={this.state.product_Name}
                            onChange={this.handleChange}
                            placeholder="Product Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="Quantity">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="quantity"
                            value={this.state.quantity}
                            onChange={this.handleChange}
                            placeholder="Quantity"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="vehicle_Number">
                          <Form.Label>Vehicle Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="vehicle_Number"
                            value={this.state.vehicle_Number}
                            onChange={this.handleChange}
                            placeholder="Vehicle Number"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="transporter">
                          <Form.Label>Transporter</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="transporter"
                            value={this.state.transporter}
                            onChange={this.handleChange}
                            placeholder="Transporter"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="source_Plant">
                          <Form.Label>Source Plant</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="source_Plant"
                            value={this.state.source_Plant}
                            onChange={this.handleChange}
                            placeholder="Source Plant"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="source_Place">
                          <Form.Label>Source Place</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="source_Place"
                            value={this.state.source_Place}
                            onChange={this.handleChange}
                            placeholder="Source Place"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="destination_Plant">
                          <Form.Label>Destination Plant</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="destination_Plant"
                            value={this.state.destination_Plant}
                            onChange={this.handleChange}
                            placeholder="Destination Plant"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="destination_Place">
                          <Form.Label>Destination Place</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="destination_Place"
                            value={this.state.destination_Place}
                            onChange={this.handleChange}
                            placeholder="Destination  Place"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="item_Name">
                          <Form.Label>Item Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="item_Name"
                            value={this.state.item_Name}
                            onChange={this.handleChange}
                            placeholder="Item Name"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="sap_Number">
                          <Form.Label>SAP Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="sap_Number"
                            value={this.state.sap_Number}
                            onChange={this.handleChange}
                            placeholder="SAP Number"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="customer_Name">
                          <Form.Label>Customer Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="customer_Name"
                            value={this.state.customer_Name}
                            onChange={this.handleChange}
                            placeholder="Customer Name"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="product_Temp">
                          <Form.Label>Product Temp</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="product_Temp"
                            value={this.state.product_Temp}
                            onChange={this.handleChange}
                            placeholder="Product Temp"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="weight_of_Unit">
                          <Form.Label>Weight of Unit</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="weight_of_Unit"
                            value={this.state.weight_of_Unit}
                            onChange={this.handleChange}
                            placeholder="Weight of Unit"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="eway_Bill_URI">
                          <Form.Label>Eway Bill URI</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="eway_Bill_URI"
                            value={this.state.eway_Bill_URI}
                            onChange={this.handleChange}
                            placeholder="Eway Bill URI"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="grnStn_Number">
                          <Form.Label>GRNSTN Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="grnStn_Number"
                            value={this.state.grnStn_Number}
                            onChange={this.handleChange}
                            placeholder="GRNSTN Number"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="grnStn_URI">
                          <Form.Label>GRNSTN URI</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="grnStn_URI"
                            value={this.state.grnStn_URI}
                            onChange={this.handleChange}
                            placeholder="GRNSTN URI"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="invoice_Number">
                          <Form.Label>Invoice Number</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="invoice_Number"
                            value={this.state.invoice_Number}
                            onChange={this.handleChange}
                            placeholder="Invoice Number"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="invoice_URI">
                          <Form.Label>Invoice URIr</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="invoice_URI"
                            value={this.state.invoice_URI}
                            onChange={this.handleChange}
                            placeholder="Invoice URI"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group controlId="date_Of_Dispatch">
                          <Form.Label>Date Of Dispatch</Form.Label>
                          <Form.Control
                            required
                            type="date"
                            name="date_Of_Dispatch"
                            value={this.state.date_Of_Dispatch}
                            onChange={this.handleChange}
                            placeholder="Date Of Dispatch"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Control
                        required
                        type="hidden"
                        name="id"
                        value={this.state.id}
                      />
                      <Button
                        theme="accent"
                        type="submit"
                        size="sm"
                        className="ml-auto"
                      >
                        <i className="material-icons"></i>Submit
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>
          </CardBody>
        </LoadingOverlay>
        <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
        `}</style>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url
});
export default withRouter(connect(mapStateToProps)(AddEditMaterial));
