import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { CardHeader, ListGroupItem } from "shards-react";

class AddEditPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      mobileNumber: "",
      otpEntered: "",
      name: "",
      fathersName: "",
      gender: "",
      id: "",
      address: "",
      currentAccessLocation: "",
      currentAccessDate: "",
      currentAccessTime: "",
      dOB: "",
      visitorCompanyIdCard: "",
      visitorBusinessCard: "",
      roleId: "",
      authorisationId: "",
      presentCompany: "",
      dailyWageRate: "",
      reportingManager: "",
      esicNumber: "",
      labauthorisationId: "",
      whomToMeetorReport: "",
      drivingLicense: "",
      driverPresentCompany: "",
      isDriverSober: "",
      approvalDateAndTime: "",
      personType: "null",
      idImage: ""
    };
    if (props.product) {
      this.state = props.product;
    }
  }
  handleDate = date => {
    //console.log("We are in the handle date function");
    //console.log(date.toDateString());
    this.setState({
      dOB: date
    });
  };
  handleDateAndTime = dateandTime => {
    //console.log("We are in the date and Time function");
    this.setState({
      approvalDateAndTime: dateandTime
    });
  };
  handleChange(event) {
    //console.log("We are in the change function");
    //console.log(event.target.id);
    const name = event.target.id;
    const value = event.target.value;
    //console.log(name);
    //console.log(value);
    this.setState({
      [name]: value
    });
    //console.log("We are displaying the state");
    //console.log(this.state);
  }

  mapFields(data) {
    return data.map(
      item =>
        item.val && (
          <Col sm="6" key={item} className="my-2">
            <div className="row">
              <div className="col-auto pr-0">{item.title}:</div>
              <div className="col pl-1" style={{ fontWeight: "bold" }}>
                {item.val}
              </div>
            </div>
          </Col>
        )
    );
  }

  render() {
    let state = this.state;

    // let upper=(({name,fathersName,gender,personID,homeAddress,currentAccessLocation,currentAccessTime,currentAccessDate,dOB,personType})=>(({name,fathersName,gender,personID,homeAddress,currentAccessLocation,currentAccessTime,currentAccessDate,dOB,personType})))(this.state)
    let upper = [
      {
        title: "Name",
        val: state.name
      },
      {
        title: "Father Name",
        val: state.fathersName
      },
      {
        title: "Gender",
        val: state.gender
      },
      {
        title: "Mobile Number",
        val: state.mobileNumber
      },
      {
        title: "Person ID",
        val: state.personID
      },
      {
        title: "Address",
        val: state.homeAddress
      },
      {
        title: "Pincode",
        val: state.pincode
      },
      {
        title: "City",
        val: state.city
      },
      {
        title: "State",
        val: state.state
      },
      {
        title: "Current Access Time",
        val: state.currentAccessTime
      },
      {
        title: "current Access Date",
        val: state.currentAccessDate
      },
      {
        title: "Date of Birth",
        val: state.dOB
      },
      {
        title: "Person Type",
        val: state.personType
      }
    ];
    //console.log(upper);
    let below = {
      Customer: [
        {
          title: "Company Name",
          val: state.presentCompany
        }
      ],
      Labour: [
        {
          title: "Reporting Manager",
          val: state.reportingManagerName
        },
        {
          title: "Daily Wage Rate",
          val: state.dailyWageRate
        },
        {
          title: "ESIC Number",
          val: state.esicNumber
        }
      ],
      "Truck Driver": [
        {
          title: "Customer Name",
          val: state.Customer_Name
        },
        {
          title: "Driving License",
          val: state.drivingLicense
        },
        {
          title: "Transporter Company",
          val: state.driverPresentCompany
        },
        {
          title: "Was Driver Sober?",
          val: state.isDriverSober
        }
      ],
      Visitor: [
        {
          title: "Whom To Meet",
          val: state.whomToMeet
        }
      ],
      Intern: [
        {
          title: "Whom To Meet",
          val: state.whomToMeetorReport
        },
        {
          title: "Intern Code",
          val: state.EmployeeCode
        }
      ],
      Supplier: [
        {
          title: "Whom To Meet",
          val: state.whomToMeetorReport
        }
      ],
      "Contracted Employee": [
        {
          title: "Reporting Manager",
          val: state.reportingManagerName
        }
      ]
    };

    return (
      <Row className="mt-3">
        <Col sm={12}>
          <Form>
            <ListGroupItem className="px-2 pt-0 border-0">
              <Row>
                {this.mapFields(upper)}

                <Col sm="10">
                  {state.idImage && (
                    <div>
                      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                        ID Photo:
                      </p>
                      <img
                        src={state.idImage}
                        alt="ID"
                        className="img-fluid"
                        style={{ maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              {/* showing second form data */}
              {/*               
              {state.personType !== "Employee" && (
                <div className="d-block">
                  <CardHeader className="border-bottom pl-0">
                    <h6 className="mb-0">{state.personType} Details</h6>
                  </CardHeader>
                  <ListGroupItem className="p-4">
                    <Row>{this.mapFields(below[state.personType])}</Row>
                  </ListGroupItem>
                </div>
              )} */}
            </ListGroupItem>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default AddEditPerson;
