import React from "react";

function ViewMenu() {
  return (
    <div
      className="d-flex flex-column position-absolute p-3"
      style={{
        zIndex: 1,
        top: "-15px",
        right: "15px",
        backgroundColor: "#e3eaeb21"
      }}
    >
      <button className="btn btn-sm btn-dark my-1">View Customer</button>
      <button className="btn btn-sm btn-dark">View Vehicle</button>
      <button className="btn btn-sm btn-dark my-1">View Material</button>
    </div>
  );
}

export default ViewMenu;
