import {
  FETCH_POSTS,
  NEW_POST,
  DASHBOARD_DATA,
  LOCATION
} from "../actions/types";

const initialState = {
  url: "http://moolwms.us-east-2.elasticbeanstalk.com",
  //url: "http://localhost:5000",
  //url:'http://localhost:5000/',
  //right now ,roles are disabled
  dashboard_data: sessionStorage.dashboard_data
    ? JSON.parse(sessionStorage.dashboard_data)
    : null,
  location: sessionStorage.selectedLocation
    ? JSON.parse(sessionStorage.selectedLocation)
    : null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        items: action.payload
      };
    case NEW_POST:
      return {
        ...state,
        item: action.payload
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data: action.payload
      };

    case LOCATION:
      return {
        ...state,
        location: action.payload
      };
    default:
      return state;
  }
}
