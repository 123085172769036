import React from "react";
import { Link, useHistory } from "react-router-dom";
import dummyImage from "../../images/content-management/profile.png";
import "./Profile.css";
function Profile() {
  let history = useHistory();
  const logOut = () => {
    sessionStorage.removeItem("dashboard_data");
    sessionStorage.removeItem("selectedLocation");
    sessionStorage.removeItem("token");
    history.push("/");
  };
  return (
    <div className="ProfileContainer pt-1">
      <div className="d-flex flex-md-row flex-column mt-md-4  px-md-4">
        <div className="leftProfilePart mt-md-5 mt-2">
          <img src={dummyImage} className="profileImage" />
          <button className="btn btn-dark d-block mx-auto mt-3">
            Change Password
          </button>
          <Link
            to={{ pathname: "/View_Attendance", mobileNumber: "8826871947" }}
          >
            <button className="btn btn-dark d-block mx-auto mt-3">
              View Attendance
            </button>
          </Link>
        </div>
        <div className="rightProfilePart">
          <h4>Ramdev Singh</h4>
          <hr />
          <p>
            <span>Role</span>:<span>Admin</span>
          </p>
          <p>
            <span>DOB</span>:<span>11/01/1980</span>
          </p>
          <p>
            <span>Mobile Number</span>:<span>8826871943</span>
          </p>
          <p>
            <span>Last Logged In</span>:<span>11/06/2020 at 8:00AM</span>
          </p>
        </div>
      </div>
      <button
        className="ProfileLogOut btn  btn-danger position-absolute"
        style={{ bottom: "0px" }}
        onClick={logOut}
      >
        Log Out
      </button>
    </div>
  );
}

export default Profile;
