import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../common/TableView";
import AxiosPost from "../../common/AxiosPost";
class ViewAllPersonWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      headings: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
      this.fetchTotal();
    }
  }
  //for fetching total number in facilty
  fetchTotal = () => {
    AxiosPost("/get_ptype_num", {}, res => {
      //console.log(res);
      if (res.data) {
        if (this.props.setNumber) {
          let total = 0;
          res.data.forEach(ele => {
            total += ele.pcount;
          });
          //console.log(total);
          this.props.setNumber(total);
        }
      }
    });
  };

  fetch = () => {
    AxiosPost(
      `/person_in_out_table`,
      {
        start: 0,
        length: this.props.limitRecords ? 5 : 10000
      },
      res => {
        //console.log(res);
        this.setState({ isLoading: false });
        if (res.data) {
          let filteredData = res.data.filter(
            item => item.inorout === "in" || item.inorout === "out"
          );
          let ptype = this.props.ptype;
          if (ptype && ptype !== "all") {
            filteredData = filteredData.filter(i => i.personType === ptype);
            this.setState({
              data: filteredData.map(p => ({
                icon: p.inorout,
                title: p.name,
                pMobileNumber: p.mobileNumber,
                time: p.inorout === "in" ? p.in_time : p.out_time,
                //url: `/person?mobileNumber=${p.mobileNumber}`
                info: p.mobileNumber
              }))
            });
            this.setState({
              headings: ["IN/OUT", "Name", "Mobile Number", "Date & Time"]
            });
          } else {
            this.setState({
              data: filteredData.map(p => ({
                icon: p.inorout,
                title: p.name,
                pMobileNumber: p.mobileNumber,
                //getting first and second letter of ptype
                personType:
                  p.personType.split(" ").length === 1
                    ? p.personType.split(" ")[0][0]
                    : p.personType.split(" ")[0][0] +
                      p.personType.split(" ")[1][0],
                time: p.inorout === "in" ? p.in_time : p.out_time,
                //url: `/person?mobileNumber=${p.mobileNumber}`
                info: p.mobileNumber
              }))
            });
            this.setState({
              headings: [
                "IN/OUT",
                "Name",
                "Mobile Number",
                "Type",
                "Date & Time"
              ]
            });
          }

          //console.log(filteredData);
        }
      }
    );
  };
  handleChange = info => {
    //console.log(info);
    //console.log("clocked");
    this.props.history.push({ pathname: "/person", mobileNumber: info });
  };
  componentDidMount() {
    this.fetch();
    this.fetchTotal();
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="p-3">
          <div className="spinner-border d-block m-auto" />
        </div>
      );

    return (
      <TableView
        data={this.state.data}
        handleChange={info => this.handleChange(info)}
        headings={!this.props.limitRecords && this.state.headings}
      />
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewAllPersonWrapper));
