import React from "react";
import { Card, CardBody, Button } from "shards-react";
import { Form } from "react-bootstrap";
import AxiosPost from "../common/AxiosPost";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

function OTPcard({ item, setDisplay }) {
  const [loader, setLoader] = React.useState(false);
  const [otp, setOTP] = React.useState();
  let history = useHistory();

  function handleOTP(e) {
    if (e) e.preventDefault();
    console.log(otp);
    setLoader(true);
    let data = { otp: otp, id: item._id.$oid };
    AxiosPost(
      "/check_supervisor_otp",
      data,
      res => {
        setLoader(false);
        console.log(res);
        if (res.status === 200 && res.data.Status === " Okay! ") {
          PNotify.success("Successfully Asssigned");
          history.push("/dashboard");
        } else {
          PNotify.error("Something Went Wrong");
        }
      },
      () => {
        setLoader(false);
        PNotify.error("Something Went Wrong");
      }
    );
  }
  return (
    <div
      style={{
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Card small className="m-3 m-md-4" style={{ width: 400 }}>
        <LoadingOverlay active={loader} spinner text="Please wait...">
          <CardBody className="p-md-5 p-4">
            <h4 className="mb-5">OTP Verification</h4>
            <Form onSubmit={handleOTP}>
              <label htmlFor="otpEntered">Enter OTP received:</label>
              <OtpInput
                value={otp}
                onChange={i => setOTP(i)}
                numInputs={6}
                //otpContainerStyle class is in Common.css
                containerStyle="otpContainerStyle"
                inputStyle={{
                  width: 40,
                  height: 55,
                  background: "#e8f5f5",

                  borderRadius: 6
                }}
                focusStyle={{ outline: "none" }}
              />
              <button type="submit" className="btn btn-accent d-block mx-auto">
                Submit
              </button>
            </Form>
          </CardBody>
        </LoadingOverlay>
      </Card>
    </div>
  );
}

export default OTPcard;
