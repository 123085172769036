import React from "react";
import { Row, Form, Col } from "react-bootstrap";
class AddEditVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      _id: "",
      vehicleType: "",
      owner_Type: "",
      owner_Id: "",
      vehicle_Insurance: "",
      insurance_Expiry: "",
      last_Date_Time: "",
      last_Location: "",

      vehicle_Number: "",
      vehicle_RC: ""
    };

    if (props.product) {
      this.state = props.product;
    }
  }

  render() {
    //let pageTitle;
    //pageTitle = <h3>View Vehicle</h3>;
    let fields = [
      {
        title: "Vehicle Number",
        val: this.state.vehicleNumber
      },
      {
        title: "Owner Name",
        val: this.state.ownerName
      },
      {
        title: "Owner Type",
        val: this.state.owner_Type
      },
      {
        title: "Mobile No",
        val: this.state.mobileNumber
      },
      {
        title: "Vehicle Type",
        val: this.state.vehicleType
      },
      {
        title: "Vehicle Insurance",
        val: this.state.vehicle_Insurance
      },
      {
        title: "Insurance Expiry",
        val: this.state.insurance_Expiry
      },
      {
        title: "Vehicle RC",
        val: this.state.vehicle_RC
      }
    ];
    if (this.state.owner_Type === "Other")
      fields[2].val = this.state.otherOwnerType;

    return (
      <Row>
        <Col>
          <span
            className="p-1 float-right"
            style={
              this.state.inorout === "in"
                ? { color: "#46a846", border: "1px solid #46a846" }
                : { color: "#d90c0c", border: "1px solid #d90c0c" }
            }
          >
            {this.state.inorout === "in" ? "Vehicle IN" : "Vehicle OUT"}
          </span>
        </Col>
        <Col sm={12}>
          <Form>
            <Row>
              {fields.map(item => (
                <Col sm="6" key={item.title} className="my-2">
                  <div className="row">
                    <div className="col-auto pr-0">{item.title}:</div>
                    <div className="col pl-1" style={{ fontWeight: "bold" }}>
                      {item.val}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Form>

          {/* <Form>
            <Row>
              <Col md="6">
                <Form.Group controlId="vehicle_Type">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Vehicle Type
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.vehicle_Type}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="owner_Type">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Owner Type
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.owner_Type}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group controlId="owner_Id">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Owner Mobile Number
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.mobileNumber}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="vehicle_Insurance">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Vehicle Insurance
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.vehicle_Insurance}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group controlId="insurance_Expiry">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Insurance Expiry
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.insurance_Expiry}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="last_Date_Time">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Last Date/Time
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.last_Date_Time}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group controlId="last_Location">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Last Location
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.last_Location}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="driver_ID">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Driver ID
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.driver_ID}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group controlId="vehicle_Number">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Vehicle Number
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.vehicleNumber}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="vehicle_RC">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Vehicle RC
                  </Form.Label>
                  <Col md="3" style={{ float: "right" }}>
                    {this.state.vehicle_RC}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Control type="hidden" name="id" value={this.state.id} />
            </Form.Group>
          </Form> */}
        </Col>
      </Row>
    );
  }
}

export default AddEditVehicle;
