import React from "react";
import axiosPost from "../../common/AxiosPost";
import { connect } from "react-redux";
import { Card, CardBody } from "shards-react";
import ViewMaterial from "./view_material";

import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

class ViewMaterialDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true
    };
  }

  componentDidMount() {
    if (!this.props.location.oid) {
      this.props.history.push("/dashboard");
      PNotify.info("Please Select Material First");
      return;
    }
    axiosPost(
      "/get_material_entry",
      {
        snoid: this.props.location.oid
      },
      res => {
        // console.log(res);
        this.setState({ isLoading: false });
        if (res.data)
          this.setState({
            data: res.data
          });
      }
    );
  }

  render() {
    return (
      <Card small className="m-3 m-md-4">
        <CardBody className="m-3">
          <h3
            className="px-2 d-inline-block"
            style={{
              fontSize: "x-large"
            }}
          >
            Material Details
          </h3>
          <div className="px-1">
            {this.state.isLoading && (
              <div className="spinner-border d-block m-auto" />
            )}
            {this.state.data && <ViewMaterial product={this.state.data} />}
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  selectedLocation: state.auth.location
});

export default connect(mapStateToProps)(ViewMaterialDetails);
