import React from "react";
import EditDeleteTable from "../common/EditDeleteTable";
import CardHeading from "../common/CardHeading";
import { Card, CardBody, CardTitle } from "shards-react";

function View_Customers() {
  const url = {
    endpoint: "get_locations",
    edit: "update_loc_entry",
    delete: "delete_loc"
  };
  const EditRoute = "/AddEditLocation";
  const deleteKey = {
    key: "_id",
    bodykey: "snoid"
  };
  const keyinfo = [
    {
      keyname: "locName",
      displayName: "Location"
    },
    {
      keyname: "locAddress",
      displayName: "Location Address"
    },
    {
      keyname: "personIncharge",
      displayName: "Person Incharge"
    },
    {
      keyname: "inChargePhn",
      displayName: "Incharge Phone"
    }
  ];

  return (
    <div className="mx-3">
      <Card small className="m-3 m-md-4">
        <CardBody>
          <CardTitle className="p-3">
            <CardHeading
              title={"Locations"}
              displayLocation={"All Locations"}
              icon={"location_on"}
            />
          </CardTitle>

          <EditDeleteTable
            url={url}
            keyinfo={keyinfo}
            EditRoute={EditRoute}
            deleteKey={deleteKey}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default View_Customers;
