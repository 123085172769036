import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../Material.css";

class AddEditMaterial extends React.Component {
  constructor(props) {
    //console.log("props are", props);
    super(props);
    this.state = {
      //get all values from form field
      id: "",
      items: [],

      //Basic
      sysGenGRN: "", //this is to be shown after form submission,ill be generated by backend
      vehicleNumber: "",
      personName: "",
      personId: "",
      dirOfMovement: "",
      dateOfMovement: "",
      Materialtype: "",

      //common second form fields
      quantity: "",
      name: "",
      category: "",
      source: "",
      destination: this.props.location,

      // storage material
      transporter: "",
      sapNumber: "",
      temperature: "",
      weight: "",
      grnstnNumber: "",
      grnstnUri: "",
      invoiceUri: "",
      stnChallan: "",
      deliveryNumber: "",
      totalTonnage: "",

      //Storage -> items field
      storageItems: [], // array will store items under type-Storage Material
      skuCode: "",
      desc: "",
      unit: "",
      batchumber: "",
      prodDate: "",
      expiryDate: "",
      expQty: "",
      recQty: "",
      status: "",
      recDate: "",

      //personal Item
      //all personal item fields are in common

      //documents
      fileNo: "",
      //name key is ued for document description

      //diesel
      billNo: "",

      //assets and spare
      purchaseInvoice: "",

      mobileNumber: "",
      showspinner: false
    };
    if (props.product) {
      this.state = props.product;
    }
  }

  mapFields = (fields, item) =>
    fields.map(
      el =>
        item[el.name] && (
          <Col sm="6" key={el.name} className="my-2">
            <div className="row">
              <div className="col-auto pl-0 pr-1">{el.title}:</div>
              <div className="col px-0" style={{ fontWeight: "bold" }}>
                {item[el.name]}
              </div>
            </div>
          </Col>
        )
    );
  render() {
    let AllFields = {
      BasicFields: [
        {
          title: "Customer",
          name: "customerName"
        },
        {
          title: "Name",
          name: "personName"
        },

        {
          title: "Driver Phone No",
          name: "personId"
        },
        {
          title: "Date of Movement",
          name: "dateOfMovement"
        },
        {
          title: " Direction of Movement",
          name: "dirOfMovement"
        }
      ],
      "Storage Material": [
        {
          title: "GRN",
          name: "grnstnNumber"
        },
        {
          title: "Invoice Number",
          name: "invoiceUri"
        },
        {
          title: "STN Challan Number",
          name: "stnChallan"
        },
        {
          title: "Total Tonnage",
          name: "totalTonnage"
        },
        {
          title: "Destination",
          name: "destination"
        },
        {
          title: "Source",
          name: "source"
        }
      ],
      storageItems: [
        {
          title: "Item",
          name: "name"
        },
        {
          title: "SKU Code",
          name: "skuCode"
        },
        {
          title: "Batch Number",
          name: "batchnumber"
        },
        {
          title: "Weight of Unit (KG)",
          name: "weight"
        },
        {
          title: "Unit",
          name: "unit"
        },
        {
          title: "Quantity",
          name: "quantity"
        },
        {
          title: "Temperature",
          name: "temperature"
        },
        {
          title: "Production Date",
          name: "prodDate"
        },
        {
          title: "Expiry Date",
          name: "expiryDate"
        },
        {
          title: "Remarks",
          name: "remarks"
        }
      ],
      "Personal Item": [
        {
          title: "Product Name",
          name: "name",
          required: true
        },
        {
          title: "Quantity",
          name: "quantity",
          required: true
        },
        {
          title: "Source",
          name: "source"
        },
        {
          title: "Destination",
          name: "destination"
        }
      ],
      Assets: [
        {
          title: "Name",
          name: "name",
          required: true
        },
        {
          title: "Quantity",
          name: "quantity",
          required: true
        },
        {
          title: "Category",
          name: "category"
        },
        {
          title: "Purchase Invoice",
          name: "purchaseInvoice"
        }
      ],
      "Spare Parts": [
        {
          title: "Name",
          name: "name",
          required: true
        },
        {
          title: "Quantity",
          name: "quantity",
          required: true
        },
        {
          title: "Category",
          name: "category"
        },
        {
          title: "Purchase Invoice",
          name: "purchaseInvoice"
        }
      ],
      Documents: [
        {
          title: "Document Description",
          name: "name",
          required: true
        },
        {
          title: "File Number",
          name: "fileNo"
        },
        {
          title: "Source",
          name: "source"
        },
        {
          title: "Destination",
          name: "destination"
        }
      ],
      Diesel: [
        {
          title: "Quantity",
          name: "quantity",
          required: true
        },
        {
          title: "Diesel Bill Number",
          name: "billNo"
        }
      ]
    };
    if (this.state.vehicleNumber)
      AllFields["BasicFields"].unshift({
        title: "Vehicle Number",
        name: "vehicleNumber"
      });
    if (this.state.inorout === "out") {
      AllFields.storageItems[AllFields["storageItems"].length - 1].title =
        "Dispatched Date";
    }
    const { items } = this.state;
    return (
      <Row>
        <Col>
          <span
            className="p-1 float-right"
            style={
              this.state.inorout === "in"
                ? { color: "#46a846", border: "1px solid #46a846" }
                : { color: "#d90c0c", border: "1px solid #d90c0c" }
            }
          >
            {this.state.inorout === "in" ? "Material IN" : "Material OUT"}
          </span>
        </Col>
        <Col sm={12}>
          <Form>
            <Row>
              {AllFields["BasicFields"].map(
                (item, index) =>
                  this.state[item.name] && (
                    <Col sm="6" key={index} className="my-2">
                      <div className="row">
                        <div className="col-auto pr-0">{item.title}:</div>
                        <div
                          className="col pl-1"
                          style={{ fontWeight: "bold" }}
                        >
                          {this.state[item.name]}
                        </div>
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </Form>
        </Col>
        <Col className="mt-4 px-0 px-md-3">
          {items &&
            items.map((item, index) => (
              <>
                {/* {item.Materialtype === "Storage Material" && ( */}
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontSize: ".9rem" }}>
                      {index + 1}
                      {". "}
                      {`${item.Materialtype.split(" ")[0]} (${
                        item.name
                          ? item.name
                          : item.grnstnNumber
                          ? "GRN-" + item.grnstnNumber
                          : "Qua-" + item.quantity
                      })`}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="flex-column expanDetail">
                    <Row className="w-100 mx-2 mb-2">
                      {this.mapFields(AllFields[item.Materialtype], item)}
                    </Row>

                    {/* storage material type containing items */}
                    {item.Materialtype === "Storage Material" &&
                      item.storageItems && (
                        <div>
                          <hr
                            className="w-25"
                            style={{ backgroundColor: "rgba(0, 64, 114,1)" }}
                          />
                          <div className="text-body mb-2">
                            <span>Total Items:{item.storageItems.length} </span>
                          </div>
                          {item.storageItems.map((el, key) => (
                            <>
                              <ExpansionPanel key={key}>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography style={{ fontSize: ".9rem" }}>
                                    {key + 1}
                                    {". "}
                                    {`${el.name} (Qua-${el.quantity} ${el.unit})`}
                                  </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Row className="w-100 mx-0">
                                    {this.mapFields(
                                      AllFields["storageItems"],
                                      el
                                    )}
                                  </Row>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </>
                          ))}
                        </div>
                      )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* )} */}
              </>
            ))}
        </Col>
      </Row>
    );
  }
}

export default AddEditMaterial;
