import React, { Component } from "react";
// import './App.css';
import { Card, CardBody, CardTitle } from "shards-react";
import ViewAllVehicleWrapper from "./ViewAllVehicleWrapper";
import AxiosPost from "../../common/AxiosPost";
import CardHeading from "../../common/CardHeading";
class MethodVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddProduct: false,
      error: null,
      response: {},
      product: {},
      isEditProduct: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onCreate() {
    this.setState({ isAddProduct: true });
  }

  onFormSubmit(data) {
    let apiUrl;
    //console.log(this.state.isAddProduct, "Add");
    //console.log(this.state.isEditProduct, "Edit");
    //console.log("data is", data);
    if (this.state.isEditProduct) {
      apiUrl = "/update_vehicle_entry";
    } else {
      apiUrl = "/vehicle_inward_register";
    }
    if (data["_id"]) {
      const idkey = data["_id"];
      const final = idkey["$oid"].toString();
      data["_id"] = final;
      //console.log(typeof data["_id"]);
    }
    //console.log(apiUrl);
    AxiosPost(apiUrl, data, res => {
      this.setState({
        response: res,
        isAddProduct: false,
        isEditProduct: false
      });
      //console.log("The data has been submitted");
      //console.log("res is", res.data);
    });
  }
  editProduct = productId => {
    //console.log(productId);
    const apiUrl = "/get_vehicle_entry";
    const val = { snoid: productId["$oid"].toString() };
    //console.log(val, "heyyyy");
    AxiosPost(
      apiUrl,
      val,
      result => {
        //console.log(result.data);
        this.setState({
          product: result.data,
          isEditProduct: true,
          isAddProduct: true
        });
      },
      error => {
        this.setState({ error });
      }
    );
  };

  render() {
    // let productForm;
    // if (this.state.isAddProduct || this.state.isEditProduct) {
    //   productForm = (
    //     <AddEditVehicle
    //       onFormSubmit={this.onFormSubmit}
    //       product={this.state.product}
    //     />
    //   );
    // }

    return (
      <div className="MethodVehicle">
        <Card small className="m-3 m-md-4">
          <CardBody>
            <CardTitle className="p-3">
              <CardHeading
                title={"Vehicle In Out Records"}
                icon={"drive_eta"}
              />
            </CardTitle>
            <div className="p-3">
              <ViewAllVehicleWrapper />
            </div>
          </CardBody>
        </Card>
        {/* <Col>
          {!this.state.isAddProduct && <Button variant="primary" style={{float:'right'}} onClick={() => this.onCreate()}>Add Vehicle</Button>}
          {this.state.response.status === 'success' && <div><br /><Alert variant="info">{this.state.response.message}</Alert></div>}
          {!this.state.isAddProduct && <ListVehicle editProduct={this.editProduct}/>}
          { productForm }
          {this.state.error && <div>Error: {this.state.error.message}</div>}
        </Col> */}
      </div>
    );
  }
}

export default MethodVehicle;
