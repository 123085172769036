import React from "react";
import PropTypes from "prop-types";

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-3 bg-white border-top position-absolute w-100" style={{bottom:'0px'}}>
    {/* <Container fluid={contained}> */}
      <>
        {/* <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={Link} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}
        <span className="copyright m-auto">{copyright}</span>
      </>
    {/* </Container> */}
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © 2019 MoolCode. All rights reserved",
  menuItems: [
    // {
    //   title: "Home",
    //   to: "#"
    // },
    // {
    //   title: "Services",
    //   to: "#"
    // },
    // {
    //   title: "About",
    //   to: "#"
    // },
    // {
    //   title: "Products",
    //   to: "#"
    // },
    // {
    //   title: "Blog",
    //   to: "#"
    // }
  ]
};

export default MainFooter;
