import React from "react";
import { Card, CardBody, CardTitle } from "shards-react";
import TableView from "../common/TableView";
import CardHeading from "../common/CardHeading";

const data = [
  { title: "Visitor IN/OUT", info: "/View_AllVisitors" },
  // { title: "Attendance", info: "/View_employees" },
  { title: "Storage Material Inward", info: "/ViewMaterialInward" },
  { title: "Storage Material Outward", info: "/ViewMaterialOutward" },
  { title: "Vehicle IN/OUT", info: "/View_AllVehicle" },
  { title: "Intern IN/OUT", info: "/View_AllInterns" },
  // { title: "Customer Vistor ", info: "/View_Customers" },
  { title: "Labour IN/OUT", info: "/View_AllLabours" },
  { title: "Document Movement", info: "/View_AllDocuments" }
];

function RegisterTable(props) {
  const reDirect = path => {
    props.history.push(path);
  };
  return (
    <Card small className="m-3 m-md-4">
      <CardBody>
        <div className="p-3">
          <CardHeading title={"Registers"} icon={"menu_book"} />
        </div>
        <div className="p-3">
          <TableView data={data} handleChange={reDirect} />
        </div>
      </CardBody>
    </Card>
  );
}

export default RegisterTable;
