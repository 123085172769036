import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import axiosPost from "../common/AxiosPost";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 3000;

export default function EditDeleteTable({
  url,
  keyinfo,
  EditRoute,
  deleteKey
}) {
  const store = useSelector(state => state);
  const [data, setData] = useState();
  const [loadertext, setLoadertext] = useState();
  const [load, setLoad] = useState(false);

  function handleAlert(type, msg) {
    PNotify.alert({
      type: type,
      text: msg
    });
  }
  useEffect(() => {
    axiosPost(
      `/${url.endpoint}`,
      {},
      res => {
        //console.log(res);
        setData(res.data);
        // if (res.data.length === 0) handleAlert("notice", "No data");
        setLoadertext();
      },
      () => {
        handleAlert("error", "Something went wrong");
        setLoadertext();
      }
    );
  }, [load]);

  function handleDelete(body) {
    //console.log(body);
    setLoadertext("Deleting");
    axiosPost(
      `/${url.delete}`,
      body,
      res => {
        //console.log(res);
        if (res.status === 200) {
          setLoad(!load);
          handleAlert("info", "Successfully Deleted");
        } else {
          setLoadertext();
          handleAlert("error", "Something went wrong");
        }
      },
      () => {
        setLoadertext();
        handleAlert("error", "Something went wrong");
      }
    );
  }
  return (
    <React.Fragment>
      {data ? (
        data.length >= 1 ? (
          <LoadingOverlay
            active={loadertext ? true : false}
            spinner
            text={loadertext}
          >
            <Table responsive className="mt-1 text-center">
              <thead>
                <tr>
                  <th>S.No</th>
                  {keyinfo.map((item, index) => (
                    <th key={index}>{item.displayName}</th>
                  ))}
                  <th>Edit/Delete</th>
                </tr>
              </thead>
              {data &&
                data.map((row, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>{index + 1}</td>
                      {keyinfo.map((item, ind) => (
                        <td key={ind}>{row[item.keyname]}</td>
                      ))}

                      <td>
                        <div className="d-flex flex-row justify-content-center">
                          <Link to={EditRoute}>
                            <button className="btn btn-outline-info mr-2">
                              Edit
                            </button>
                          </Link>
                          <button
                            className="btn btn-outline-danger"
                            onClick={e => {
                              e.preventDefault();
                              let body;
                              if (deleteKey.key === "_id")
                                body = {
                                  [deleteKey.bodykey]: row._id.$oid
                                };
                              else
                                body = {
                                  [deleteKey.bodykey]: row[deleteKey.key],
                                  location: store.auth.location
                                };
                              handleDelete(body);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          </LoadingOverlay>
        ) : (
          <div className="text-center p-3">No Data Found</div>
        )
      ) : (
        <div className="p-3">
          <div className="spinner-border d-block m-auto" />
        </div>
      )}
    </React.Fragment>
  );
}
