import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../common/TableView";
import AxiosPost from "../../common/AxiosPost";

class ViewAllMaterialWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
    }
  }
  fetch = () => {
    AxiosPost(
      "/material_in_out_table",
      { start: 0, length: this.props.limitRecords ? 5 : 1000 },
      res => {
        this.setState({ isLoading: false });
        // console.log(res.data);
        if (res.data)
          this.setState({
            data: res.data
              .reverse()
              .slice(0, this.props.limitRecords ? 5 : res.data.length)
              .map(m => ({
                icon: m.inorout,
                title: m.vehicleNumber || "On Foot",
                cust: m.customerName || " ",
                time: m.inorout === "in" ? m.in_time : m.out_time,
                //url: `/material?id=${m._id["$oid"]}`,
                info: m._id["$oid"]
              }))
          });
      }
    );
  };
  componentDidMount() {
    this.fetch();
  }
  handleChange = info => {
    //console.log(info);
    //console.log("clocked");
    this.props.history.push({ pathname: "/material", oid: info });
  };

  render() {
    if (this.state.isLoading)
      return (
        <div className="p-3">
          <div className="spinner-border d-block m-auto" />
        </div>
      );

    return (
      <TableView
        data={this.state.data}
        handleChange={info => this.handleChange(info)}
        headings={
          !this.props.limitRecords && [
            "IN/OUT",
            "Vehicle Number",
            "Customer",
            "Date & Time"
          ]
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewAllMaterialWrapper));
