import React, { useState, useEffect } from "react";
import axiosPost from "../common/AxiosPost";

import store from "../../store";
import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

import TableView from "../common/TableView";

function DockingTable({ history, inorout }) {
  const [tabledata, setTableData] = useState({});
  const storedata = store.getState();
  const [showloader, setLoader] = useState(true);

  const stateAuth = useSelector(state => state.auth);
  const [SelectedLocation, setLoc] = useSelector(state => state.auth.location);
  //console.log(SelectedLocation);

  useEffect(() => {
    //console.log("docking.js mounted");
    ////console.log(storedata);
    setLoader(true);
    axiosPost("/get_docking_queue", {}, res => {
      // console.log("res", res);

      if (res.data) {
        //inorout is passes by sgaactionCard
        let a = res.data.filter(i => i.inorout === inorout);

        let newdata = a.map(m => ({
          icon: m.docking,
          title: m.vehicleNumber || "On Foot",
          cust: m.customerName || " ",
          time: m.inorout === "in" ? m.in_time : m.out_time,
          info: m
        }));
        setTableData(newdata);
      }
      setLoader(false);
    });
  }, [SelectedLocation]);

  const handleChange = item => {
    //console.log("clicked is", item);

    setLoader(true);
    //setdockData([])

    history.push({
      pathname: "/Material_Counter",
      item: item
    });
  };

  if (showloader)
    return (
      <div className="p-3">
        <div className="spinner-border d-block m-auto" />
      </div>
    );

  return <TableView data={tabledata} handleChange={handleChange} />;
}

export default withRouter(DockingTable);
