import React from "react";
import axiosPost from "../../common/AxiosPost";
import { connect } from "react-redux";
import { Card, CardBody } from "shards-react";
import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css";

import ViewPerson from "./view_person";
import TableView from "../../common/TableView";
import moment from "moment";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

class ViewPersonDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      inoutData: [],
      isLoading: true,
      selectedDate: new Date().toString()
    };
  }

  componentDidMount() {
    if (!this.props.location.mobileNumber) {
      this.props.history.push("/dashboard");
      PNotify.info("Please Select Person First");
      return;
    }
    axiosPost(
      "/get_person_inout_entry",
      { mobileNumber: this.props.location.mobileNumber },
      res => {
        // console.log(res);
        this.setState({ isLoading: false });
        if (res.data && Array.isArray(res.data)) {
          res.data.forEach(ele => {
            let temp = this.state.inoutData;
            temp.push({ icon: ele.inorout, time: ele.in_time });
          });
          this.setState({
            data: res.data[0]
          });
        }
      }
    );
  }

  render() {
    return (
      <Card small className="m-3 m-md-4">
        <CardBody className="mt-3">
          <div className="p-2">
            <div>
              <h3
                className="px-2 d-inline-block"
                style={{
                  fontSize: "x-large"
                }}
              >
                User Details
              </h3>
            </div>
            {this.state.isLoading && (
              <div className="spinner-border d-block m-auto" />
            )}
            {this.state.data && (
              <>
                <ViewPerson product={this.state.data} />

                <div className="row mt-3">
                  <div className="col-md-8">
                    <InfiniteCalendar
                      // width={400}
                      // height={400}

                      className="w-100"
                      selected={new Date(this.state.selectedDate)}
                      onSelect={selectedDate => this.setState({ selectedDate })}
                      dateClasses={[
                        {
                          className: "dateHighlighted position-relative",
                          dates: this.state.inoutData.map(d =>
                            moment(d.time).toDate()
                          )
                        }
                      ]}
                    />
                  </div>
                  <div
                    className="col-md-4 mt-5 overflow-auto"
                    style={{ maxHeight: "270px" }}
                  >
                    <TableView
                      data={this.state.inoutData.filter(
                        d =>
                          moment(d.time).format("L") ===
                          moment(this.state.selectedDate).format("L")
                      )}
                      headings={["IN/OUT", "Timings"]}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <div className="p-3">
            
          </div> */}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  selectedLocation: state.auth.location
});

export default connect(mapStateToProps)(ViewPersonDetails);
