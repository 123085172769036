import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../common/TableView";
import AxiosPost from "../../common/AxiosPost";

class ViewAllVehicleWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
    }
  }

  fetch = () => {
    AxiosPost(
      "/vehicle_in_out_table",
      { start: 0, length: this.props.limitRecords ? 5 : 100000 },
      res => {
        // console.log(res);
        this.setState({ isLoading: false });
        let n = 0;
        if (res.data)
          this.setState({
            data: res.data.reverse().map((v, i) => ({
              icon: v.inorout,
              title: v.vehicleNumber,
              time: v.inorout === "in" ? v.in_time : v.out_time,

              token: v.vehicleType === "Material Carrier" ? `T${++n}` : "N/A",
              //url: `/vehicle?id=${v._id["$oid"]}`,
              info: v._id["$oid"]
            }))
          });
      }
    );
  };
  componentDidMount() {
    this.fetch();
  }
  handleChange = info => {
    //console.log(info);
    //console.log("clocked");
    this.props.history.push({ pathname: "/vehicle", oid: info });
  };
  render() {
    if (this.state.isLoading)
      return (
        <div className="p-3">
          <div className="spinner-border d-block m-auto" />
        </div>
      );

    return (
      <TableView
        data={this.state.data.reverse()}
        headings={
          !this.props.limitRecords && [
            "IN/OUT",
            "Vehicle Number",
            "Token",
            "Date & Time"
          ]
        }
        handleChange={info => this.handleChange(info)}
      />
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewAllVehicleWrapper));
