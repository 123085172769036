import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import LineChart from "../common/LineChart";
import TableView from "../common/TableView";
import axiosPost from "../common/AxiosPost";
import CardHeading from "../common/CardHeading";
import { Card, CardBody, CardTitle } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 3000;

function View_Attendance(props) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = `${yyyy}-${mm}-${dd}`;

  const [mobileNumber, setNumber] = useState(
    parseInt(props.location.mobileNumber)
  );
  const [start_date, setDate] = useState(today);
  const [loader, setLoader] = useState(false);
  const redux = useSelector(state => state.auth);
  //   //console.log(redux);
  const [chartData, setChartData] = useState();
  const [tableData, setTableData] = useState([]);
  const handleSubmit = e => {
    e.preventDefault();
    if (mobileNumber.toString().length !== 10) {
      PNotify.error("Number Should Contain 10 digits");
      return;
    }
    //console.log("attendance submit clicked");
    let body = {
      mobileNumber: mobileNumber,
      start_date: start_date
    };
    setLoader(true);
    //console.log(body);
    axiosPost(
      "/get_employee_attendance",
      body,
      res => {
        //console.log(res);
        setLoader(false);
        if (res.data && Array.isArray(res.data) && res.data.length === 0) {
          setChartData({});
        } else if (res.data && Array.isArray(res.data)) {
          setTableData(res.data);
          let cumuHours = res.data.map(e => e.cumuHours);
          let numHours = res.data.map(e => e.numHours);
          let labels = res.data.map(e => e.date);

          let temp = {
            labels: labels,
            datasets: [
              {
                label: "Number of Hours",
                data: numHours,
                backgroundColor: "rgba(0,123,255,0.1)",
                borderColor: "rgba(0,123,255,1)",
                pointBackgroundColor: "#ffffff",
                pointHoverBackgroundColor: "rgb(0,123,255)",
                borderWidth: 1.5,
                pointRadius: 0,
                pointHoverRadius: 3
              },
              {
                label: "Cumulative Hours",
                data: cumuHours,
                backgroundColor: "rgba(255,65,105,0.1)",
                borderColor: "rgba(255,65,105,1)",
                pointBackgroundColor: "#ffffff",
                pointHoverBackgroundColor: "rgba(255,65,105,1)",
                borderDash: [3, 3],
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 2,
                pointBorderColor: "rgba(255,65,105,1)"
              }
            ]
          };
          //console.log(cumuHours, numHours, labels, temp);
          setChartData(temp);
        }
      },
      () => {
        setLoader(false);
        PNotify.error("Something went wrong.Please Try again!");
      }
    );
  };

  return (
    <Card small className="m-3 m-md-4">
      <CardBody>
        <CardTitle className="p-3">
          <CardHeading
            title={"View Attendance"}
            icon={"transfer_within_a_station"}
            displayLocation="All Locations"
          />
        </CardTitle>
        <LoadingOverlay active={loader} spinner>
          <div className="container">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Please Enter Mobile Number"
                      value={mobileNumber}
                      onChange={e => setNumber(e.target.value)}
                      readOnly={props.location.mobileNumber && true}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Select Starting Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={start_date}
                      onChange={e => setDate(e.target.value)}
                    />
                  </Form.Group>
                </div>

                <div className="col-12">
                  <button
                    type="submit"
                    className="d-block mx-auto my-2 btn  btn-accent"
                  >
                    Check
                  </button>
                </div>
              </div>
            </Form>
            {chartData && Object.keys(chartData).length > 0 && (
              <LineChart data={chartData} />
            )}
            {chartData && Object.keys(chartData).length === 0 && (
              <div className="text-center my-2"> Sorry,No Data Found.</div>
            )}
            {chartData && Object.keys(chartData).length > 0 && (
              <div className="mt-2">
                <TableView
                  data={tableData.map(i => ({
                    title: i.date,
                    numHours: i.numHours,
                    cumuHours: i.cumuHours
                  }))}
                  headings={["Date", "Number of Hours", "Cumulative Hours"]}
                />
                <div className="text-center my-4">
                  <span className="border p-3">
                    Total Number Of Hours:
                    {tableData[tableData.length - 1].cumuHours}
                  </span>
                </div>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
}

export default View_Attendance;
