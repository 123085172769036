export default function() {
  return [
    {
      title: "Dashboard",
      to: "/Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter:'',
      child: [
        {
          title: "Person In/Out",
          to: "/Person_inOut"
        }
    ]
    },
    {
      title: "Operations",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
  
    {
      title: "Material IN/OUT",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/Material_InOut",
    },

    {
      title: "Person IN/OUT",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/Person_inOut",
    },
    {
      title: "Vehicle IN/OUT",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/Vehicle_InOut",
    },

    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    }
  ];
}
