import React from "react";
import { Card, CardBody, CardFooter, CardHeader, Row } from "shards-react";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import PersonIcon from "../../assets/person.png";
import Accordion from "react-bootstrap/Accordion";
import { Button, Card as CardRap } from "react-bootstrap";
const CardAction = ({ icon, title, type, handleCount }) => {
  return (
    <div style={{ padding: "5px 10px" }} onClick={handleCount}>
      <Icon style={{ fontSize: 40, color: "#f8f9fae0" }} className="hoverWhite">
        {icon}
      </Icon>
    </div>
  );
};

function ItemWidgets({ item, handleCount, count, index, isAnotherSupervisor }) {
  console.log(item);

  let storageItems = [
    {
      title: "SKU Code",
      name: "skuCode"
    },
    {
      title: "Batch Number",
      name: "batchnumber"
    },
    {
      title: "Weight of Unit (KG)",
      name: "weight"
    },
    {
      title: "Quantity",
      name: "quantity"
    },
    {
      title: "Temperature",
      name: "temperature"
    },
    {
      title: "Production Date",
      name: "prodDate"
    },
    {
      title: "Expiry Date",
      name: "expiryDate"
    },
    {
      title: "Remarks",
      name: "remarks"
    }
  ];
  return (
    <Card style={{ backgroundColor: "lightseagreen" }} className="my-5">
      <CardHeader
        style={{
          backgroundColor: "lightseagreen",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: ".625rem .625rem 0 0"
        }}
        className={"py-4"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            height: 100,
            background: "rgba(255,255,255,0.4)",
            borderRadius: "50%",
            marginBottom: 20,
            marginRight: 0,
            padding: "10%",
            fontSize: "1.5rem"
          }}
        >
          {count}
        </div>
        <h5 className="m-0" style={{ color: "#FFF", flex: 2 }}>
          <Accordion>
            <Accordion.Toggle
              as={Row}
              variant="link"
              eventKey="0"
              className="mx-auto d-block text-center"
              style={{ pointer: "cursor" }}
            >
              {item.name}
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <CardRap.Body>
                <div
                  style={{ fontSize: "1rem" }}
                  className="py-1 text-athens-gray text-center border-top mt-2 pt-3"
                >
                  {storageItems.map(
                    i =>
                      item[i.name] && (
                        <div>
                          <span>{i.title}: </span>
                          <span style={{ fontWeight: 600 }}>
                            {item[i.name]}
                          </span>
                        </div>
                      )
                  )}
                </div>
              </CardRap.Body>
            </Accordion.Collapse>
          </Accordion>
        </h5>
      </CardHeader>

      <CardFooter style={{ backgroundColor: "lightseagreen" }}>
        {!isAnotherSupervisor && (
          <>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around"
              }}
            >
              <CardAction
                icon="remove_circle"
                title="Add Entry"
                handleCount={() => handleCount(index, "-")}
              />
              <CardAction
                icon="add_box"
                title="Add Entry"
                handleCount={() => handleCount(index, "+")}
              />
              <CardAction icon="check_circle" title="Add Exit" />
              <CardAction icon="broken_image" title="View Logs" />
            </Row>
          </>
        )}
      </CardFooter>
    </Card>
  );
}

export default ItemWidgets;
