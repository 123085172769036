import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "shards-react";
//import Table_view_person from "./table_view_person";
//import AddEditPerson from "./view_person";

import ViewAllPersonWrapper from "./ViewAllPersonWrapper";
import AxiosPost from "../../common/AxiosPost";
import CardHeading from "../../common/CardHeading";

class MethodPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      ptype: ""
    };
    //this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  // onFormSubmit(data) {
  //   let apiUrl;
  //   console.log(this.state.isAddProduct, "Add");
  //   console.log(this.state.isEditProduct, "Edit");
  //   console.log("data is", data);
  //   if (this.state.isEditProduct) {
  //     apiUrl = "/update_person_entry";
  //   } else {
  //     apiUrl = "/addperson";
  //   }
  //   if (data["_id"]) {
  //     const idkey = data["_id"];
  //     const final = idkey["$oid"].toString();
  //     data["_id"] = final;
  //     console.log(typeof data["_id"]);
  //   }
  //   console.log(apiUrl);
  //   AxiosPost(apiUrl, data, res => {
  //     this.setState({
  //       response: res
  //     });
  //     console.log("The data has been submitted");
  //     console.log("res is", res.data);
  //   });
  // }
  // editProduct = productId => {
  //   console.log(productId);
  //   const apiUrl = "/get_person_entry";
  //   const val = { snoid: productId["$oid"].toString() };
  //   console.log(val, "heyyyy");
  //   AxiosPost(
  //     apiUrl,
  //     val,
  //     result => {
  //       console.log(result.data);
  //       this.setState({
  //         product: result.data,
  //         isEditProduct: true
  //       });
  //     },
  //     error => {
  //       this.setState({ error });
  //     }
  //   );
  // };

  componentDidMount() {
    let path = this.props.match.path;
    if (path === "/View_AllPerson") {
      this.setState({
        ptype: "all",
        title: "Person"
      });
    } else if (path === "/View_AllVisitors") {
      this.setState({
        ptype: "Visitor",
        title: "Visitor"
      });
    } else if (path === "/View_AllLabours") {
      this.setState({
        ptype: "Labour",
        title: "Labour"
      });
    } else if (path === "/View_AllInterns") {
      this.setState({
        ptype: "Intern",
        title: "Intern"
      });
    }
  }
  render() {
    // let productForm;
    // if (this.state.isEditProduct) {
    //   productForm = (
    //     <AddEditPerson
    //       onFormSubmit={this.onFormSubmit}
    //       product={this.state.product}
    //     />
    //   );
    // }

    return (
      <div className="MethodPerson">
        <Card small className="m-3 m-md-4">
          <CardBody>
            <CardTitle className="p-3">
              <CardHeading
                title={this.state.title + " In Out Records"}
                icon={"person_outline"}
              />
            </CardTitle>
            <div className="p-3">
              <ViewAllPersonWrapper ptype={this.state.ptype} />
            </div>
          </CardBody>
        </Card>
        {/* <Col style={{display: "none"}}>
          {this.state.response.status === 'success' && <div><br /><Alert variant="info">{this.state.response.message}</Alert></div>}
          {!this.state.isEditProduct && <Table_view_person editProduct={this.editProduct} />}
          {productForm}
          {this.state.error && <div>Error: {this.state.error.message}</div>}
        </Col> */}
      </div>
    );
  }
}

export default MethodPerson;
