import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      username:
        (sessionStorage.dashboard_data &&
          JSON.parse(sessionStorage.dashboard_data).username) ||
        "User"
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  logOut = () => {
    sessionStorage.removeItem("dashboard_data");
    sessionStorage.removeItem("selectedLocation");
    sessionStorage.removeItem("token");
    this.props.history.push("/");
    PNotify.info("Successfully Logged Out");
    return;
  };
  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle
          caret
          tag={NavLink}
          className="text-nowrap p-2 mt-2 text-center"
          style={{
            boxShadow: "1px 1px 5px 4px #eceff7",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
            alt="User Avatar"
          />{" "} */}
          <span className="d-none d-md-inline-block mr-1">
            {" "}
            {this.state.username}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/* <DropdownItem
            to="user-profile"
            onClick={() => this.props.history.push("/Profile")}
          >
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem> */}
          {/* <DropdownItem  to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem  to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem to="/" className="text-danger" onClick={this.logOut}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default withRouter(UserActions);
