import React, { useEffect } from "react";
import { Card, CardBody } from "shards-react";
import "./Docking.css";
import { Modal, Form } from "react-bootstrap";
import SelectedMaterial from "./SelectedMaterial";
import LoadingOverlay from "react-loading-overlay";
import AxiosPost from "../common/AxiosPost";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

function MaterialCards({ item, setDisplay, isAnotherSupervisor }) {
  const [selectedMaterial, selectMaterial] = React.useState();
  const [showModal, toggleModal] = React.useState(false);
  const [modalLoader, setmodalLoader] = React.useState(true);
  const [newSupervisor, setnewSupervisor] = React.useState();
  const [superVisors, setSuperVisors] = React.useState([]);

  let materials = React.useRef(
    item.items.filter(i => i.Materialtype === "Storage Material")
  );
  function handleClose(e) {
    if (e) e.preventDefault();
    toggleModal(false);
  }
  function fetchSupervisors() {
    setmodalLoader(true);
    AxiosPost(
      "/employee_table",
      //any data
      { start: 0, length: 10000000 },
      res => {
        console.log(res);
        setmodalLoader(false);
        if (res.data.length >= 1)
          setSuperVisors(
            res.data.map(i => ({
              username: i.username,
              mobileNumber: i.mobileNumber
            }))
          );
      }
    );
  }

  function AssignNewSupervisor() {
    let newSuperVisor = superVisors.find(i => i.username === newSupervisor);
    if (!newSuperVisor) {
      PNotify.error("Username Not Found");
      return;
    }
    console.log(newSuperVisor);
    let data = { ...item };
    data._id = data._id.$oid;
    data["newSupervisor"] = newSuperVisor;
    console.log(data);
    setmodalLoader(true);
    AxiosPost("/otp_assign_new_supervisor", data, res => {
      console.log(res);
      setmodalLoader(false);
      if (res.data.Status === " Okay! ") setDisplay("otp");
      else PNotify.error("Something Went Wrong");
    });
  }
  const Datalist = (
    <datalist id="list">
      {superVisors &&
        superVisors.map((i, key) => <option key={key} value={i.username} />)}
    </datalist>
  );

  console.log(item);
  if (selectedMaterial)
    return (
      <SelectedMaterial
        material={selectedMaterial}
        selectMaterial={selectMaterial}
        info={item}
        isAnotherSupervisor={isAnotherSupervisor}
      />
    );
  else
    return (
      <div
        style={{
          minHeight: "calc(100vh - 120px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card small className="m-3 m-md-4" style={{ width: 500 }}>
          <CardBody className="p-md-5 p-4">
            <h3 style={{ transform: "translateX(-6px)" }}>Choose Material:</h3>
            <span className="text-secondary">
              Choose a Material for Docking
            </span>
            {!isAnotherSupervisor && (
              <div className="d-flex justify-content-end mt-2">
                {" "}
                <button
                  className="btn btn-warning d-block"
                  onClick={() => {
                    //extra check
                    if (isAnotherSupervisor) {
                      PNotify.error("You are not allowed");
                      return;
                    }
                    toggleModal(true);
                    fetchSupervisors();
                  }}
                >
                  Assign Supervisor
                </button>
              </div>
            )}
            {materials.current &&
              materials.current.map((i, key) => (
                <div
                  className="d-flex mt-5 position-relative"
                  style={{ cursor: "pointer" }}
                  onClick={() => selectMaterial(i)}
                  key={key}
                >
                  <div className="dockMaterialCard">
                    <div className="my-2">
                      <h6>Invoice: {i.invoiceUri}</h6>

                      <p>Total Items:{i.storageItems.length}</p>
                      <p>Total Tonnage:{i.totalTonnage}</p>
                    </div>
                  </div>
                </div>
              ))}

            <Modal
              show={showModal}
              onHide={handleClose}
              style={{ zIndex: "1070" }}
            >
              <LoadingOverlay active={modalLoader} spinner text="fetching...">
                <Modal.Header closeButton className="border-0">
                  <Modal.Title>Assign New Supervisor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="text-secondary mb-0">
                    Please Select a Supervisor:
                  </p>

                  <Form.Group>
                    <Form.Label className="required">Choose</Form.Label>
                    <Form.Control
                      required={true}
                      type="text"
                      name="newSupervisor"
                      value={newSupervisor}
                      onChange={i => {
                        setnewSupervisor(i.target.value);
                      }}
                      placeholder="Enter the username"
                      list="list"
                      autoComplete={"off"}
                    />
                    {Datalist}
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-0">
                  <button className="btn btn-danger" onClick={handleClose}>
                    Close
                  </button>
                  <button
                    className="btn btn-accent"
                    onClick={AssignNewSupervisor}
                  >
                    Send
                  </button>
                </Modal.Footer>
              </LoadingOverlay>
            </Modal>
          </CardBody>
        </Card>
      </div>
    );
}

export default MaterialCards;
