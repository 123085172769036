import React from "react";
import { useSelector } from "react-redux";
import Icon from "@material-ui/core/Icon";

function CardTitle({ title, displayLocation, icon }) {
  let location = useSelector(state => state.auth.location);
  return (
    <div className="cardHeading">
      <div className="d-flex">
        <div className="d-flex pr-3 mt-2">
          <Icon className="my-auto cardIcon">{icon}</Icon>
        </div>
        <div className="d-flex flex-column ">
          <span className="cardLocation text-uppercase">
            {" "}
            {displayLocation ? displayLocation : location}
          </span>
          <div className="cardTitle text-uppercase">{title}</div>
        </div>
      </div>
    </div>
  );
}

export default CardTitle;
