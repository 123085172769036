import { Card, CardBody, Button } from "shards-react";
import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import axiosPost from "../common/AxiosPost";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

class AddEditMaterial extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.data) this.initialState = this.props.data;
    else
      this.initialState = {
        //get all values from form field
        id: "",
        items: [],
        showItemsForm: false, //storage material items form to be shown or not
        editItemIndex: undefined, //stores index of item that has to be updated
        editStoreItemIndex: undefined, //stores index of item that has to be updated in Storage material type
        isMaterialOut: false,
        //Basic
        sysGenGRN: "", //this is to be shown after form submission,ill be generated by backend
        vehicleNumber: "",
        personName: "",
        personId: "",
        dirOfMovement: "Inward",
        dateOfMovement: "",
        Materialtype: "",
        customerName: "",

        //common second form fields
        quantity: "",
        name: "",
        category: "",
        source: "",
        destination: this.props.location,

        // storage material
        grnstnNumber: "",
        invoiceUri: "",
        stnChallan: "",
        totalTonnage: "",

        //Storage -> items field
        storageItems: [], // array will store items under type-Storage Material
        temperature: "",
        weight: "",
        skuCode: "",
        desc: "",
        unit: "",
        batchnumber: "",
        prodDate: "",
        expiryDate: "",
        remarks: "",

        //personal Item
        //all personal item fields are in common

        //documents
        fileNo: "",
        //name key is ued for document description

        //diesel
        billNo: "",

        //assets and spare
        purchaseInvoice: "",

        customersList: [], //customer list to dropdown all customers
        mobileNumber: "",
        showspinner: false,
        successmsg: "checkPhone"
        //successmsg: "showFields"
      };

    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this); // bind the method
    this.handleSubmit = this.handleSubmit.bind(this); // bind the method
  }

  componentDidMount() {
    //data coming from docking queue
    if (this.props.data) this.setState({ readOnly: true });
    else this.setState({ readOnly: false });

    //setting isMaterialOut to true when it /Material out
    if (this.props.match.path === "/Material_Out")
      this.setState({ isMaterialOut: true, dirOfMovement: "Outward" });
    //getting data if both person in and vehicle in are filled
    if (
      this.props.history.location &&
      this.props.history.location.vehicleNumber &&
      this.props.history.location.name
    ) {
      this.setState({
        vehicleNumber: this.props.history.location.vehicleNumber,
        personName: this.props.history.location.name,
        mobileNumber: this.props.history.location.mobileNumber,
        personId: this.props.history.location.mobileNumber,
        customerName: this.props.history.location.customerName,
        successmsg: "showFields"
      });
    }
    //gettin data if person in is filled(name,mobileNumber)
    else if (this.props.history.location && this.props.history.location.name) {
      this.setState({
        personName: this.props.history.location.name,
        mobileNumber: this.props.history.location.mobileNumber,
        personId: this.props.history.location.mobileNumber,
        //idType: this.props.history.location.idType,
        //  personType: this.props.history.location.personType,
        successmsg: "checkVehicle"
      });
    }

    //default setting dateOfMovement
    let today = new Date();
    this.setState({
      dateOfMovement: `${today.getFullYear()}-${
        (today.getMonth() + 1).toString().length === 1
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1
      }-${
        today.getDate().toString().length === 1
          ? "0" + today.getDate()
          : today.getDate()
      }`
    });

    //fetch cutomers list
    this.fetchCustomers();
  }
  fetchCustomers = () => {
    axiosPost("/get_customers", {}, res => {
      //console.log(res.data);
      let list = res.data.map(cust => ({
        custName: cust.custName,
        custProducts: cust.custProducts
      }));
      //console.log(list);
      this.setState({ customersList: list });
    });
  };
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });

    if (name === "Materialtype") this.setState({ editItemIndex: undefined });
  }
  checkMobile = e => {
    e.preventDefault();

    if (this.state.mobileNumber.toString().length === 10) {
      const data = {
        mobileNumber: this.state.mobileNumber,
        auth_status: "phone_entered"
      };

      this.setState({ showspinner: true });
      axiosPost(
        "/checkphone",
        data,
        res => {
          //console.log(res.data);

          if (
            res.data.Status === "This person is already inside the facility."
          ) {
            axiosPost(
              "/get_person_inout_entry",
              { mobileNumber: this.state.mobileNumber },
              res => {
                //console.log(res);
                let person = res.data[0];
                this.setState({
                  personName: person.name,
                  personId: person.mobileNumber,
                  showspinner: false
                });
                if (this.props.history.location.vehicleNumber) {
                  this.setState({ successmsg: "showFields" });
                } else {
                  this.setState({ successmsg: "checkVehicle" });
                }
              },
              () => {
                PNotify.error("Something Went Wrong. Please Try again !!");
              }
            );
          } else if (res.data.Status === " Okay! ") {
            PNotify.info("Please Add Person first");
            this.props.history.push({
              pathname: "/AddEditPerson",
              state: {
                OTPtype: "FirstPerson",
                mobileNumber: this.state.mobileNumber,
                isMaterialOut: this.state.isMaterialOut
              }
            });
            return;
          } else if (
            res.data.Status ===
            "OTP already sent to this number. Either enter it in Person In form or wait for 5 minutes."
          ) {
            PNotify.info(
              "OTP already sent to this number. Either enter it in Person In form or wait for 5 minutes."
            );
            this.props.history.push("/dashboard");
            return;
          } else PNotify.error("Something Went Wrong !!");
        },
        () => {
          this.setState({ showspinner: false });
          PNotify.error("Something went Wrong.Try Again !!");
        }
      );
    } else {
      this.setState({
        showspinner: false,
        mobileNumber: ""
      });
      PNotify.error("Number should contain 10 digits only");
    }
  };

  checkVehicle = e => {
    if (e) e.preventDefault();
    if (this.state.vehicleType === "OnFoot") {
      this.setState({ successmsg: "showFields" });
      return;
    }
    this.setState({ showspinner: true });
    axiosPost(
      "/check_vehicle_inorout",
      { vehicleNumber: this.state.vehicleNumber },

      res => {
        //console.log(res);

        if (
          res.data.Status === "This vehicle never entered in the facility" ||
          res.data.Status === "This vehicle is not present inside the facility."
        ) {
          this.props.history.push({
            pathname: "/AddEditVehicle",
            mobileNumber: this.state.mobileNumber,
            vehicleNumber: this.state.vehicleNumber,
            vehicleType: this.state.vehicleType,
            name: this.state.personName,
            isMaterialOut: this.state.isMaterialOut
          });
          PNotify.info("Please fill Vehicle information");
          this.setState({ showspinner: false });
        } else if (res.data.Status === "Already In") {
          axiosPost(
            "/check_vehicle_number",
            { vehicleNumber: this.state.vehicleNumber },
            res => {
              this.setState({ showspinner: false });
              //console.log(res);
              let data = res.data;
              if (data.length === 1) {
                this.setState({
                  successmsg: "showFields",
                  vehicleNumber: data[0].vehicleNumber //just to be sure od vehicle number
                });
              } else {
                PNotify.error("Vehicle Data Not Found");
              }
            },
            () => {
              PNotify.error("Something Went Wrong !!");
              this.setState({
                vehicleNumber: "",
                showspinner: false
              });
            }
          );
        }
      },
      () => {
        this.setState({ showspinner: false });
      }
    );
  };
  checkStorage() {
    let t = this.state.items.find(e => e.Materialtype === "Storage Material");
    if (t) return true;
    else return false;
  }
  handleSubmit(event) {
    // handle submit button method
    event.preventDefault();
    const state = this.state;
    //for docking.js
    if (this.props.click) {
      this.props.click(event);
      return;
    }

    if (state.Materialtype !== "") this.AddMoreItems();
    if (state.items.length === 0) {
      PNotify.error("Please Add atleat one item.");
      return;
    }
    if (this.checkStorage() && !state.customerName) {
      PNotify.error("Customer Name is compulsory in case of Storage Material");
      return;
    }

    const data = {
      vehicleNumber: this.state.vehicleNumber,
      personId: this.state.mobileNumber,
      personName: this.state.personName,
      customerName: this.state.customerName,
      items: this.state.items,
      mobileNumber: this.state.mobileNumber,
      inorout: "in",
      dirOfMovement: this.state.dirOfMovement,
      dateOfMovement: this.state.dateOfMovement
    };
    if (this.checkStorage()) {
      if (this.state.isMaterialOut) data.docking = "out";
      else data.docking = "in";
    }
    let endpoint = "/material_inward_register";
    //setting endpoint, whether it is material out or in
    if (this.state.isMaterialOut) {
      endpoint = "/material_outward_register";
      data.inorout = "out";
      //console.log("material out");
    }
    delete data["showspinner"];
    delete data["successmsg"];
    delete data["vehicleList"];
    //console.log("material  data", data);
    this.setState({ showspinner: true });
    axiosPost(
      endpoint,
      data,
      res => {
        window.scrollTo(0, 0);
        this.setState({ showspinner: false, successmsg: "GoHome" });
        //console.log(res);
      },
      () => {
        this.setState({ showspinner: false });
      }
    );
  }
  //add and verify items for all material type
  AddMoreItems = action => {
    const {
      Materialtype,
      items,
      editItemIndex,
      //common second form fields
      quantity,
      name,
      category,
      source,
      destination,

      // storage material

      grnstnNumber,

      invoiceUri,
      stnChallan,

      totalTonnage,
      storageItems,
      //perosnal Item
      //all personal item fields are in common

      //documents
      fileNo,
      //name key is ued for document description

      //diesel
      billNo,

      //assets and spare
      purchaseInvoice
    } = this.state;

    let temp = items,
      obj;
    //adding new item or updating it
    if (action === "add" || action === "update") {
      if (Materialtype === "Storage Material") {
        if (
          !(grnstnNumber && invoiceUri && totalTonnage && source && destination)
        ) {
          PNotify.error("Please fill all required fields");
          return;
        }
        if (storageItems.length === 0) {
          PNotify.error("Atleat Add One Item");
          return;
        }
        obj = {
          Materialtype,
          grnstnNumber,
          invoiceUri,
          stnChallan,
          totalTonnage,
          source,
          destination,
          storageItems
        };
      } else if (Materialtype === "Personal Item") {
        if (!(name && quantity)) {
          PNotify.error("Please fill all required fields");
          return;
        }
        obj = {
          Materialtype,
          name,
          quantity,
          category,
          source,
          destination
        };
      } else if (Materialtype === "Assets" || Materialtype === "Spare Parts") {
        if (!(name && quantity)) {
          PNotify.error("Please fill all required fields");
          return;
        }
        obj = {
          Materialtype,
          name,
          quantity,
          category,
          purchaseInvoice
        };
      } else if (Materialtype === "Documents") {
        if (!name) {
          PNotify.error("Please fill all required fields");
          return;
        }
        obj = {
          Materialtype,
          name,
          fileNo,
          category,
          source,
          destination
        };
      } else if (Materialtype === "Diesel") {
        if (!quantity) {
          PNotify.error("Please fill all required fields");
          return;
        }
        obj = {
          Materialtype,
          billNo,
          quantity
        };
      }
      if (action === "add") {
        temp.push(obj);
      } else if (action === "update") {
        temp[editItemIndex - 1] = obj;
        PNotify.info("Item Updated");
      }
      //console.log(temp);
      this.setState({ items: temp });
    }

    //Reseting item fields
    this.setState({
      Materialtype: "",
      editItemIndex: undefined,
      showItemsForm: false,
      editStoreItemIndex: undefined,
      //common second form fields
      quantity: "",
      name: "",
      category: "",
      source: "",
      destination: this.props.location,

      // storage material
      transporter: "",

      temperature: "",
      weight: "",
      grnstnNumber: "",

      invoiceUri: "",
      stnChallan: "",

      totalTonnage: "",

      //Storage -> items field
      storageItems: [], // array will store items under type-Storage Material
      skuCode: "",

      unit: "",
      batchnumber: "",
      prodDate: "",
      expiryDate: "",
      expQty: "",
      recQty: "",
      remarks: "",

      //perosnal Item
      //all personal item fields are in common

      //documents
      fileNo: "",
      //name key is ued for document description

      //diesel
      billNo: "",

      //assets and spare
      purchaseInvoice: ""
    });
  };

  editItem = (item, index) => {
    //item is an object
    this.setState(item);
    //sometimes 0 can be index,which is false in js ,thereofere increment by one for proper functionality.
    this.setState({ editItemIndex: index + 1 });
  };
  //add item for type="Storage Material"
  addItem = action => {
    //console.log("item called");
    let temp = this.state.storageItems;
    const {
      skuCode,
      name,
      batchnumber,
      weight,
      unit,
      quantity,
      temperature,
      prodDate,
      expiryDate,
      remarks,
      editStoreItemIndex
    } = this.state;
    if (
      !(
        skuCode &&
        name &&
        weight &&
        unit &&
        quantity &&
        temperature &&
        batchnumber &&
        prodDate &&
        expiryDate
      )
    ) {
      PNotify.error("Please fill all required fields of item");
      return;
    }
    let obj = {
      skuCode,
      name,
      batchnumber,
      unit,
      weight,
      quantity,
      temperature,
      prodDate,
      expiryDate,
      remarks
    };
    if (action === "add") temp.push(obj);
    else if (action === "update") {
      temp[editStoreItemIndex - 1] = obj;
      PNotify.info("Item Updated");
    }
    //console.log(temp);
    this.setState({
      storageItems: temp,
      skuCode: "",
      name: "",
      unit: "",
      weight: "",
      temperature: "",
      batchnumber: "",
      prodDate: "",
      expiryDate: "",
      quantity: "",
      remarks: "",

      editStoreItemIndex: undefined,
      showItemsForm: false
    });
  };
  mapFields = fields =>
    fields.map((item, index) => (
      <Col md="6" key={item.name}>
        <Form.Group controlId={item.name}>
          <Form.Label className={item.required && "required"}>
            {item.title}
          </Form.Label>
          <Form.Control
            required={item.required ? true : false}
            readOnly={this.state.readOnly}
            type={item.type ? item.type : "text"}
            name={item.name}
            value={this.state[item.name]}
            onChange={this.handleChange}
            placeholder={`Enter ${item.title}`}
          />
        </Form.Group>
      </Col>
    ));

  render() {
    let pageTitle = "Material In";
    if (this.state.isMaterialOut) pageTitle = "Material Out";

    let BasicFields = [
      {
        title: "Name",
        name: "personName",
        readOnly: true
      },

      {
        title: "Person ID",
        name: "personId",
        readOnly: true
      },
      {
        title: "Direction of Movement",
        name: "dirOfMovement",
        readOnly: true
      },
      {
        title: "Date of Movement",
        name: "dateOfMovement",
        type: "date",
        readOnly: true,
        required: true
      }
    ];

    if (this.state.vehicleNumber)
      BasicFields.unshift({
        title: "Vehicle Number",
        name: "vehicleNumber",
        readOnly: true
      });

    let storageFields = [
      {
        title: "GRN",
        name: "grnstnNumber",
        required: true
      },
      {
        title: "Invoice Number",
        name: "invoiceUri",
        required: true
      },
      {
        title: "STN Challan Number",
        name: "stnChallan"
      },
      {
        title: "Total Tonnage",
        name: "totalTonnage",
        required: true
      },
      {
        title: "Destination",
        name: "destination",
        required: true
      },
      {
        title: "Source",
        name: "source",
        required: true
      }
    ];

    //Storage -> items field

    let storageItems = [
      {
        title: "SKU Code",
        name: "skuCode",
        required: true
      },
      {
        title: "Batch Number",
        name: "batchnumber",
        required: true
      },
      {
        title: "Weight of Unit (KG)",
        name: "weight",
        required: true
      },
      {
        title: "Quantity",
        name: "quantity",
        required: true
      },
      {
        title: "Temperature",
        name: "temperature",
        required: true
      },
      {
        title: "Production Date",
        name: "prodDate",
        required: true,
        type: "date"
      },
      {
        title: "Expiry Date",
        name: "expiryDate",
        required: true,
        type: "date"
      },
      {
        title: "Remarks",
        name: "remarks"
      }
    ];

    let personalItemsFields = [
      {
        title: "Product Name",
        name: "name",
        required: true
      },
      {
        title: "Quantity",
        name: "quantity",
        required: true
      },
      {
        title: "Source",
        name: "source"
      },
      {
        title: "Destination",
        name: "destination"
      }
    ];

    //asset and spare has same fields
    let assetsFields = [
      {
        title: "Name",
        name: "name",
        required: true
      },
      {
        title: "Quantity",
        name: "quantity",
        required: true
      },
      {
        title: "Category",
        name: "category"
      },
      {
        title: "Purchase Invoice",
        name: "purchaseInvoice"
      }
    ];

    let documentFields = [
      {
        title: "Document Description",
        name: "name",
        required: true
      },
      {
        title: "File Number",
        name: "fileNo"
      },
      {
        title: "Source",
        name: "source"
      },
      {
        title: "Destination",
        name: "destination"
      }
    ];

    let dieselFields = [
      {
        title: "Quantity",
        name: "quantity",
        required: true
      },
      {
        title: "Diesel Bill Number",
        name: "billNo"
      }
    ];

    let itemNames = [];
    if (this.state.customerName) {
      itemNames = this.state.customersList.find(
        e => e.custName === this.state.customerName
      ).custProducts;
    }
    //console.log(itemNames);
    if (this.props.data) pageTitle = "Check Material";

    if (["checkPhone", "checkVehicle"].includes(this.state.successmsg)) {
      return (
        <div
          style={{
            minHeight: "calc(100vh - 120px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Card small className="m-3 m-md-4" style={{ width: 400 }}>
            <LoadingOverlay
              active={this.state.showspinner}
              spinner
              text="Processing..."
            >
              <CardBody>
                <h3 className="p-3">{pageTitle}</h3>
                <div className="p-3">
                  {this.state.successmsg === "checkPhone" ? (
                    <Row>
                      <Col sm="12">
                        <Form onSubmit={this.checkMobile}>
                          <Row>
                            <Col sm="12">
                              <Form.Group controlId="mobileNumber">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                  // readOnly={this.state.readOnly}
                                  required
                                  type="number"
                                  name="mobileNumber"
                                  value={this.state.mobileNumber}
                                  onChange={this.handleChange}
                                  placeholder="Enter Your Mobile Number"
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              sm="12"
                              className="submit-button-container text-center"
                            >
                              <Button
                                type="submit"
                                pill
                                className="submit-button"
                                style={{
                                  minWidth: "150px",
                                  padding: "10px 20px",
                                  fontSize: "16px"
                                }}
                              >
                                <i className="material-icons"></i>Check
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm="12">
                        <Form onSubmit={this.checkVehicle}>
                          <Row>
                            <Col sm="12">
                              <Form.Group>
                                <Form.Label>Vehicle Type</Form.Label>
                                <Form.Control
                                  as="select"
                                  required
                                  id="vehicleType"
                                  name="vehicleType"
                                  value={this.state.vehicleType || ""}
                                  onChange={this.handleChange}
                                >
                                  <option value="" disabled>
                                    Choose Vehicle
                                  </option>
                                  <option value="OnFoot">On Foot</option>
                                  <option value="Personal Vehicle(4 wheeler)">
                                    Personal Vehicle(4 wheeler)
                                  </option>
                                  <option value="Personal Vehicle(2 wheeler)">
                                    Personal Vehicle(2 wheeler)
                                  </option>
                                  <option value="Commercial Vehicle(4 seater)">
                                    Commercial Vehicle(4 seater)
                                  </option>
                                  <option value="Commercial Vehicle(6 seater)">
                                    Commercial Vehicle(6 seater)
                                  </option>
                                  <option value="Commercial Vehicle(7 seater)">
                                    Commercial Vehicle(7 seater)
                                  </option>
                                  <option value="Material Carrier">
                                    Material Carrier
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            {this.state.vehicleType &&
                              this.state.vehicleType !== "OnFoot" && (
                                <Col sm="12">
                                  <Form.Group>
                                    <Form.Label>Vehicle Number</Form.Label>
                                    <Form.Control
                                      required={true}
                                      type={"text"}
                                      name={"vehicleNumber"}
                                      onChange={this.handleChange}
                                      value={this.state.vehicleNumber}
                                      placeholder={`Enter Vehicle Number`}
                                    />
                                  </Form.Group>
                                </Col>
                              )}

                            <Col
                              sm="12"
                              className="submit-button-container text-center"
                            >
                              <Button
                                type="submit"
                                pill
                                className="submit-button"
                                style={{
                                  minWidth: "150px",
                                  padding: "10px 20px",
                                  fontSize: "16px"
                                }}
                              >
                                <i className="material-icons"></i>Check
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  )}
                </div>
              </CardBody>
            </LoadingOverlay>
          </Card>
        </div>
      );
    } else if (this.state.successmsg === "GoHome")
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10
            }}
          >
            <Col md="3">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
          </Row>
        </div>
      );
    else if (this.state.successmsg === "showFields")
      return (
        <Card small className="m-3 m-md-4">
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text="Processing..."
          >
            <CardBody>
              <h3 className="p-3">{pageTitle}</h3>
              <div className="p-3">
                <Row>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        {BasicFields.map((item, index) => (
                          <Col md="6" key={index}>
                            <Form.Group>
                              <Form.Label className="required">
                                {item.title}
                              </Form.Label>
                              <Form.Control
                                required={item.required ? true : false}
                                readOnly={item.readOnly ? true : false}
                                type={item.type ? item.type : "text"}
                                name={item.name}
                                value={this.state[item.name]}
                                placeholder={`Enter ${item.title}`}
                                onChange={
                                  !item.readOnly
                                    ? this.handleChange
                                    : () => false
                                }
                              />
                            </Form.Group>
                          </Col>
                        ))}
                        {/* <Col md="6">
                          <Form.Group>
                            <Form.Label className="required">
                              Direction of Movement
                            </Form.Label>
                            <Form.Control
                              as="select"
                              required={true}
                              name="dirOfMovement"
                              value={this.state.dirOfMovement || ""}
                              onChange={this.handleChange}
                            >
                              <option value="" disabled>
                                Choose Mode of Movement
                              </option>

                              <option value="Inward">Inward</option>
                              <option value="Outward">Outward</option>
                            </Form.Control>
                          </Form.Group>
                        </Col> */}

                        <Col md="6">
                          <Form.Group controlId="customerName">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                              as="select"
                              name="customerName"
                              value={this.state.customerName || "0"}
                              onChange={this.handleChange}
                            >
                              <option value="0" disabled>
                                Choose Customer Name
                              </option>

                              {this.state.customersList.map(e => (
                                <option key={e.custName} value={e.custName}>
                                  {e.custName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group controlId="Materialtype">
                            <Form.Label>Material Type:</Form.Label>
                            <Form.Control
                              as="select"
                              name="Materialtype"
                              value={this.state.Materialtype || "0"}
                              onChange={this.handleChange}
                            >
                              <option value="0" disabled>
                                Choose Material Type
                              </option>

                              <option value="Storage Material">
                                Storage Material
                              </option>
                              <option value="Personal Item">
                                Personal Item
                              </option>
                              <option value="Documents">Documents</option>
                              <option value="Assets">Assets</option>
                              {/* Diesel option is not shown in case of material out */}
                              {!this.state.isMaterialOut && (
                                <option value="Diesel">Diesel</option>
                              )}

                              <option value="Spare Parts">Spare Parts</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Show Title alert when update of an item is going on */}
                      {this.state.editItemIndex && (
                        <div className="text-center mb-2 p-1 alert-info">
                          Update Item
                        </div>
                      )}
                      {this.state.Materialtype && (
                        <Row>
                          <h5 className="col-12">
                            {this.state.Materialtype} Details
                          </h5>
                          {/* Storage Material Details Form */}
                          {this.state.Materialtype === "Storage Material" && (
                            <>
                              {this.mapFields(storageFields)}
                              {!this.state.showItemsForm &&
                                (this.state.storageItems.length === 0 ? (
                                  <div className="w-100 mx-4 text-center border p-3 my-2">
                                    No Item Added
                                  </div>
                                ) : (
                                  <div className="d-flex flex-column my-2 border w-100 p-3 mx-4">
                                    {!this.state.editStoreItemIndex &&
                                      this.state.storageItems.map(
                                        (item, index) => (
                                          <div key={index}>
                                            {index + 1}.{item.skuCode}
                                            <div className="float-sm-right text-center">
                                              <EditIcon
                                                className="mx-2"
                                                style={{
                                                  color: "#00b8d8",
                                                  fontSize: "larger",
                                                  cursor: "pointer"
                                                }}
                                                onClick={e => {
                                                  e.preventDefault();
                                                  this.setState(item);
                                                  this.setState({
                                                    editStoreItemIndex:
                                                      index + 1
                                                  });
                                                  if (
                                                    this.state.showItemsForm ===
                                                    false
                                                  )
                                                    this.setState({
                                                      showItemsForm: true
                                                    });
                                                }}
                                              />
                                              <CancelIcon
                                                className="mx-2"
                                                style={{
                                                  color: "#c4183c",
                                                  fontSize: "larger",
                                                  cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                  let temp = this.state
                                                    .storageItems;
                                                  temp = [
                                                    ...temp.slice(0, index),
                                                    ...temp.slice(
                                                      index + 1,
                                                      temp.length
                                                    )
                                                  ];
                                                  this.setState({
                                                    storageItems: temp
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                ))}
                              {this.state.showItemsForm && (
                                <>
                                  <hr className="w-75" />
                                  <h5 className="col-12">Item Details</h5>
                                  <Col md="6">
                                    <Form.Group controlId="name">
                                      <Form.Label className="required">
                                        Item Name
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="name"
                                        value={this.state.name || "0"}
                                        onChange={this.handleChange}
                                        required={true}
                                      >
                                        <option value="0" disabled>
                                          Choose Name
                                        </option>

                                        {itemNames.map(i => (
                                          <option key={i} value={i}>
                                            {i}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  {this.mapFields(storageItems.slice(0, 3))}
                                  <Col md="6">
                                    <Form.Group controlId="unit">
                                      <Form.Label className="required">
                                        Unit
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="unit"
                                        value={this.state.unit || "0"}
                                        onChange={this.handleChange}
                                        required={true}
                                      >
                                        <option value="0" disabled>
                                          Choose Any One Unit
                                        </option>

                                        <option value="Bags">Bags</option>
                                        <option value="Boxes">Boxes</option>
                                        <option value="Drums">Drums</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  {this.mapFields(storageItems.slice(3))}
                                </>
                              )}

                              <Button
                                theme="dark"
                                type="button"
                                size="sm"
                                className="ml-4 my-2"
                                onClick={e => {
                                  e.preventDefault();
                                  if (this.state.showItemsForm === false) {
                                    if (!this.state.customerName) {
                                      PNotify.notice(
                                        "Please Choose a Customer first."
                                      );
                                      return;
                                    }
                                    this.setState({ showItemsForm: true });
                                  } else {
                                    if (this.state.editStoreItemIndex)
                                      this.addItem("update");
                                    else this.addItem("add");
                                  }
                                }}
                              >
                                {this.state.editStoreItemIndex
                                  ? "Update"
                                  : this.state.showItemsForm
                                  ? "Add"
                                  : "Add New Item"}
                              </Button>
                            </>
                          )}
                          {/* Personal Item Details Form */}
                          {this.state.Materialtype === "Personal Item" && (
                            <>
                              <Col md="6">
                                <Form.Group>
                                  <Form.Label>Product Category</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="category"
                                    value={this.state.category || "0"}
                                    onChange={this.handleChange}
                                  >
                                    <option value="0" disabled>
                                      Choose Product Category
                                    </option>

                                    <option value="Laptop">Laptop</option>
                                    <option value="Other">Other</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>

                              {this.mapFields(personalItemsFields)}
                            </>
                          )}
                          {/* Cfor Document Details */}
                          {this.state.Materialtype === "Documents" && (
                            <>
                              {this.mapFields(documentFields.slice(0, 2))}
                              <Col md="6">
                                <Form.Group>
                                  <Form.Label>Document Category</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="category"
                                    value={this.state.category || "0"}
                                    onChange={this.handleChange}
                                  >
                                    <option value="0" disabled>
                                      Choose Document Category
                                    </option>

                                    <option value="Admin">Admin</option>
                                    <option value="HR">HR</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Account">Account</option>
                                    <option value="Research">Research</option>
                                    <option value="IT">IT</option>
                                    <option value="Projects">Projects</option>
                                    <option value="Consulting">
                                      Consulting
                                    </option>
                                    <option value="Others">Others</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              {this.mapFields(documentFields.slice(2))}
                            </>
                          )}

                          {/* Cfor Assets Details */}
                          {this.state.Materialtype === "Assets" && (
                            <>{this.mapFields(assetsFields)}</>
                          )}

                          {/* Cfor Diesel Details */}
                          {this.state.Materialtype === "Diesel" &&
                            !this.state.isMaterialOut && (
                              <>{this.mapFields(dieselFields)}</>
                            )}

                          {/* Cfor Spare Details */}
                          {this.state.Materialtype === "Spare Parts" && (
                            <>{this.mapFields(assetsFields)}</>
                          )}
                        </Row>
                      )}

                      {/* For Adding More Items */}
                      {this.state.Materialtype && (
                        <div>
                          <Button
                            theme="info"
                            type="button"
                            size="sm"
                            className="ml-2"
                            onClick={e => {
                              e.preventDefault();
                              if (this.state.editItemIndex)
                                this.AddMoreItems("update");
                              else this.AddMoreItems("add");
                            }}
                          >
                            {this.state.editItemIndex
                              ? "Update"
                              : this.state.Materialtype !== "Storage Material"
                              ? "Add"
                              : "Save"}
                          </Button>
                          <Button
                            theme="danger"
                            type="button"
                            size="sm"
                            className="ml-1"
                            onClick={e => {
                              e.preventDefault();
                              this.AddMoreItems();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      )}

                      {!this.state.editItemIndex &&
                        this.state.items.length >= 1 && (
                          <div className="d-flex flex-column my-2 p-2 border">
                            <p className="text-secondary mb-2">Item List:</p>
                            {this.state.items.map((item, index) => (
                              <div key={index}>
                                <span>{index + 1}. </span>
                                <span>{item.Materialtype}</span>
                                <div className="float-sm-right text-center">
                                  <EditIcon
                                    className="mx-2"
                                    style={{
                                      color: "#00b8d8",
                                      fontSize: "larger",
                                      cursor: "pointer"
                                    }}
                                    onClick={e => {
                                      e.preventDefault();
                                      this.editItem(item, index);
                                    }}
                                  />
                                  <CancelIcon
                                    className="mx-2"
                                    style={{
                                      color: "#c4183c",
                                      fontSize: "larger",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      let temp = this.state.items;
                                      temp = [
                                        ...temp.slice(0, index),
                                        ...temp.slice(index + 1, temp.length)
                                      ];
                                      this.setState({ items: temp });
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      <div
                        className={
                          this.props.data
                            ? "row justify-content-around mt-3"
                            : "row justify-content-center mt-3"
                        }
                      >
                        {this.props.data && (
                          <Button
                            theme="accent"
                            type="button"
                            size="sm"
                            className="col col-auto d-block"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ readOnly: false });
                            }}
                          >
                            <i className="material-icons"></i>Edit
                          </Button>
                        )}
                        <Button
                          theme="accent"
                          type="submit"
                          size="sm"
                          className="col col-auto d-block"
                          style={{ fontSize: "medium" }}
                        >
                          <i className="material-icons"></i>Submit
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </LoadingOverlay>
          <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
        `}</style>
        </Card>
      );
    else return null;
  }
}

const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url
});
export default connect(mapStateToProps)(AddEditMaterial);
