import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, FormInput, Button } from "shards-react";
import "../../assets/range-date-picker.css";
import AxiosPost from "../common/AxiosPost";

import "../../assets/range-date-picker.css";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import PNotify from "pnotify/dist/es/PNotify";

class AddEditPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: "",
      showspinner: false,
      successmsg: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const name = event.target.id;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.mobileNumber.length !== 10) {
      PNotify.error("Number should contain 10 digits");
      return;
    }
    this.setState({ showspinner: true });
    //console.log("url", this.props.url);
    //console.log("We are in the submit function");
    ////console.log(this.state);
    const data = {
      ...this.state,
      inorout: "out",
      location: this.props.location
    };
    delete data["showspinner"];
    delete data["successmsg"];
    //console.log("data from person out to b send is ", data);
    AxiosPost(
      "/person_outward_register",
      data,
      res => {
        //console.log(res);
        window.scrollTo(0, 0);
        this.setState({ showspinner: false });
        if (res.status === 200) {
          if (res.data === "Done") this.setState({ successmsg: true });
          else {
            PNotify.error(res.data.Status);
          }
        } else {
          PNotify.error("Something went wrong !");
        }
      },
      () => {
        PNotify.error("Something went wrong !");
        this.setState({ showspinner: false });
      }
    );
  }

  render() {
    let pageTitle;
    pageTitle = "Person Out";

    if (this.state.successmsg)
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10
            }}
          >
            <Col md="3" className="mb-4">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
            <Col md="3">
              <Link
                to={`/Vehicle_Out?ownerId=${this.state.mobileNumber}`}
                style={{ textDecoration: "none" }}
              >
                <SGActionsCard
                  type="vehicle"
                  title="Add Vehicle Exit"
                  hideFooter
                />
              </Link>
            </Col>
          </Row>
        </div>
      );

    return (
      <div
        style={{
          minHeight: "calc(100vh - 120px)",
          display: this.state.otpverified ? "block" : "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card
          small
          className="m-3 m-md-4"
          style={{ width: this.state.otpverified ? "auto" : 400 }}
        >
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text="Processing..."
          >
            <CardBody>
              <h3 className="p-3">{pageTitle}</h3>

              <Form onSubmit={this.handleSubmit} className="px-3">
                <Row>
                  <Col md="12" className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <FormInput
                      placeholder="Mobile Number"
                      type="number"
                      id="mobileNumber"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="form-group submit-button-container"
                    style={{ marginTop: "30px" }}
                  >
                    {/* <button style={{ marginBottom: "10px" }} class="btn btn-primary btn-lg center" onclick="checkMobNumber(); otp()">Verify </button> */}
                    <Button pill type="submit" className="submit-button">
                      <i className="material-icons"></i>Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </LoadingOverlay>
          <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
          .submit-button-container {
            margin-top: 20px;
            text-align: center;
          }
          .submit-button {
            min-width: 150px;
            padding: 10px 20px;
            font-size: 16px;
          }
          ._loading_overlay_overlay {
            border-radius: .625rem;
          }
        `}</style>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url
});
export default connect(mapStateToProps)(AddEditPerson);
