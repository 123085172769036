import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../common/TableView";
import AxiosPost from "../../common/AxiosPost";
import { Card, CardBody, CardTitle } from "shards-react";
import CardHeading from "../../common/CardHeading";
class ViewMaterialWard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Materialtype: "",
      data: [],
      isLoading: true
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
    }
  }
  fetch = () => {
    AxiosPost("/material_in_out_table", { start: 0, length: 10000 }, res => {
      this.setState({ isLoading: false });
      //console.log(res.data);
      if (res.data) {
        let newArr = [],
          temp = [];
        if (Array.isArray(res.data)) {
          newArr = res.data;
          newArr.forEach(e => {
            let temp1 = { ...e };
            if (e.items)
              e.items.forEach(i => {
                if (i.Materialtype === this.state.Materialtype) {
                  temp1.items = { ...i };

                  temp.push({ ...temp1 });
                }
              });
          });
        }

        //console.log("temp ", temp);
        this.setState({
          data: temp
            .reverse()
            .slice(0, res.data.length)
            .map((m, index) => ({
              icon: m.inorout,
              oname: m.items && m.items.name,
              odName: m.personName,
              ovNum: m.vehicleNumber || "On Foot",
              time: m.inorout === "in" ? m.in_time : m.out_time,
              info: m._id["$oid"]
            }))
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.path === "/View_AllDocuments")
      this.setState({ Materialtype: "Documents" });

    this.fetch();
  }
  handleChange = info => {
    //console.log(info);
    //console.log("clocked");
    this.props.history.push({ pathname: "/material", oid: info });
  };

  render() {
    return (
      <div className="MethodMaterial">
        <Card small className="m-3 m-md-4">
          <CardBody>
            <CardTitle className="p-3">
              <CardHeading
                title={`
             ${this.state.Materialtype} In Out Records 
            `}
                icon={"topic"}
              />
            </CardTitle>
            <div className="p-3">
              <div>
                {this.state.isLoading ? (
                  <div className="p-3">
                    <div className="spinner-border d-block m-auto" />
                  </div>
                ) : (
                  <TableView
                    data={this.state.data}
                    handleChange={info => this.handleChange(info)}
                    headings={[
                      "In/Out",
                      "Doc Name",
                      "Driver",
                      "Vehicle Number",
                      "Date & Time"
                    ]}
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewMaterialWard));
