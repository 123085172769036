import React, { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Card, CardBody, CardTitle } from "shards-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import LoadingOverlay from "react-loading-overlay";
import AxiosPost from "../common/AxiosPost";
import { useSelector } from "react-redux";
import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import MessageCard from "../common/MessageCard";
import { Link } from "react-router-dom";

import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 1000;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddEditCustomer() {
  const store = useSelector(state => state);

  const [showspinner, setSpinner] = useState(false);
  const [data, setData] = useState({
    custName: "",
    custRegAdd: "",
    custType: "",
    custLandline: "",
    custPAN: "",
    custWebsite: "",
    custAreas: "",
    preAuthVisitor: "",
    preAuthVisitorPhn: "",
    locationMaterialMap: "",
    custGSTNums: [],
    custProducts: [],

    tempdata: "",
    tempnums: [],
    tempnames: []
  });
  const [open, setOpen] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleClickOpen = val => {
    setOpen(val);
  };

  function handleClose(type) {
    //console.log("close func type:", type);

    setOpen(false);
    setData({ ...data, tempdata: "" });
    if (type === "cancel gst")
      setData({ ...data, tempnums: [...data.custGSTNums] });
    else if (type === "ok gst")
      setData({ ...data, custGSTNums: [...data.tempnums] });
    else if (type === "cancel products")
      setData({ ...data, tempnames: [...data.custProducts] });
    else if (type === "ok products")
      setData({ ...data, custProducts: [...data.tempnames] });
  }

  function handleSubmit(e) {
    e.preventDefault();
    //console.log("inside submit");
    if (data.preAuthVisitorPhn.toString().length !== 10) {
      PNotify.error("Phone Number should contain 10 digits");
      return;
    } else if (data.custGSTNums.length === 0) {
      PNotify.error("Please add atleast one GST Number");
      return;
    } else if (data.custProducts.length === 0) {
      PNotify.error("Please add atleast one Product Name");
      return;
    }
    let body = {
      ...data,
      tempdata: undefined,
      tempnums: undefined,
      tempnames: undefined
    };
    //console.log(body);
    setSpinner(true);
    AxiosPost(
      "/addcustomer",
      body,
      res => {
        //console.log(res);
        setSpinner(false);
        setSubmitSuccess(true);
      },
      () => {
        setSpinner(false);
        PNotify.error({
          text: "Something Went Wrong.Try again !"
        });
      }
    );
  }

  function UpdateGST(val) {
    //console.log("update");
    if (val === "gst") {
      let arr = [...data.tempnums];
      arr.push(data.tempdata);
      setData({ ...data, tempnums: arr, tempdata: "" });
    } else {
      let arr = [...data.tempnames];
      arr.push(data.tempdata);
      setData({ ...data, tempnames: arr, tempdata: "" });
    }
  }
  function handleChange(e) {
    let name = e.target.name;
    let val = e.target.value;
    let newdata = { ...data, [name]: val };
    setData(newdata);
  }
  const fields = [
    {
      key: "custName",
      placeholder: " Name"
    },
    {
      key: "custRegAdd",
      placeholder: " Address"
    },
    {
      key: "custType",
      placeholder: " Type"
    },
    {
      key: "custLandline",
      placeholder: " Landline",
      type: "number"
    },

    {
      key: "custPAN",
      placeholder: " PAN Card"
    },
    {
      key: "custWebsite",
      placeholder: " Website"
    },
    {
      key: "custAreas",
      placeholder: " Area"
    },
    {
      key: "preAuthVisitor",
      placeholder: "Pre Auth Visitor"
    },
    {
      key: "preAuthVisitorPhn",
      placeholder: "Pre Auth Visitor Phone Number",
      type: "number"
    },
    {
      key: "locationMaterialMap",
      placeholder: "Location Material Map"
    }
  ];
  if (submitSuccess)
    return (
      <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
        <div style={{ margin: "0 auto", maxWidth: 680 }}>
          <MessageCard title="Success" subtitle="Customer added." />
        </div>
        <Row
          style={{
            justifyContent: "center",
            marginTop: 40,
            marginBottom: 10
          }}
        >
          <Col md="3">
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <SGActionsCard type="home" title="Go Home" hideFooter />
            </Link>
          </Col>
        </Row>
      </div>
    );
  return (
    <Card small className="m-3 m-md-4">
      <LoadingOverlay active={showspinner} spinner text="Processing...">
        <CardBody>
          <CardTitle className="p-3 h3">{"Add Customer"}</CardTitle>
          <div className="p-3">
            <Row>
              <Col sm={12}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" className="mb-2">
                      <Row>
                        {fields.map((field, ind) => (
                          <Col md="6" key={ind}>
                            <Form.Group>
                              <Form.Label className="required">
                                {ind < 7 && "Firm "} {field.placeholder}
                              </Form.Label>
                              <Form.Control
                                required={true}
                                type={field.type ? field.type : "text"}
                                name={field.key}
                                value={data[field.key]}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        ))}
                        {["gst", "products"].map(e => (
                          <Col
                            md="6"
                            className="d-flex flex-column justify-content-center mt-2 mt-md-0"
                            key={e}
                          >
                            <div>
                              <Button
                                theme="accent"
                                variant="outline-primary"
                                size="md"
                                onClick={() => handleClickOpen(e)}
                              >
                                {e === "gst"
                                  ? "Add GST Numbers"
                                  : "Add Product Names"}
                              </Button>

                              <Dialog
                                open={open === e}
                                TransitionComponent={Transition}
                                keepMounted
                                //onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                              >
                                <DialogTitle id="alert-dialog-slide-title">
                                  {e === "gst"
                                    ? "Add GST Numbers"
                                    : "Add Product Names"}
                                </DialogTitle>
                                <DialogContent>
                                  <Row>
                                    <Col xs="10" className="pr-1">
                                      <Form.Group>
                                        <Form.Control
                                          placeholder={
                                            e === "gst"
                                              ? "Enter GST Number"
                                              : "Enter Product Name"
                                          }
                                          name="tempdata"
                                          value={data.tempdata}
                                          onChange={handleChange}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs="2" className="p-0">
                                      <AddBoxRoundedIcon
                                        style={{
                                          fontSize: "xx-large",
                                          cursor: "pointer"
                                        }}
                                        onClick={() => UpdateGST(e)}
                                      />
                                    </Col>
                                    <Col sm="10">
                                      {e === "gst" ? (
                                        <ul>
                                          {data.tempnums.map((num, key) => (
                                            <li key={key}>{num}</li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <ul>
                                          {data.tempnames.map((num, key) => (
                                            <li key={key}>{num}</li>
                                          ))}
                                        </ul>
                                      )}
                                    </Col>
                                  </Row>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => handleClose("cancel " + e)}
                                    variant="outline-danger"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => handleClose("ok " + e)}
                                    variant="outline-primary"
                                  >
                                    ok
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <Row className="mt-3 justify-content-center">
                        <Col className="col-auto">
                          <Button type="submit" theme="accent" size="md">
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}

export default AddEditCustomer;
