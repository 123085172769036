import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import AxiosPost from "../common/AxiosPost";
import CardHeading from "../common/CardHeading";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { Card, CardBody, CardTitle } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 3000;

export default function View_employess(props) {
  const store = useSelector(state => state);
  const [data, setData] = useState();
  const [loadertext, setLoadertext] = useState();
  const [load, setLoad] = useState(true);
  {
    const [pages, setPages] = useState({
      start: 0,
      length: 100000
    });
    const [pagenum, setPagenum] = useState(1);
    const [isEoa, setEoa] = useState(false);

    function handleAlert(type, msg) {
      PNotify.alert({
        type: type,
        text: msg
      });
    }
    useEffect(() => {
      // setLoadertext("Loading....");

      let data = {
        start: pages.start,
        length: pages.length
      };
      AxiosPost(
        "/employee_table",
        data,
        res => {
          //console.log(res);
          let newArr = res.data.filter(
            ele => ele.mobileNumber !== store.auth.dashboard_data.mobileNumber
          );
          setData(newArr);
          if (res.data.length === 0) handleAlert("notice", "No more data");
          else if (res.data.length < 10) setEoa(true);
          else setEoa(false);
          setLoadertext();
        },
        () => {
          handleAlert("error", "Something went wrong");
          setLoadertext();
        }
      );
    }, [load, pages]);

    function setpage(type) {
      setLoad(type);
      let newdata;
      if (type === "next") {
        newdata = { ...pages, start: pagenum * 10 };
        //console.log(newdata);
        setPages(newdata);
        setPagenum(pagenum + 1);
      } else {
        newdata = { ...pages, start: pages.start === 9 ? 0 : pages.start - 10 };
        // console.log(newdata);
        setPages(newdata);
        setPagenum(pagenum - 1);
      }
    }

    function handleDelete(num) {
      //console.log(num);
      setLoadertext("Deleting");

      AxiosPost(
        "/delete_employee",
        { mobileNumber: num },
        res => {
          //console.log(res);
          if (res.data.Status === "Success") {
            setLoadertext();
            setLoad(!load);
            handleAlert("info", "Successfully Deleted");
          } else {
            handleAlert("error", "Something went wrong");
          }
        },
        e => {
          // console.log(e);
          setLoadertext();
          handleAlert("error", "Something went wrong");
        }
      );
    }
    return (
      <React.Fragment>
        <div>
          <Card small className="m-3 m-md-4">
            <CardBody>
              <CardTitle className="p-3">
                <CardHeading
                  title={"Users"}
                  displayLocation={"All Locations"}
                  icon={"assignment_ind"}
                />
              </CardTitle>
              {data ? (
                <LoadingOverlay
                  active={loadertext ? true : false}
                  spinner
                  text={loadertext}
                >
                  <div className="p-3">
                    <Table responsive className="mt-1 text-center">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Username</th>
                          <th>Mobile Number</th>
                          <th>Role</th>
                          <th>Edit/Delete</th>
                        </tr>
                      </thead>
                      {data &&
                        data.map((row, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{pages.start + index + 1}</td>
                              <td>{row.username}</td>
                              <td>{row.mobileNumber}</td>
                              <td>{row.role}</td>

                              <td>
                                <div className="d-flex flex-row justify-content-center">
                                  <Link
                                    to={{
                                      pathname: "/AddEditEmployee",
                                      state: { EditNumber: row.mobileNumber }
                                    }}
                                  >
                                    <button className="btn btn-outline-info mr-2">
                                      Edit
                                    </button>
                                  </Link>
                                  <button
                                    className="btn btn-outline-danger"
                                    onClick={e => {
                                      e.preventDefault();
                                      handleDelete(row.mobileNumber);
                                    }}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="btn btn-outline-warning ml-2"
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/View_Attendance",
                                        mobileNumber: row.mobileNumber
                                      });
                                    }}
                                  >
                                    View Attendance
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                    {/* <div className="d-flex justify-content-center">
                      <Pagination>
                        <Pagination.Prev
                          disabled={pagenum === 1 && true}
                          onClick={e => {
                            e.preventDefault();
                            setpage("prev");
                          }}
                        />

                        <Pagination.Item active>{pagenum}</Pagination.Item>

                        <Pagination.Next
                          disabled={isEoa}
                          onClick={e => {
                            e.preventDefault();
                            setpage("next");
                          }}
                        />
                      </Pagination>
                    </div> */}
                  </div>
                </LoadingOverlay>
              ) : (
                <div className="p-3">
                  <div className="spinner-border d-block m-auto" />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
