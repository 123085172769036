import React from "react";
import EditDeleteTable from "../common/EditDeleteTable";
import { Card, CardBody, CardTitle } from "shards-react";
import CardHeading from "../common/CardHeading";

function View_Customers() {
  const url = {
    endpoint: "get_customers",
    edit: "update_cust_entry",
    delete: "delete_cust"
  };
  const EditRoute = "/AddEditCustomer";
  const deleteKey = {
    key: "_id",
    bodykey: "snoid"
  };
  const keyinfo = [
    {
      keyname: "custName",
      displayName: "Customer Name"
    },
    {
      keyname: "custType",
      displayName: "Customer Type"
    },
    {
      keyname: "custLandline",
      displayName: "Customer Number"
    }
  ];

  return (
    <Card small className="m-3 m-md-4">
      <CardBody>
        <CardTitle className="p-3">
          <CardHeading
            title={"All Customers"}
            icon={"person_outline"}
            displayLocation="All Locations"
          />
        </CardTitle>

        <EditDeleteTable
          url={url}
          keyinfo={keyinfo}
          EditRoute={EditRoute}
          deleteKey={deleteKey}
        />
      </CardBody>
    </Card>
  );
}

export default View_Customers;
