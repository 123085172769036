import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...rest }) {
  const { path } = rest;

  //console.log("protected rooute path:", path);

  return (
    <Route
      {...rest}
      render={props => {
        if (
          sessionStorage.dashboard_data &&
          JSON.parse(sessionStorage.dashboard_data)
        ) {
          const dashData =
            sessionStorage.dashboard_data &&
            JSON.parse(sessionStorage.dashboard_data).role;
          // console.log("dashdata defined",dashData);
          //remove '||true' from below if condition
          if (
            dashData &&
            dashData[0].routes &&
            Object.keys(dashData[0].routes).includes(path)
          )
            return <Component {...props} />;
          else return <Redirect to="/errors" />;
        } else {
          // console.log("dashdata not defined");
          return (
            <Redirect
              to={{ to: "/", state: { from: props.location.pathname } }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
