import React from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Button } from "shards-react";
import AxiosPost from "../common/AxiosPost";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

function DockConsent({ history, item, setDisplay, info }) {
  const [loader, setLoader] = React.useState(false);

  console.log(info);
  function changeToPending(e) {
    e.preventDefault();
    //console.log("actual cliked at id ", item);
    let i = { ...item };
    i.docking = "pending";
    i._id = i._id.$oid;
    i.assignedSupervisor = {
      username: info.username,
      mobileNumber: info.mobileNumber
    };
    i.counts = {};
    //to prevent errors
    item.counts = {};
    console.log(i);
    setLoader(true);
    AxiosPost(
      "/update_docking_queue_entry",
      i,
      res => {
        console.log(res);
        setLoader(false);
        if (res.data.Status === " Okay! ") setDisplay("mcards");
        else PNotify.error("Something Went Wrong");
      },
      e => {
        PNotify.error("Something Went Wrong");
        setLoader(false);
        console.log(e);
      }
    );
  }
  return (
    <div
      style={{
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Card small className="m-3 m-md-4" style={{ width: 400 }}>
        <LoadingOverlay active={loader} spinner text="Please wait...">
          {item && item.assignedSupervisor ? (
            <CardBody className="p-md-5 p-4">
              <h4>Under Supervision</h4>
              <p className="text-secondary mt-4">
                {"This Material item is supervised by "}
                <span style={{ fontWeight: 600 }}>
                  {item.assignedSupervisor.username}
                </span>
                {".You can only view it."}
              </p>
              <div className="d-flex justify-content-end mt-5">
                <Button
                  pill
                  className="submit-button btn-danger mr-2"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  pill
                  className="submit-button"
                  onClick={() => setDisplay("mcards")}
                >
                  Okay
                </Button>
              </div>
            </CardBody>
          ) : (
            <CardBody className="p-md-5 p-4">
              <h4>{"Are you sure ?"}</h4>
              <p className="text-secondary mt-4">
                Your name will registered under this dock.
              </p>
              <div className="d-flex justify-content-end mt-5">
                <Button
                  pill
                  className="submit-button btn-danger mr-2"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  pill
                  className="submit-button"
                  onClick={changeToPending}
                >
                  Okay
                </Button>
              </div>
            </CardBody>
          )}
        </LoadingOverlay>
      </Card>
    </div>
  );
}

export default withRouter(DockConsent);
