import React from "react";
import axiosPost from "../common/AxiosPost";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../common/TableView";

class ViewAllInFacility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
    }
  }

  fetch = () => {
    axiosPost("/get_ptype_num", {}, res => {
      //console.log(res);

      this.setState({ isLoading: false });

      if (res.data) {
        if (this.props.setNumber) {
          let total = 0;
          res.data.forEach(ele => {
            total += ele.pcount;
          });
         // console.log(total);
          this.props.setNumber(total);
        }

        this.setState({
          data: res.data
        });
      }
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="p-3">
          <div className="spinner-border d-block m-auto" />
        </div>
      );

    return (
      <TableView
        data={this.state.data}
        headings={!this.props.limitRecords && ["Person Type", "Numbers"]}
      />
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewAllInFacility));
