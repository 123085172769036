import React from "react";
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import './components/common/Common.css'
import routes from "./routes";
import withTracker from "./withTracker";

import { Provider } from "react-redux";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/Login/Login";
import Errors from "./dashboard/Errors";
//import { DefaultLayout } from "./layouts";
{
  /*for old code just remove commented portion */
}

export default () => (
  <Provider store={store}>
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
    <Switch>
        <Route exact path="/" component={Login} />
        {routes.map((route, index) => {
          return (
            <ProtectedRoute
              key={index}
              path={route.path}
              exact={true}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}

        {/* <Route
          path="*"
          component={withTracker(props => {
            return (
              <DefaultLayout {...props}>
                <Errors {...props} />
              </DefaultLayout>
            );
          })}
        /> */}
        <Route path="*" component={Errors}/>
        
      </Switch>
      </div>
    </Router>
  </Provider>
);

{
  /*trying to make authentication export default class Auth extends React.Component{
      constructor() {
        this.authenticated = true;
      }


      login(cb) {
        this.authenticated = true;
        cb();
      }

      logout(cb) {
        this.authenticated = false;
        cb();
      }

      isAuthenticated() {
        return this.authenticated;
      }
      render(){
        return(
          <div>
            <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
    {(() => {
       if(this.Authenticated==true)
       {
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
        }
      })()}
    </div>
  </Router>
          </div>
        )
      }
    }
 */
}
