import React from "react";
import { Form } from "react-bootstrap";
import { Card, CardTitle, CardBody, Button } from "shards-react";

import Image from "../../assets/logistics-illustration.png";

const getStepTitle = step => {
  switch (step) {
    case 0:
      return "Add Facility";
    case 1:
      return "Add Chamber";
    case 2:
      return "Add Manager";
    case 3:
      return "Add Employee";
    default: return "";
  }
};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      name: "",
      email: "",
      location: "",
      password: ""
    };
  }
  render() {
    return (
      <div
        style={{
          minHeight: "calc(100vh)",
          background:
            "linear-gradient(to bottom, #e8f6f5, #ecf3f3, #e3e2e3, #fbfcfc)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card
          small
          style={{ width: 760, display: "flex", flexDirection: "row" }}
        >
          <CardBody>
            <CardTitle
              className="p-3"
              style={{ color: "#003131", fontSize: 28 }}
            >
              {getStepTitle(this.state.step)}
            </CardTitle>
            <div className="p-3">
              {this.state.step > 1 ? (
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </Form.Group>
              ) : (
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </Form.Group>
              )}
              {this.state.step > 1 ? (
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </Form.Group>
              ) : (
                <Form.Group controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    placeholder="Enter location"
                    value={this.state.location}
                    onChange={e => this.setState({ location: e.target.value })}
                  />
                </Form.Group>
              )}
              <div style={{ textAlign: "center", marginTop: 30 }}>
                <Button
                  pill
                  style={{
                    minWidth: 150,
                    padding: "10px 20px",
                    fontSize: 16
                  }}
                  onClick={() =>
                    this.setState({
                      name: "",
                      email: "",
                      location: "",
                      password: "",
                      step: this.state.step + 1
                    })
                  }
                >
                  {this.state.step > 2 ? "Submit" : "Next"}
                </Button>
              </div>
            </div>
          </CardBody>
          <img
            src={Image}
            style={{
              maxWidth: 400,
              maxHeight: 350,
              objectFit: "cover",
              borderTopRightRadius: "0.635em",
              borderBottomRightRadius: "0.635em"
            }}
          />
        </Card>

        <style>{`
          .form-label {
            color: #003131;
            font-size: 12px;
          }
          input.form-control {
              border: none;
              border-bottom: 1px solid #f3f3f3;
              border-radius: 0;
              box-shadow: none!important;
              padding-left: 0;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
        `}</style>
      </div>
    );
  }
}

export default Registration;
