import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../common/TableView";
import AxiosPost from "../../common/AxiosPost";
import { Card, CardBody, CardTitle } from "shards-react";
import CardHeading from "../../common/CardHeading";
class ViewMaterialWard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMaterialOutward: false, //check url params and then show records accordingly
      data: [],
      isLoading: true
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.SelectedLocation !== prevProps.SelectedLocation) {
      this.setState({ isLoading: true });
      this.fetch();
    }
  }
  fetch = () => {
    AxiosPost("/material_in_out_table", { start: 0, length: 10000 }, res => {
      this.setState({ isLoading: false });
      //console.log(res.data);
      if (res.data) {
        let newArr = [],
          temp = [];
        if (Array.isArray(res.data)) {
          if (this.state.isMaterialOutward) {
            newArr = res.data.filter(e => e.inorout === "out");
          } else newArr = res.data.filter(e => e.inorout === "in");

          newArr.forEach(e => {
            let temp1 = { ...e };
            if (e.items)
              e.items.forEach(i => {
                if (i.Materialtype === "Storage Material") {
                  temp1.items = { ...i };
                  i.storageItems.forEach(e => {
                    temp1.storageItems = e;
                    temp.push({ ...temp1 });
                  });
                }
              });
          });
        }

        //console.log("temp ", temp);
        this.setState({
          data: temp
            .reverse()
            .slice(0, res.data.length)
            .map((m, index) => ({
              time: m.inorout === "in" ? m.in_time : m.out_time,
              billNo: temp.length - index,
              customerName: m.customerName,
              Article: m.storageItems.name,
              aQuantity: m.storageItems.quantity,
              aunit: m.storageItems.unit,
              challan: m.items.stnChallan,
              dName: m.personName,
              vNum: m.vehicleNumber || "On Foot",
              info: m._id["$oid"]
            }))
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.path === "/ViewMaterialOutward")
      this.setState({ isMaterialOutward: true });

    this.fetch();
  }
  handleChange = info => {
    //console.log(info);
    //console.log("clocked");
    this.props.history.push({ pathname: "/material", oid: info });
  };

  render() {
    return (
      <div className="MethodMaterial">
        <Card small className="m-3 m-md-4">
          <CardBody>
            <CardTitle className="p-3">
              <CardHeading
                title={`
              Storage Material ${
                this.state.isMaterialOutward ? "Outward" : "Inward"
              } 
              
            `}
                icon="storage"
              />
            </CardTitle>
            <div className="p-3">
              <div>
                {this.state.isLoading ? (
                  <div className="p-3">
                    <div className="spinner-border d-block m-auto" />
                  </div>
                ) : (
                  <TableView
                    data={this.state.data}
                    handleChange={info => this.handleChange(info)}
                    headings={
                      !this.props.limitRecords && [
                        "Date & Time",
                        "Gate Pass No",
                        "Name of the Party",
                        "Article",
                        "Quantity",
                        "Unit",
                        "Challan Number",
                        "Driver Name",
                        "Vehicle Number"
                      ]
                    }
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url,
  SelectedLocation: state.auth.location
});

export default withRouter(connect(mapStateToProps)(ViewMaterialWard));
