import React from "react";
import { Card } from "shards-react";
import Icon from "@material-ui/core/Icon";

export default ({ title, subtitle }) => {
  return (
    <div style={{ position: "relative" }}>
      <Card
        className="p-4"
        style={{
          borderRadius: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: "-1px 2px 10px 0px rgba(0,0,0,0.07)"
        }}
      >
        <div className="pr-4">
          <div
            style={{
              backgroundColor: "rgb(0, 208, 82)",
              borderRadius: "50%",
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Icon style={{ color: "#fff", fontSize: 20 }}>check</Icon>
          </div>
        </div>
        <div>
          {title && (
            <div style={{ fontSize: 20, fontWeight: "bold" }}>{title}</div>
          )}
          {subtitle && (
            <div style={{ color: "#aaa", fontWeight: "400" }}>{subtitle}</div>
          )}
        </div>
      </Card>
      <div
        style={{
          position: "absolute",
          top: 0,
          width: 3,
          height: "100%",
          backgroundColor: "rgb(0, 208, 82)"
        }}
      />
    </div>
  );
};
