import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AxiosPost from "../common/AxiosPost";
import MaterialCards from "./MaterialCards.jsx";
import DockConsent from "./DockConsent";
import OTPcard from "./OTPcard";

function SelectedDock({ history }) {
  let item = history.location.item;
  const [Display, setDisplay] = useState();
  const [isAnotherSupervisor, setIsAnotherSupervisor] = useState(false);
  const info = useSelector(state => state.auth.dashboard_data);
  useEffect(() => {
    if (!item) {
      history.push("/dashboard");
      return;
    }
    console.log(item);
    if (
      item.assignedSupervisor &&
      item.assignedSupervisor.mobileNumber === info.mobileNumber
    ) {
      if (item.pauth) setDisplay("otp");
      else setDisplay("mcards");
    } else if (
      item.assignedSupervisor &&
      item.assignedSupervisor.mobileNumber !== info.mobileNumber
    )
      setIsAnotherSupervisor(true);
  }, []);
  if (Display === "mcards")
    return (
      <MaterialCards
        item={item}
        setDisplay={setDisplay}
        isAnotherSupervisor={isAnotherSupervisor}
      />
    );
  else if (Display === "otp")
    return <OTPcard setDisplay={setDisplay} item={item} />;
  else return <DockConsent item={item} setDisplay={setDisplay} info={info} />;
}

export default SelectedDock;
