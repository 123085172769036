import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Card, CardBody, CardTitle, Button } from "shards-react";
import { Redirect } from "react-router-dom";
import OtpInput from "react-otp-input";

import "../Login/Login.css";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { dashboard_data, handle_location } from "../../actions/postActions";
import PNotify from "pnotify/dist/es/PNotify";
import AxiosPost from "../common/AxiosPost";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      password: "",
      otp: "",
      error: "",
      processing: false,
      showOtpInput: false,
      redirect: false
    };
  }

  render() {
    const {
      mobile,
      password,
      error,
      processing,
      showOtpInput,
      otp,
      redirect
    } = this.state;

    if (redirect === true) return <Redirect to="/Dashboard" />;

    return (
      <>
        <div
          style={{
            minHeight: "100vh",
            background:
              "linear-gradient(to bottom, #e8f6f5, #ecf3f3, #e3e2e3, #fbfcfc)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div>
            <div
              style={{ textAlign: "center", fontSize: 30, color: "#003131" }}
            >
              <span style={{ fontWeight: 900 }}>mool</span>
              <span style={{ fontWeight: 100 }}>WMS</span>
              <div
                style={{
                  height: 2,
                  width: 50,
                  background: "#003b75",
                  margin: "20px auto 100px auto"
                }}
              />
            </div>
            <Card small className="m-4 logiCardWidth">
              <CardBody>
                <CardTitle
                  className="p-3"
                  style={{ color: "#003131", fontSize: 28 }}
                >
                  Login
                </CardTitle>
                <div className="p-3">
                  <form method="post">
                    {!showOtpInput ? (
                      <>
                        <Form.Group controlId="phone_number">
                          <Form.Label>Phone number</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone_number"
                            value={mobile}
                            onChange={e =>
                              this.setState({ mobile: e.target.value })
                            }
                            placeholder="Enter number"
                          />
                        </Form.Group>
                        <Form.Group controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={e =>
                              this.setState({ password: e.target.value })
                            }
                            placeholder="Enter password"
                          />
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: 12,
                            color: "#9db0b0",
                            fontWeight: 400
                          }}
                        >
                          <span>Enter the OTP you received to</span>
                          <br />
                          <span style={{ color: "#003131", marginTop: 20 }}>
                            {mobile}
                          </span>
                        </div>
                        <OtpInput
                          value={otp}
                          onChange={otp => this.setState({ otp })}
                          numInputs={6}
                          //otpContainerStyle class is in Common.css
                          containerStyle="otpContainerStyle"
                          inputStyle={{
                            width: 40,
                            height: 55,
                            background: "#e8f5f5",

                            borderRadius: 6
                          }}
                          focusStyle={{ outline: "none" }}
                          className="my-4"
                        />
                      </>
                    )}
                    {error !== "" && (
                      <Alert
                        variant="danger"
                        style={{ color: "#721c24", backgroundColor: "#f8d7da" }}
                      >
                        {error}
                      </Alert>
                    )}

                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <Button
                        type="submit"
                        pill
                        disabled={
                          (!showOtpInput &&
                            (mobile === "" || password === "")) ||
                          (showOtpInput && otp === "") ||
                          processing
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ processing: true, error: "" });
                          //console.log({ mob: mobile, state: this.state });
                          if (!showOtpInput) {
                            if (mobile.length !== 10) {
                              PNotify.error("Number should contain 10 digits");
                              this.setState({ processing: false });
                              return;
                            }
                            AxiosPost(
                              "/preOTPlogin",
                              { mobileNumber: mobile, password },
                              res => {
                                //console.log(res.data);
                                if (res.data.includes("sentOTP"))
                                  this.setState({
                                    showOtpInput: true,
                                    processing: false
                                  });
                                else
                                  this.setState({
                                    processing: false,
                                    error: "Invalid credentials!"
                                  });
                              },
                              () => {
                                this.setState({
                                  processing: false,
                                  error: "Something went wrong!"
                                });
                              }
                            );
                          } else {
                            AxiosPost(
                              "/login",
                              { otp },
                              async res => {
                                //console.log(res);
                                if (
                                  res.status === 200 &&
                                  typeof res.data === "object" &&
                                  res.data.constructor === Object &&
                                  Object.keys(res.data.info.role).length !== 0
                                ) {
                                  let locations =
                                    res.data.info.role[0].locations;
                                  //console.log(locations);
                                  if (locations.length === 0) {
                                    PNotify.error(
                                      "Aborting! Location has no data"
                                    );
                                    this.setState({
                                      processing: false
                                    });
                                    return;
                                  }
                                  sessionStorage.setItem(
                                    "selectedLocation",
                                    JSON.stringify(locations[0])
                                  );
                                  this.props.handle_location(locations[0]);

                                  sessionStorage.setItem(
                                    "dashboard_data",
                                    JSON.stringify(res.data.info)
                                  );
                                  // sessionStorage.setItem(
                                  //   "selectedLocation",
                                  //   JSON.stringify(
                                  //     res.data.info.role[0].locations[0]
                                  //   )
                                  // );
                                  // //console.log(sessionStorage.dashboard_data && JSON.parse(sessionStorage.dashboard_data))

                                  await this.props.dashboard_data(
                                    res.data.info
                                  );
                                  // //console.log(res.data.info.role[0].locations[0])
                                  // await this.props.handle_location(
                                  //   res.data.info.role[0].locations[0]
                                  // );
                                  //saving token
                                  if (res.data.token) {
                                    sessionStorage.setItem(
                                      "token",
                                      JSON.stringify(res.data.token)
                                    );
                                  }

                                  if (this.props.location.state)
                                    this.props.history.push(
                                      this.props.location.state.from
                                    );
                                  this.setState({
                                    redirect: true,
                                    processing: false
                                  });
                                } else
                                  this.setState({
                                    processing: false,
                                    error: "Login failed!"
                                  });
                              },
                              err => {
                                //console.log("The eroor is", err);
                                this.setState({
                                  processing: false,
                                  error: "Something went wrong!"
                                });
                              }
                            );
                          }
                        }}
                        style={{
                          minWidth: 150,
                          padding: "10px 20px",
                          fontSize: 16
                        }}
                      >
                        {processing
                          ? "Processing..."
                          : showOtpInput
                          ? "Login"
                          : "Continue"}
                      </Button>
                    </div>
                  </form>
                </div>
              </CardBody>
              <style>{`
                .form-label {
                  color: #003131;
                  font-size: 12px;
                }
                input.form-control {
                    border: none;
                    border-bottom: 1px solid #f3f3f3;
                    border-radius: 0;
                    box-shadow: none!important;
                    padding-left: 0;
                }
                input.form-control, input.form-control:focus{
                  color: #000;
                  font-weight: normal;
                }
                .otpContainerStyle div{
                  margin-right:.25rem!important;
                  margin-top:1.5rem!important;

                }
              `}</style>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  url: state.auth.url
});

const mapDispatchToProps = dispatch => ({
  dashboard_data: data => dispatch(dashboard_data(data)),
  handle_location: data => dispatch(handle_location(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
