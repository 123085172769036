import React, { Component } from "react";
// import './App.css';
//import { Col, Button, Alert } from "react-bootstrap";
import { Card, CardBody, CardTitle } from "shards-react";
import ViewAllMaterialWrapper from "./ViewAllMaterialWrapper";
import AxiosPost from "../../common/AxiosPost";
import CardHeading from "../../common/CardHeading";
class MethodMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      product: {},
      isEditProduct: false
    };
  }
  editProduct = productId => {
   // console.log(productId);
    const val = { snoid: productId };
    //console.log(val, "heyyyy");
    AxiosPost("/get_material_entry", val, result => {
      //console.log(result.data);
      this.setState({
        product: result.data,
        isEditProduct: true
      });
    });
  };
  render() {
    // let productForm;
    // if (this.state.isAddProduct || this.state.isEditProduct) {
    //   productForm = <AddEditMaterial product={this.state.product} />;
    // }

    return (
      <div className="MethodMaterial">
        <Card small className="m-3 m-md-4">
          <CardBody>
            <CardTitle className="p-3">
              <CardHeading
                title={"Material In Out Records"}
                icon={"list_alt"}
              />
            </CardTitle>
            <div className="p-3">
              <ViewAllMaterialWrapper />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default MethodMaterial;
