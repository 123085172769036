import React, { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Card, CardBody, CardTitle } from "shards-react";
//import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import LoadingOverlay from "react-loading-overlay";
import axiosPost from "../common/AxiosPost";
import { useSelector } from "react-redux";
import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import MessageCard from "../common/MessageCard";
import { Link } from "react-router-dom";
import PNotify from "pnotify/dist/es/PNotify";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 3000;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddEditLocation() {
  const store = useSelector(state => state);

  const [showspinner, setSpinner] = useState(false);
  const [data, setData] = useState({
    locName: "",
    locAddress: "",
    numDocks: "",
    numGates: "",
    personIncharge: "",
    reportingManagers: [],
    inChargePhn: "",
    stateGSTNum: "",

    tempname: "",
    tempnumber: "",
    tempnums: [],
    Err: false
  });
  const [open, setOpen] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleClose(type) {
    //console.log("close func type:", type);
    setOpen(false);
    if (type === "cancel")
      setData({ ...data, tempnums: [...data.reportingManagers] });
    else if (type === "ok")
      setData({ ...data, reportingManagers: [...data.tempnums] });
  }

  function handleSubmit(e) {
    e.preventDefault();
    //console.log("inside submit");
    if (data.inChargePhn.length !== 10) {
      PNotify.error("Incharge Phone Number should conatin 10 digits");
      return;
    } else if (data.reportingManagers.length === 0) {
      setData({ ...data, Err: true });
      return;
    }
    setSpinner(true);
    let body = {
      ...data,
      location: store.auth.location,
      tempname: undefined,
      tempnumber: undefined,
      tempnums: undefined,
      Err: undefined
    };
    //console.log(body);

    axiosPost(
      "/addlocation",
      body,
      res => {
        //console.log(res);
        setSpinner(false);
        setSubmitSuccess(true);
      },
      () => {
        setSpinner(false);
        PNotify.error({
          text: "Something Went Wrong.Try again !"
        });
      }
    );
  }

  function UpdateManagers(e) {
    //console.log("update");
    e.preventDefault();
    if (data.tempnumber.length === 0 || data.tempname.length === 0) {
      PNotify.error({
        text: "Both Fields are compulsory"
      });
      return;
    } else if (data.tempnumber.length !== 10) {
      PNotify.error({
        text: "Number Should contain 10 digit"
      });
      return;
    }

    let arr = [...data.tempnums];
    arr.push({ empName: data.tempname, mobileNumber: data.tempnumber });
    setData({
      ...data,
      tempnums: arr,
      tempname: "",
      tempnumber: "",
      Err: undefined
    });
  }
  function handleChange(e) {
    let name = e.target.name;
    let val = e.target.value;
    let newdata = { ...data, [name]: val };
    setData(newdata);
  }
  const fields = [
    {
      key: "locName",
      placeholder: "Location Name"
    },
    {
      key: "locAddress",
      placeholder: "Location Address"
    },
    {
      key: "numDocks",
      placeholder: "Total number of Docks",
      type: "number"
    },
    {
      key: "numGates",
      placeholder: "Total number of Gates",
      type: "number"
    },

    {
      key: "personIncharge",
      placeholder: "Person Incharge"
    },
    {
      key: "inChargePhn",
      placeholder: "Incharge Phone Number",
      type: "number"
    },
    {
      key: "stateGSTNum",
      placeholder: "State GST Number"
    }
  ];
  if (submitSuccess)
    return (
      <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
        <div style={{ margin: "0 auto", maxWidth: 680 }}>
          <MessageCard title="Success" subtitle="Location added." />
        </div>
        <Row
          style={{
            justifyContent: "center",
            marginTop: 40,
            marginBottom: 10
          }}
        >
          <Col md="3">
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <SGActionsCard type="home" title="Go Home" hideFooter />
            </Link>
          </Col>
        </Row>
      </div>
    );
  return (
    <Card small className="m-3 m-md-4">
      <LoadingOverlay active={showspinner} spinner text="Processing...">
        <CardBody>
          <CardTitle className="p-3 h3">{"Add Location"}</CardTitle>
          <div className="p-3">
            <Row>
              <Col sm={12}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" className="mb-2">
                      <Row>
                        {fields.map((field, ind) => (
                          <Col md="6" key={ind}>
                            <Form.Group>
                              <Form.Label className="required">
                                {field.placeholder}
                              </Form.Label>
                              <Form.Control
                                required={true}
                                type={field.type ? field.type : "text"}
                                name={field.key}
                                value={data[field.key]}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        ))}
                        <Col
                          md="6"
                          className="d-flex flex-column justify-content-center"
                        >
                          <div>
                            <Button
                              theme="accent"
                              variant="outline-primary"
                              size="md"
                              onClick={handleClickOpen}
                            >
                              {"Add Reporting Managers"}
                            </Button>
                            {data.Err && (
                              <div className="text-danger">
                                Please Enter Atleast One Manager
                              </div>
                            )}
                            <Dialog
                              open={open}
                              TransitionComponent={Transition}
                              keepMounted
                              //onClose={handleClose}
                              aria-labelledby="alert-dialog-slide-title"
                              aria-describedby="alert-dialog-slide-description"
                            >
                              <DialogTitle id="alert-dialog-slide-title">
                                {"Add Reporting Managers"}
                              </DialogTitle>
                              <DialogContent>
                                <Row>
                                  <Col xs="10" className="pr-1">
                                    <Row>
                                      <Col xs="12">
                                        <Form.Group>
                                          <Form.Control
                                            placeholder="Enter Name of Manager"
                                            name="tempname"
                                            value={data.tempname}
                                            onChange={handleChange}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Form.Group>
                                          <Form.Control
                                            placeholder="Enter Phone Number of Manager"
                                            name="tempnumber"
                                            value={data.tempnumber}
                                            type="number"
                                            onChange={handleChange}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs="2" className="p-0">
                                    <AddBoxRoundedIcon
                                      className="my-4 ml-2"
                                      style={{
                                        fontSize: "40px",
                                        cursor: "pointer"
                                      }}
                                      onClick={UpdateManagers}
                                    />
                                  </Col>
                                  <Col sm="10">
                                    <ul>
                                      {data.tempnums.map((manager, key) => (
                                        <li key={key}>
                                          {manager.empName}(
                                          {manager.mobileNumber})
                                        </li>
                                      ))}
                                    </ul>
                                  </Col>
                                </Row>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleClose("cancel")}
                                  variant="outline-danger"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => handleClose("ok")}
                                  variant="outline-primary"
                                >
                                  ok
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3 justify-content-center">
                        <Col className="col-auto">
                          <Button type="submit" theme="accent" size="md">
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}

export default AddEditLocation;
