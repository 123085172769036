import React from "react";
import { Row, Form, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  ListGroupItem,
  FormInput,
  FormSelect,
  Button
} from "shards-react";

function PersonForm({
  read,
  handleChange,
  state,
  customerDatalist,
  managersDatalist,
  EmployeeDatalist,
  updateState,
  handleCamera
}) {
  return (
    <div>
      <div>
        <CardHeader className="py-0">
          <h6 className="mb-0">User Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4 border-0">
          <Row>
            <Col md="6">
              <Form.Group controlId="name">
                <Form.Label className="required">Name</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="text"
                  name="name"
                  value={state.name}
                  //value={ state.name || "sam"}
                  onChange={!read ? handleChange : undefined}
                  placeholder={"Full Name"}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="fathersName">
                <Form.Label className="required">Father's Name</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="text"
                  name="fathersName"
                  value={state.fathersName}
                  onChange={!read ? handleChange : undefined}
                  placeholder="Father's Name"
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Label htmlFor="gender" className="required">
                  Gender
                </Form.Label>
                <FormSelect
                  id="gender"
                  name="gender"
                  value={state.gender || "0"}
                  // defaultValue='0'
                  onChange={!read ? handleChange : undefined}
                  disabled={read}
                >
                  <option value="0" disabled>
                    Choose Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="homeAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  readOnly={state.personInData.homeAddress ? true : false}
                  type="text"
                  name="homeAddress"
                  value={state.homeAddress}
                  onChange={handleChange}
                  placeholder="Address"
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  readOnly={state.personInData.pincode ? true : false}
                  type="text"
                  name="pincode"
                  value={state.pincode}
                  onChange={handleChange}
                  placeholder="Pincode"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">City</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="text"
                  name="city"
                  value={state.city}
                  onChange={!read ? handleChange : undefined}
                  placeholder="City"
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Label className="required">State</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="text"
                  name="state"
                  value={state.state}
                  onChange={!read ? handleChange : undefined}
                  placeholder="State"
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Label className="required">ID Type</Form.Label>
                <FormSelect
                  id="idType"
                  name="idType"
                  value={state.idType || "0"}
                  // defaultValue='0'
                  onChange={!read ? handleChange : undefined}
                  disabled={read}
                >
                  <option value="0" disabled>
                    Select Id Type
                  </option>
                  <option value="aadharCard">Aadhar Card</option>
                  <option value="rationCard">Ration Card</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="id">
                <Form.Label className="required">ID Number</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="text"
                  name="id"
                  value={state.id}
                  onChange={!read ? handleChange : undefined}
                  placeholder="Enter ID Number"
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="dOB">
                <Form.Label className="required">Date Of Birth</Form.Label>
                <Form.Control
                  required={true}
                  readOnly={read}
                  type="date"
                  name="dOB"
                  value={state.dOB}
                  onChange={!read ? handleChange : undefined}
                  placeholder="Date Of Birth"
                />
                <p className="text-danger">{state.dobErr}</p>
              </Form.Group>
            </Col>
            <Col md="6" className="form-group">
              <Form.Group>
                <label htmlFor="personType" className="required">
                  Choose Person Type
                </label>
                <FormSelect
                  id="personType"
                  value={state.personTypenum}
                  onChange={updateState}
                >
                  <option value="0" disabled>
                    Choose Person Type
                  </option>
                  <option value="1">Customer</option>
                  <option value="2">Labour</option>
                  <option value="3">Contracted Employee</option>
                  <option value="4">Truck Driver</option>
                  <option value="5">Visitor</option>
                  <option value="6">Intern</option>
                  <option value="7">Supplier</option>
                  <option value="8">Employee</option>
                </FormSelect>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Label className="required">
                  {" "}
                  Do you have a Vehicle?
                </Form.Label>
                <FormSelect
                  id="haveVehicle"
                  name="haveVehicle"
                  value={state.haveVehicle || "0"}
                  required
                  onChange={handleChange}
                >
                  <option value="0" disabled>
                    Choose Yes or No
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </FormSelect>
              </Form.Group>
            </Col>
            {state.haveVehicle === "no" && (
              <Col md="6">
                <Form.Group>
                  <Form.Label className="required">
                    {" "}
                    Do you have a Material?
                  </Form.Label>
                  <FormSelect
                    id="haveMaterial"
                    name="haveMaterial"
                    value={state.haveMaterial || "0"}
                    required
                    onChange={handleChange}
                  >
                    <option value="0" disabled>
                      Choose Yes or No
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </FormSelect>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Button theme="secondary" onClick={handleCamera}>
                Take Image
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      </div>

      {/* Customer details start */}
      <div
        style={{
          display: state.personType === "Customer" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Customer Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            <Col md="6">
              <Form.Group controlId="presentCompany">
                <Form.Label className="required">Company Name</Form.Label>{" "}
                {/* Option to write the customer name and then the system searches the name from the database and gives an option
                              to the user and upon choosing the present company the authorised person of the company (customer) gets and OTP notifying him of the visit of the person and he can 
                              give the OTP to the person for allowing the person to enter the facility. */}
                <Form.Control
                  autoComplete="off"
                  required={true}
                  type="text"
                  name="presentCompany"
                  value={state.presentCompany}
                  onChange={handleChange}
                  placeholder="Please enter company name."
                  list={state.presentCompany.length > 0 ? "customers" : ""}
                />
                {customerDatalist}
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>

      {/* Labour Details Start */}
      <div
        style={{
          display: state.personType === "Labour" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Labour Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            <Col md="6">
              <Form.Group controlId="dailyWageRate">
                <Form.Label className="required">Daily Wage</Form.Label>
                <Form.Control
                  required={true}
                  type="number"
                  name="dailyWageRate"
                  value={state.dailyWageRate}
                  onChange={handleChange}
                  placeholder="Daily Wage"
                />
              </Form.Group>
            </Col>

            {/* This has to be a dropdown of the employees having the authorisation as per role access to let labour in the facility. */}
            <Col md="6">
              <Form.Group controlId="reportingManagerName">
                <Form.Label className="required">Reporting Manager</Form.Label>
                <Form.Control
                  autoComplete="off"
                  required={true}
                  type="text"
                  name="reportingManagerName"
                  value={state.reportingManagerName}
                  onChange={handleChange}
                  placeholder="Please Enter Reporting Manager"
                  list={state.reportingManagerName.length > 0 ? "managers" : ""}
                />
                {managersDatalist}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="esicNumber">
                <Form.Label>ESIC Number</Form.Label>
                <Form.Control
                  required={true}
                  type="number"
                  name="esicNumber"
                  value={state.esicNumber}
                  onChange={handleChange}
                  placeholder="ESIC Number"
                />
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
      {/* Driver Details start */}

      <div
        style={{
          display: state.personType === "Truck Driver" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Driver Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            {/* Instead of whomtoReport we should ask for customer for which the driver has gotten material.*/}
            <Col md="6">
              <Form.Group controlId="Customer_Name">
                <Form.Label className="required">Customer Name</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="Customer_Name"
                  value={state.Customer_Name}
                  onChange={handleChange}
                  placeholder="Customer Name"
                  list={state.Customer_Name.length > 0 ? "customers" : ""}
                  autoComplete={"off"}
                />
                {customerDatalist}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="drivingLicense">
                <Form.Label className="required">Driving License</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="drivingLicense"
                  value={state.drivingLicense}
                  onChange={handleChange}
                  placeholder="Driving License"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="driverPresentCompany">
                <Form.Label className="required">
                  Transporter Company
                </Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="driverPresentCompany"
                  value={state.driverPresentCompany}
                  onChange={handleChange}
                  placeholder="Transporter Company"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="isDriverSober">
                <Form.Label className="required">Is Driver Sober?</Form.Label>
                <FormSelect
                  required={true}
                  type="text"
                  name="isDriverSober"
                  id="isDriverSober"
                  value={
                    // personInData.isDriverSober ||
                    state.isDriverSober
                  }
                  onChange={handleChange}
                  placeholder="Is Driver Sober?"
                >
                  <option value={""} disabled>
                    Choose
                  </option>
                  <option value={"No"}>No</option>
                  <option value={"Yes"}>Yes</option>
                </FormSelect>
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>

      {/* //Visitor Code */}
      <div
        style={{
          display: state.personType === "Visitor" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Visitor Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            {/* There should be a drop down of the employees of the company authorised to have visitors. */}
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Whom To Meet</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="whomToMeetorReport"
                  value={state.whomToMeetorReport}
                  onChange={handleChange}
                  placeholder="Please choose the employee name."
                  list={
                    state.whomToMeetorReport.length > 0 ? "employee" : undefined
                  }
                  autoComplete={"off"}
                />
                {EmployeeDatalist}
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
      {/* End of Visitor Code */}

      {/* Intern Code */}
      <div
        style={{
          display: state.personType === "Intern" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Intern Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            {/* There should be a drop down of the employees of the company authorised to have visitors. */}
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Reporting Manager</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="whomToMeetorReport"
                  value={state.whomToMeetorReport}
                  onChange={handleChange}
                  placeholder="Please choose the employee name."
                  list={
                    state.whomToMeetorReport.length > 0 ? "employee" : undefined
                  }
                  autoComplete={"off"}
                />
                {EmployeeDatalist}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="EmployeeCode">
                <Form.Label className="required">Intern Code</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="EmployeeCode"
                  value={state.EmployeeCode}
                  onChange={handleChange}
                  placeholder="Intern Code"
                />
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
      {/* End of Intern Code */}

      {/* Supplier Code */}
      <div
        style={{
          display: state.personType === "Supplier" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h6 className="mb-0">Supplier Details</h6>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Company Name</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="companyName"
                  value={state.companyName}
                  onChange={handleChange}
                  placeholder="Enter Your Company Name"
                  autoComplete={"off"}
                />
                {EmployeeDatalist}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Whom To Meet</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  name="whomToMeetorReport"
                  value={state.whomToMeetorReport}
                  onChange={handleChange}
                  placeholder="Please choose the employee name."
                  list={
                    state.whomToMeetorReport.length > 0 ? "employee" : undefined
                  }
                  autoComplete={"off"}
                />
                {EmployeeDatalist}
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
      {/* End of Supplier Code */}

      <div
        style={{
          display: state.personType === "Contracted Employee" ? "block" : "none"
        }}
      >
        <CardHeader className="border-bottom">
          <h4 className="mb-0">Employee Details</h4>
        </CardHeader>
        <ListGroupItem className="p-4">
          <Row>
            {/* <Col md="6">
                              <Form.Group controlId="EmployeeCode">
                                <Form.Label>Employee Code</Form.Label>
                                <Form.Control
                                  required={true}
                                  type="text"
                                  name="EmployeeCode"
                                  value={state.EmployeeCode}
                                  onChange={handleChange}
                                  placeholder="Employee Code"
                                />
                              </Form.Group>
                            </Col> */}
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Reporting Manager</Form.Label>
                <Form.Control
                  autoComplete="off"
                  required={true}
                  type="text"
                  name="reportingManagerName"
                  value={state.reportingManagerName}
                  onChange={handleChange}
                  placeholder="Please Enter Reporting Manager"
                  list={
                    state.reportingManagerName.length > 0
                      ? "managers"
                      : undefined
                  }
                />
                {managersDatalist}
              </Form.Group>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
    </div>
  );
}

export default PersonForm;
