import axios from "axios";
//let url = "http://localhost:5000";
let url = "http://moolwms.us-east-2.elasticbeanstalk.com";
function AxiosPost(endpoint, body, handleSuccess, handleError) {
  let temp = {
    ...body,
    location: sessionStorage.selectedLocation
      ? JSON.parse(sessionStorage.selectedLocation)
      : undefined,
    token: sessionStorage.token ? JSON.parse(sessionStorage.token) : undefined
  };

  axios
    .post(url + endpoint, temp)
    .then(res => {
      if (res.data === "Token not sent!" || res.data === "Unauth") {
        alert(res.data);
        return;
      }
      if (handleSuccess) handleSuccess(res);
    })
    .catch(err => {
      //console.log(err);
      if (handleError) handleError(err);
    });
}

export default AxiosPost;
