import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { Card, CardTitle, CardBody, Button } from "shards-react";
import axiosPost from "../common/AxiosPost";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";

import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;
class AddEditVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      owner_Id:
        this.props.location.search !== ""
          ? this.props.location.search.split("?ownerId=")[1]
          : "",
      vehicleNumber: "",
      showspinner: false,
      successmsg: false
    };

    // if (props.product) {
    //   this.state = props.product;
    // } else {
    //   this.state = this.initialState;
    // }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    //console.log("Vehicle Out submit function..");
    event.preventDefault();
    this.setState({ showspinner: true });

    axiosPost(
      "/vehicle_outward_register",
      { vehicleNumber: this.state.vehicleNumber },
      res => {
        window.scrollTo(0, 0);
        this.setState({ showspinner: false });
        //console.log(res);
        if (res.data && res.data.Status && res.data.Status === " Okay! ") {
          this.setState({ successmsg: true });
        } else if (res.data && res.data.Status) PNotify.error(res.data.Status);
        else PNotify.error("Something Went Wrong.Please Try again !!");
      },
      () => {
        this.setState({ showspinner: false });
        PNotify.error("Something Went Wrong.Please Try again !!");
      }
    );
  }

  render() {
    let pageTitle;
    pageTitle = "Vehicle Out";

    if (this.state.successmsg)
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10
            }}
          >
            <Col md="3" className="mb-4">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
            <Col md="3">
              <Link to={`/Material_Out`} style={{ textDecoration: "none" }}>
                <SGActionsCard
                  type="material"
                  title="Add Material Exit"
                  hideFooter
                />
              </Link>
            </Col>
          </Row>
        </div>
      );

    return (
      <div
        style={{
          minHeight: "calc(100vh - 120px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card small className="m-3 m-md-4" style={{ width: 400 }}>
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text="Processing..."
          >
            <CardBody>
              <h3 className="p-3">{pageTitle}</h3>
              <div className="p-3">
                <Row>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="12">
                          <Form.Group controlId="vehicleNumber">
                            <Form.Label>Vehicle Number</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="vehicleNumber"
                              value={this.state.vehicleNumber}
                              onChange={this.handleChange}
                              placeholder="Vehicle Number"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="submit-button-container">
                        <Form.Control
                          required
                          type="hidden"
                          name="id"
                          value={this.state.id}
                        />
                        <Button pill className="submit-button">
                          <i className="material-icons"></i>Submit
                        </Button>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </LoadingOverlay>
          <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
          .submit-button-container {
            margin-top: 20px;
            text-align: center;
          }
          .submit-button {
            min-width: 150px;
            padding: 10px 20px;
            font-size: 16px;
          }
          ._loading_overlay_overlay {
            border-radius: .625rem;
          }
        `}</style>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location2: state.auth.location,
  url: state.auth.url
});
export default connect(mapStateToProps)(AddEditVehicle);
