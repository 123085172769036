import React, { useState, useEffect } from "react";
import { Card, CardBody } from "shards-react";
import ViewMenu from "./ViewMenu";
import ItemWidgets from "./itemWidgets";
import AxiosPost from "../common/AxiosPost";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";
import { useHistory } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

function SelectedMaterial({
  material,
  selectMaterial,
  info,
  isAnotherSupervisor
}) {
  const [counts, setCounts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showMenu, toggleMenu] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (!(info.counts && info.counts[material.invoiceUri])) {
      let a = [];
      material.storageItems.forEach(i => a.push(0));
      setCounts(a);
    } else setCounts(info.counts[material.invoiceUri]);
  }, [1]);

  const handleCount = (index, countType) => {
    console.log("handle count called");
    let a = [...counts];
    if (countType === "+") a[index] += 1;
    else {
      if (a[index] - 1 < 0) {
        PNotify.notice("Value Can't be Negative.");
        return;
      }
      a[index] -= 1;
    }
    setCounts(a);
  };

  function handleSave() {
    console.log("saved");
    setLoader(true);
    let item = { ...info };
    item._id = item._id.$oid;
    if (!item.counts) item.counts = {};
    item.counts[material.invoiceUri] = counts;
    console.log(info, material);
    console.log(item);
    AxiosPost(
      "/update_docking_queue_entry",
      item,
      res => {
        console.log(res);
        setLoader(false);
        if (res.data.Status === " Okay! ") {
          setLoader(false);
          history.push("/dashboard");
          PNotify.success("Saved");
        } else PNotify.error("Something Went Wrong");
      },
      e => {
        PNotify.error("Something Went Wrong");
        setLoader(false);
        console.log(e);
      }
    );
  }
  return (
    <div
      style={{
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      onClick={() => {
        if (showMenu) toggleMenu(false);
      }}
    >
      <Card small className="m-3 m-md-4" style={{ width: 500 }}>
        <LoadingOverlay active={loader} spinner text="Please wait...">
          <CardBody className="p-md-5 p-4">
            <button
              className="btn btn-outline-dark mb-3 btn-sm"
              onClick={() => selectMaterial()}
            >
              Go Back
            </button>
            {info.vehicleNumber && (
              <h6>
                Vehicle No:{" "}
                <b className="font-weight-bold">{info.vehicleNumber}</b>
              </h6>
            )}
            <div className="position-relative " style={{ textAlign: "end" }}>
              <Icon onClick={() => toggleMenu(!showMenu)}>more_vert</Icon>
              {showMenu && <ViewMenu info={info} />}
            </div>
            {material.storageItems.map((item, key) => (
              <ItemWidgets
                item={item}
                key={key}
                index={key}
                count={counts[key]}
                handleCount={handleCount}
                isAnotherSupervisor={isAnotherSupervisor}
              />
            ))}
            {!isAnotherSupervisor && (
              <div>
                <button
                  className="btn btn-accent float-right mb-4"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            )}
          </CardBody>
        </LoadingOverlay>
      </Card>
    </div>
  );
}

export default SelectedMaterial;
