import { FETCH_POSTS, NEW_POST, DASHBOARD_DATA, LOCATION } from "./types";
//import axios from 'axios';

export const fetchPosts = () => dispatch => {
  fetch("https://jsonplaceholder.typicode.com/posts")
    .then(res => res.json())
    .then(posts =>
      dispatch({
        type: FETCH_POSTS,
        payload: posts
      })
    );
};

export const createPost = postData => dispatch => {
  fetch("https://jsonplaceholder.typicode.com/posts", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(postData)
  })
    .then(res => res.json())
    .then(post =>
      dispatch({
        type: NEW_POST,
        payload: post
      })
    );
};

export const dashboard_data = data => dispatch => {
  // console.log('dispatching my dashboard action')
  dispatch({
    type: DASHBOARD_DATA,
    payload: data
  });
};

export const handle_location = data => dispatch => {
  // console.log('location set as: ',data)
  dispatch({
    type: LOCATION,
    payload: data
  });
};
