import React from "react";
import { Row, Form, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  ListGroupItem,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import "../../assets/range-date-picker.css";
import "../../assets/range-date-picker.css";
import Moment from "moment";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import LoadingOverlay from "react-loading-overlay";
import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import MessageCard from "../common/MessageCard";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import "./addEditPersonStyle.css";
import {
  //BrowserView,
  MobileView
  // isBrowser,
  //isMobile
} from "react-device-detect";

import PNotify from "pnotify/dist/es/PNotify";
import AxiosPost from "../common/AxiosPost";
import PersonForm from "./PersonForm";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;

class AddEditPerson extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      heading: "Add/Edit Person", //determine heading of page
      _id: undefined, //Unique Identification
      mobileNumber: "", //Mobile Number of the person without country code
      otpEntered: "", //OTP Entered for submission of the information - What is this used for ?
      numerr: false, //What does this check?
      otperr: false, //What does this check?
      otpverified: false, //What is being verified here?
      specialEntry: false, //person is entering through special entry or not
      seRemarks: "", //remarks(reason ) for applying special entry
      sePurpose: "", //Purpose of person applying special entry
      seThirdPerson: "", //admin or other that has give special pass otp

      personInData: undefined, //What is this?
      name: "", //Name of the Person
      fathersName: "", //Father's Name of the Person
      gender: "", //Gender of the Person
      id: "", //What is this Id? (We already have an Id at te top as well) ??
      idType: "", //idType - What is this ?
      homeAddress: "", //Home Address of the Person
      pincode: "",
      city: "",
      state: "",
      currentAccessLocation: this.props.location, //Is this the GPS location?
      currentAccessDate: Moment().format(), //Is this the present date?
      currentAccessTime: Moment().format("LT"), //Is this the present time?
      //is18above: true, //Default setting of true being given stating that the person is above 18 (This can be removed)
      dOB: "", //Systems calculated the age and then does the Is18above automatically.
      dobErr: undefined, //Error if dob is below 18
      haveVehicle: "", //check have a vehcile or not
      haveMaterial: "", //check material or not
      visitorCompanyIdCard: "", //This has to be a photo of the company Id Card - so a url of the place where the image is getting stored can come here.
      visitorBusinessCard: "", //Same here
      roleId: "", //Access Id for the System
      authorisationId: "", //Difference between authorisation Id and Role Id.
      presentCompany: "", //The place of current work.  - Only company Id should come here. - Company name should be stored in Company Master
      dailyWageRate: "", //This is for the daily wage labourer.
      reportingManagerName: "", //Reporting Manager of the Person - in case of employee, labourer, person - The previous state of the person needs to be stored somewhere, to maintain a history of the previous nature of visit
      esicNumber: "", //ESIC Number of a person
      labauthorisationId: "", //Person Id of the person authorising the labour visit - This is per visit.
      companyName: "", //in case of supplier
      whomToMeetorReport: "", //This is per visit - Per visit entries need to go in Person In Out Register Table and Not in Person Table
      whomToReport: "", //This is per visit
      Customer_Name: "", //Customer Name under Driver details
      drivingLicense: "", //Driving license of the person visiting the facility - Specially Truck Drivers
      driverPresentCompany: "", //Transportner Name
      isDriverSober: "", //Is the Driver Sober?
      approvalDateAndTime: "", //This is auto generated after the person obtains the approval through OTP - which was sent to the person the person wants to meet. - Again this is per visit
      supervisor_phone_no: "", //Why is this required? - Who do we mean by the supervisor?
      EmployeeCode: "", //Employee Code for Permanent Employees - Given to Interns - Given to Contracted Employees also.
      personType: "", //Type of Visitor - The type of visitor can change from visit to visit.
      personTypenum: "0", //Type of Visitor Number - Will also change except for employees or interns or contracted employees - after their final exit this will be set to default.
      thirdPersonAuthList: [
        "Customer",
        "Labour",
        "Visitor",
        "Supplier",
        "Intern",
        "Truck Driver",
        "Contracted Employee"
      ],
      showEnterAsForm: false, //if ptype ===employee or intern then ask permission before submit
      TakeImageInfo: {
        showUploadModal: false,
        facingMode: true,
        datauri: ""
      }, //for camera

      managers: undefined,
      customerList: undefined,
      employeeList: undefined,
      adminList: undefined, //for special entry purpose
      reportingManager: {},
      customer: {},
      submitmsg: false,
      formErr: false,
      showspinner: false,
      showotpbtn: false,
      otpAfterSubmit: false
    };
    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //data coming from docking queue and addeditVehcile
    if (this.props.history.location.state) {
      let temp = this.props.history.location.state;
      //console.log(temp);
      if (temp.OTPtype === "FirstPerson") {
        this.setState({
          showspinner: false,
          otpEntered: "",
          showotpbtn: true,
          mobileNumber: temp.mobileNumber
        });
      } else if (temp.OTPtype === "ThirdPerson") {
        this.setState({
          otpAfterSubmit: true,
          otpEntered: "",
          mobileNumber: temp.mobileNumber,
          heading: "Third Person Authentication"
        });
      }
      //special person case
      else {
        this.setState({
          showspinner: false,
          otpEntered: "",
          showotpbtn: true,
          heading: "Special Entry OTP",
          mobileNumber: temp.mobileNumber,
          seThirdPerson: temp.seThirdPerson,
          sePurpose: temp.sePurpose,
          seRemarks: temp.seRemarks
        });
      }
    }

    this.fetchLocations();
    this.fetchCustomers();
    this.fetchEmployees();
  }

  fetchLocations = () => {
    AxiosPost("/get_locations", {}, res => {
      let loc = res.data.find(ele => ele.locName === this.props.location);
      //console.log("reporting managers:", loc.reportingManagers);
      this.setState({ managers: loc.reportingManagers });
    });
  };

  fetchCustomers = () => {
    AxiosPost("/get_customers", {}, res => {
      let customerList = res.data.map(cust => ({
        username: cust.custName,
        mobileNumber: cust.custLandline
      }));
      //console.log("customer list", customerList);
      this.setState({ customerList });
    });
  };

  fetchEmployees = () => {
    AxiosPost(
      "/employee_table",
      {
        start: 0,
        length: 1000000
      },
      res => {
        //console.log("Employee List", res);
        let employeeList = res.data.map(item => {
          item.locations.includes(this.props.location);
          return {
            username: item.username,
            mobileNumber: item.mobileNumber
          };
        });
        //console.log(employeeList);
        this.setState({ employeeList });
      }
    );
  };
  fetchAdmins = () => {
    this.setState({ showspinner: true });
    AxiosPost(
      "/admin_table",
      {
        start: 0,
        length: 1000000
      },
      res => {
        //console.log("Admin List", res);
        let adminList = res.data.map(i => ({
          username: i.username,
          mobileNumber: i.mobileNumber
        }));
        //console.log(employeeList);
        this.setState({ adminList, showspinner: false });
      }
    );
  };

  closeModal = () => {
    this.setState({ formErr: false });
  };
  handleAlert = (type, msg) => {
    PNotify.alert({
      type: type,
      text: msg
    });
  };
  //We are verifying the number here by sending an OTP to the number.
  verifynumber = e => {
    e.preventDefault();
    this.setState({ submitmsg: false, showspinner: true, showotpbtn: false });

    if (this.state.mobileNumber.toString().length === 10) {
      // this.setState({
      //   numerr: false,
      //   otperr: false
      // });
      ////console.log(this.state.mobileNumber.toString().length);
      ////console.log("Number submitted is:", this.state.mobileNumber);
      const data = {
        mobileNumber: this.state.mobileNumber,
        auth_status: "phone_entered"
      };

      AxiosPost(
        "/checkphone",
        data,
        res => {
          //console.log(res.data);

          // //console.log(res.data);
          if (res.data.Status !== " Okay! ") {
            this.setState({
              showspinner: false,
              mobileNumber: ""
            });
            this.handleAlert("error", res.data.Status);
            return;
          }
          // this.setState({otpEntered:res.data.match(/\d+/g)[0]})
          // this.verifyOtp();
          this.setState({ showspinner: false, showotpbtn: true });
        },
        () => {
          this.setState({ showspinner: false });
          this.handleAlert("error", "Something went Wrong.Try Again !!");
        }
      );
    } else {
      this.setState({
        showspinner: false,
        mobileNumber: ""
      });
      this.handleAlert("error", "Number should contain 10 digits only");
    }
  };

  verifyOtp = e => {
    this.setState({ submitmsg: false, showspinner: true });
    e.preventDefault();
    //console.log("otp entered:", this.state.otpEntered);

    const data = {
      mobileNumber: this.state.mobileNumber,
      personotp: this.state.otpEntered
    };
    let endpoint = "/postotp_addperson";
    //if seThirdPerson is defined that means user has opt for special entry
    if (this.state.seThirdPerson) endpoint = "/postotp_special_entry";
    AxiosPost(
      endpoint,
      data,
      res => {
        //console.log(res.data);
        // //console.log("length of res data", res.data.length);
        if (res.data) {
          if (typeof res.data === "string" || res.data instanceof String)
            this.handleAlert("error", res.data);
          else if (res.data.length > 0) {
            let data = res.data[0];
            if (
              ["Employee", "Intern", "Contracted Employee"].includes(
                data.personType
              )
            ) {
              this.setState({
                showEnterAsForm: true,
                personType: data.personType,
                personTypenum: data.personTypenum,
                haveVehicle: "no",
                haveMaterial: "no"
              });
              if (data.personType === "Intern")
                this.setState({
                  whomToMeetorReport: data.reportingManager.username,
                  EmployeeCode: data.EmployeeCode
                });
              if (data.personType === "Contracted Employee")
                this.setState({
                  reportingManager: data.reportingManager,
                  reportingManagerName: data.reportingManagerName
                });
            }
            this.setState({
              personInData: res.data[0],
              //personType: res.data[0].personType,
              // otperr: false,
              name: data.name,
              fathersName: data.fathersName,
              dOB: data.dOB,
              gender: data.gender,
              id: data.id,
              idType: data.idType,
              homeAddress: data.homeAddress,
              pincode: data.pincode,
              city: data.city,
              state: data.state,
              otpverified: true,
              heading: "Add/Edit Person"
            });
          } else
            this.setState({
              personInData: {},
              // otperr: false,
              heading: "Add/Edit Person",
              otpverified: true
            });

          if (!this.state.managers) this.fetchLocations();
          if (!this.state.customerList) this.fetchCustomers();
          if (!this.state.employeeList) this.fetchEmployees();
        }

        if (res.status !== 200)
          this.handleAlert("error", "Something went Wrong.Try Again !");
        this.setState({ showspinner: false, otpEntered: "" });
      },
      () => {
        this.handleAlert("error", "Something went Wrong.Try Again !");
        this.setState({
          //otperr: true,
          // otpEntered: "",
          showspinner: false
        });
      }
    );
  };

  handleSpecialEntry = e => {
    e.preventDefault();
    const {
      name,
      mobileNumber,
      seThirdPerson,
      sePurpose,
      seRemarks,
      adminList
    } = this.state;
    if (mobileNumber.toString().length !== 10) {
      PNotify.error("Number Should Contain 10 Digits.");
      return;
    } else if (!(name && mobileNumber && seThirdPerson && sePurpose)) {
      PNotify.error("Please fill all required fields");
      return;
    }
    let third_person = adminList.find(i => i.username === seThirdPerson);
    if (!third_person) {
      PNotify.error("Third Person Not Found");
      return;
    }
    this.setState({ showspinner: true });
    let data = {
      name,
      mobileNumber,
      sePurpose,
      seRemarks,
      third_person,
      auth_status: "phone_entered"
    };
    // console.log(data);
    AxiosPost(
      "/special_entry",
      data,
      res => {
        //console.log(res);
        if (res.data.Status !== " Okay! ") {
          this.setState({
            showspinner: false,
            mobileNumber: ""
          });
          this.handleAlert("error", res.data.Status);
          return;
        }
        this.setState({
          showspinner: false,
          showotpbtn: true,
          specialEntry: false,
          heading: "Special Entry OTP"
        });
        PNotify.info("OTP Sent to " + seThirdPerson);
      },
      err => {
        console.log(err);
        this.setState({
          showspinner: false,
          mobileNumber: ""
        });
        this.handleAlert("error", "Something Went Wrong");
      }
    );
  };
  handlegender = e => {
    this.setState({
      gender: e.target.getAttribute("value")
    });
  };

  handleDate = date => {
    // handle date picker method
    //console.log("We are in the handle date function");
    //console.log(date.toDateString());
    this.setState({
      dOB: date
    });
  };

  handleDateAndTime = dateandTime => {
    // handle date&time picker method
    //console.log("We are in the date and Time function");
    this.setState({
      approvalDateAndTime: dateandTime
    });
  };

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name, value);
    if (name === "dOB") {
      let diff = parseInt(
        new Date().getFullYear() - (value[0] + value[1] + value[2] + value[3])
      );
      // //console.log(diff);
      if (diff < 0) {
        this.setState({
          dobErr: "Please Put Valid Dob"
        });
      } else if (diff < 18) {
        this.setState({
          dobErr: "You are below 18"
        });
      } else
        this.setState({
          dobErr: undefined
        });
    }
    this.setState({
      [name]: value
    });
  }

  updateState = event => {
    //if (this.state.personInData && this.state.personInData.personType) return;

    const val = event.target.value;
    this.setState({ personTypenum: val });

    if (val === "1") {
      this.setState({
        personType: "Customer"
      });
    } else if (val === "2") {
      this.setState({
        personType: "Labour"
      });
    } else if (val === "3") {
      this.setState({
        personType: "Contracted Employee"
      });
    } else if (val === "4") {
      this.setState({
        personType: "Truck Driver"
      });
    } else if (val === "5") {
      this.setState({
        personType: "Visitor"
      });
    } else if (val === "6") {
      this.setState({
        personType: "Intern"
      });
    } else if (val === "7") {
      this.setState({
        personType: "Supplier"
      });
    } else if (val === "8") {
      this.setState({
        personType: "Employee"
      });
    }
  };

  handleSubmit(event) {
    this.setState({ showspinner: true });
    let state = this.state;
    // //console.log(state.name);
    if (event) event.preventDefault();
    //console.log("We are in the submit function of addedit", this.props.url);
    if (this.state.idType === "aadharCard" && this.state.id.length < 12) {
      this.handleAlert("error", "AadharCard should contain atleast 12 digits");
      this.setState({ showspinner: false });
      return;
    }

    if (state.dobErr) {
      this.handleAlert("error", "DOB is invalid");
      this.setState({ showspinner: false });
      return;
    }
    if (
      !(
        state.name &&
        state.fathersName &&
        state.dOB &&
        state.gender &&
        state.id &&
        state.city &&
        state.state &&
        state.personType &&
        state.haveVehicle
      ) ||
      (state.haveVehicle === "no" && !state.haveMaterial)
    ) {
      //console.error("Form 1 not filled properly");
      this.setState({ formErr: "Upper form not filled properly" });
      this.setState({ showspinner: false });
      return;
    }
    if (state.personType === "Customer") {
      if (!state.presentCompany) {
        this.setState({ formErr: "Customer not filled properly" });
        //console.log("Customer form not fully");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Labour") {
      if (!(state.dailyWageRate && state.reportingManagerName)) {
        this.setState({ formErr: "Labour form  not filled properly" });
        //console.log("Labourer form not filled full");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Contracted Employee") {
      if (!state.reportingManagerName) {
        this.setState({ formErr: "Contracted form not filled properly" });
        //console.error("contracted employee for not fill properly");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Truck Driver") {
      if (
        !(
          state.Customer_Name &&
          state.drivingLicense &&
          state.driverPresentCompany &&
          state.isDriverSober
        )
      ) {
        this.setState({ formErr: "Truck Driver form not filled properly" });
        //console.error("Truck driver form not filled succesfully");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Visitor") {
      if (!state.whomToMeetorReport) {
        this.setState({ formErr: "Visitor form not filled properly" });
        //console.error("Visitor Form not filled properly");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Intern") {
      if (!(state.whomToMeetorReport && state.EmployeeCode)) {
        this.setState({ formErr: "Intern form not filled properly" });
        //console.error("Intern form not filled full");
        this.setState({ showspinner: false });
        return;
      }
    } else if (state.personType === "Supplier") {
      if (!(state.whomToMeetorReport && state.companyName)) {
        this.setState({ formErr: "Supplier form not filled properly" });
        //console.error("Supplier form not filled full");
        this.setState({ showspinner: false });
        return;
      }
    }

    let data = {
      personID: this.state.id,
      mobileNumber: this.state.mobileNumber,
      // aadharIDURI: "44984397438329",
      // pancardIDURI: "32974974387487",
      driverLicenseIDURI: this.state.drivingLicense,
      name: this.state.name,
      gender: this.state.gender,
      fathersName: this.state.fathersName,
      homeAddress: this.state.homeAddress,
      pincode: this.state.pincode,
      city: this.state.city,
      state: this.state.state,
      dOB: this.state.dOB,
      idType: this.state.idType,
      id: this.state.id,
      //is18above: this.state.is18above,
      personType: this.state.personType,
      personTypenum: this.state.personTypenum,
      currentAccessDate: this.state.currentAccessDate,
      currentAccessTime: this.state.currentAccessTime,
      location: this.props.location
    };
    if (state.seThirdPerson) {
      let appenddata = {
        seThirdPerson: state.seThirdPerson,
        seRemarks: state.seRemarks,
        sePurpose: state.sePurpose
      };
      data = { ...data, ...appenddata };
    }
    if (this.state.TakeImageInfo.datauri)
      data = { ...data, idImage: this.state.TakeImageInfo.datauri };

    let appenddata;
    if (this.state.personType === "Customer") {
      const { presentCompany, customerList } = this.state;
      if (presentCompany && customerList)
        appenddata = {
          presentCompany: presentCompany,
          customer: customerList.find(ele => ele.username === presentCompany)
        };
      if (!appenddata.customer) {
        this.handleAlert("error", "Customer does not exist");
        this.setState({ showspinner: false });
        return;
      }

      // appenddata = {
      //   visitorCompanyIdCard: this.state.visitorCompanyIdCard,
      //   visitorBusinessCard: this.state.visitorBusinessCard,
      //   roleId: this.state.roleId,
      //   authorisationId: this.state.authorisationId,
      //   presentCompany: this.state.presentCompany
      // };
    } else if (this.state.personType === "Labour") {
      const { reportingManagerName, managers } = this.state;
      if (reportingManagerName && managers)
        appenddata = {
          reportingManagerName: reportingManagerName,
          reportingManager: managers.find(
            ele => ele.empName === reportingManagerName
          ),

          dailyWageRate: this.state.dailyWageRate,
          esicNumber: this.state.esicNumber,
          labauthorisationId: this.state.labauthorisationId,
          supervisor_phone_no: this.state.supervisor_phone_no
        };

      if (!appenddata.reportingManager) {
        this.handleAlert("error", "Reporting Manager does not exist");
        this.setState({ showspinner: false });
        return;
      }
    } else if (this.state.personType === "Contracted Employee") {
      //appenddata = { EmployeeCode: this.state.EmployeeCode };
      const { reportingManagerName, managers } = this.state;
      if (reportingManagerName && managers)
        appenddata = {
          reportingManagerName: reportingManagerName,
          reportingManager: managers.find(
            ele => ele.empName === reportingManagerName
          )
        };
      if (!appenddata.reportingManager) {
        this.handleAlert("error", "Reporting Manager does not exist");
        this.setState({ showspinner: false });
        return;
      }
    } else if (this.state.personType === "Truck Driver") {
      appenddata = {
        customer: this.state.customerList.find(
          ele => ele.username === this.state.Customer_Name
        ),
        drivingLicense: this.state.drivingLicense,
        driverPresentCompany: this.state.driverPresentCompany,
        isDriverSober: this.state.isDriverSober
      };
      if (!appenddata.customer) {
        this.handleAlert("error", "Customer does not exist");
        this.setState({ showspinner: false });
        return;
      }
    } else if (this.state.personType === "Intern") {
      let temp = this.state.employeeList.find(
        ele => ele.username === this.state.whomToMeetorReport
      );
      appenddata = {
        reportingManager: temp,
        EmployeeCode: this.state.EmployeeCode
      };
      if (!appenddata.reportingManager) {
        this.handleAlert("error", "Reporting Manager does not exist");
        this.setState({ showspinner: false });
        return;
      }
    } else if (["Visitor", "Supplier"].includes(this.state.personType)) {
      let temp = this.state.employeeList.find(
        ele => ele.username === this.state.whomToMeetorReport
      );
      //console.log(temp);
      appenddata = {
        whomToMeet: temp,
        companyName: state.companyName,
        approvalDateAndTime: this.state.approvalDateAndTime
      };
      if (!appenddata.whomToMeet) {
        this.handleAlert("error", "Reporting Manager does not exist");
        this.setState({ showspinner: false });
        return;
      }
    }

    data = { ...data, ...appenddata };
    data = { ...data, inorout: "in" };
    let url;
    //if special entry otp is passed then no need to do third person auth
    if (
      this.state.thirdPersonAuthList.includes(this.state.personType) &&
      !state.seThirdPerson
    ) {
      url = `/third_person_auth`;
      data = { ...data, tp_auth_status: "tp_phone_entered" };
    } else url = `/addperson`;
    //console.log(url);
    //console.log(state);
    AxiosPost(
      url,
      data,
      res => {
        //console.log("submit data:", res);
        //console.log(res.data.otp);

        //in case of person type ===Employee/Intern
        if (this.state.showEnterAsForm)
          this.setState({ showEnterAsForm: false });

        if (typeof res.data === "string" && res.data !== "Done") {
          this.handleAlert("error", "Something went Wrong");
          this.setState({
            showspinner: false,
            otpEntered: ""
          });
          return;
        } else if (
          typeof res.data === "object" &&
          res.data.constructor === Object &&
          !res.data.auth_status
        ) {
          if (res.data.Error) this.handleAlert("error", res.data.Error);
          else if (res.data.Status) this.handleAlert("error", res.data.Status);
          else this.handleAlert("error", "Something Went Wrong");
          this.setState({
            showspinner: false,
            otpEntered: ""
          });
          return;
        }

        this.setState({
          personInData: undefined,
          showspinner: false,
          otpEntered: ""
        });
        if (
          this.state.thirdPersonAuthList.includes(this.state.personType) &&
          !state.seThirdPerson
        )
          this.setState({
            otpAfterSubmit: true,
            heading: "Third Person Authentication"
          });
        else {
          this.setState({
            otpAfterSubmit: false,
            submitmsg: true
          });
          //then redirect to vhicle in or material in with details
          if (this.state.haveVehicle === "yes") {
            PNotify.info("Successfully Submit");
            this.props.history.push({
              pathname: "/AddEditVehicle",
              mobileNumber: this.state.mobileNumber,
              name: this.state.name,
              isMaterialOut:
                this.props.history.location.state &&
                this.props.history.location.state.isMaterialOut
              //personID: this.state.id,
              //idType: this.state.idType,
              //personType: this.state.personType
            });
          } else if (this.state.haveMaterial === "yes") {
            PNotify.info("Successfully Submit");
            if (
              this.props.history.location.state &&
              this.props.history.location.state.isMaterialOut
            )
              this.props.history.push({
                pathname: "/Material_Out",
                mobileNumber: this.state.mobileNumber,
                name: this.state.name
              });
            else
              this.props.history.push({
                pathname: "/AddEditMaterial",
                mobileNumber: this.state.mobileNumber,
                name: this.state.name
              });
          }
        }
      },
      e => {
        this.setState({ showspinner: false });
        this.handleAlert("error", "Something Went Wrong");
      }
    );
  }

  handleOtpAfterSubmit = e => {
    e.preventDefault();
    this.setState({ showspinner: true, otperr: false });
    ////console.log("inside otp after submit ");
    AxiosPost(
      "/postotp_thirdpersonauth",
      {
        third_person_otp: this.state.otpEntered,
        mobileNumber: this.state.mobileNumber
      },
      res => {
        //console.log(res);
        //successfull sent
        if (res.data.auth === true) {
          this.setState({
            otpAfterSubmit: false,
            submitmsg: true
          });
          //redirecting to vehicle in or material in
          if (this.state.haveVehicle === "yes") {
            PNotify.info("Successfully Submit");
            this.props.history.push({
              pathname: "/AddEditVehicle",
              mobileNumber: this.state.mobileNumber,
              name: this.state.name,
              isMaterialOut:
                this.props.history.location.state &&
                this.props.history.location.state.isMaterialOut
              //personID: this.state.id,
              //idType: this.state.idType,
              //personType: this.state.personType
            });
          } else if (this.state.haveMaterial === "yes") {
            PNotify.info("Successfully Submit");
            if (
              this.props.history.location.state &&
              this.props.history.location.state.isMaterialOut
            )
              this.props.history.push({
                pathname: "/Material_Out",
                mobileNumber: this.state.mobileNumber,
                name: this.state.name
              });
            else
              this.props.history.push({
                pathname: "/AddEditMaterial",
                mobileNumber: this.state.mobileNumber,
                name: this.state.name
              });
          }
        } else {
          this.handleAlert("error", "Something went wrong.Try again !!");
        }
        this.setState({ showspinner: false });
      },
      () => {
        this.handleAlert("error", "Something went Wrong.Try Again !!");
        this.setState({ otperr: true, showspinner: false });
      }
    );
  };

  render() {
    const { TakeImageInfo } = this.state;

    const { personInData } = this.state;
    const read =
      personInData && Object.keys(personInData).length > 0 ? true : false;
    //se-special fields
    const seFields = [
      {
        title: "Name",
        name: "name",
        required: true
      },
      {
        title: "Mobile Number",
        name: "mobileNumber",
        required: true,
        type: "number"
      },
      {
        title: "Purpose",
        name: "sePurpose",
        required: true
      },
      {
        title: "Remarks",
        name: "seRemarks",
        required: false
      }
    ];
    const managersDatalist = (
      <datalist id="managers">
        {this.state.managers &&
          this.state.managers.map((manager, key) => (
            <option key={key} value={manager.empName}>
              {manager.empName}
            </option>
          ))}
      </datalist>
    );

    const customerDatalist = (
      <datalist id="customers">
        {this.state.customerList &&
          this.state.customerList.map((customer, key) => (
            <option key={key} value={customer.username} />
          ))}
      </datalist>
    );

    const EmployeeDatalist = (
      <datalist id="employee">
        {this.state.employeeList &&
          this.state.employeeList.map((employee, key) => (
            <option key={key} value={employee.username} />
          ))}
      </datalist>
    );

    const AdminDatalist = (
      <datalist id="admin">
        {this.state.adminList &&
          this.state.adminList.map((i, key) => (
            <option key={key} value={i.username} />
          ))}
      </datalist>
    );
    if (this.state.showEnterAsForm) {
      return (
        <>
          <div
            style={{
              minHeight: "calc(100vh - 120px)",
              display:
                this.state.otpverified && !this.state.otpAfterSubmit
                  ? "block"
                  : "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <LoadingOverlay
              active={this.state.showspinner}
              spinner
              text="Processing..."
            >
              <Card
                small
                className="m-3 m-md-4"
                style={{
                  width:
                    this.state.otpverified && !this.state.otpAfterSubmit
                      ? "auto"
                      : 400,
                  height: "30vh"
                }}
              >
                <CardBody>
                  <h5 className="p-3">{this.state.heading}</h5>

                  <Row className="mt-4">
                    <Col className="d-flex justify-content-center">
                      <button
                        className="btn btn-outline-info d-block mx-2"
                        onClick={() => {
                          //removing intern and CE for third person auth list
                          this.setState(
                            {
                              thirdPersonAuthList: [
                                "Customer",
                                "Labour",
                                "Visitor",
                                "Supplier",
                                "Truck Driver"
                              ]
                            },
                            this.handleSubmit
                          );
                        }}
                      >
                        Enter as
                        {this.state.personType === "Employee" && " Employee"}
                        {this.state.personType === "Intern" && " Intern"}
                        {this.state.personType === "Contracted Employee" &&
                          " Contracted Employee"}
                      </button>
                      <button
                        className="btn btn-outline-info d-block"
                        onClick={() =>
                          this.setState({
                            whomToMeetorReport: "",
                            EmployeeCode: "",
                            personTypenum: "0",
                            personType: "",
                            showEnterAsForm: false,
                            haveVehicle: "",
                            haveMaterial: ""
                          })
                        }
                      >
                        Enter as Other
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </LoadingOverlay>
          </div>
        </>
      );
    } else if (this.state.submitmsg)
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <div style={{ margin: "0 auto", maxWidth: 680 }}>
            <MessageCard title="Success" subtitle="Entry added." />
          </div>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 40,
              marginBottom: 10
            }}
          >
            <Col md="3" className="mb-4">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
            <Col md="3">
              <Link
                to={{
                  pathname: "/AddEditVehicle",
                  mobileNumber: this.state.mobileNumber,
                  name: this.state.name
                  //personID: this.state.id,
                  //idType: this.state.idType,
                  //personType: this.state.personType
                }}
                style={{ textDecoration: "none" }}
              >
                <SGActionsCard
                  type="vehicle"
                  title="Add Vehicle Entry"
                  hideFooter
                />
              </Link>
            </Col>
          </Row>
        </div>
      );

    return (
      <div
        style={{
          minHeight: "calc(100vh - 120px)",
          display:
            this.state.otpverified && !this.state.otpAfterSubmit
              ? "block"
              : "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card
          small
          className="m-3 m-md-4"
          style={{
            width:
              this.state.otpverified && !this.state.otpAfterSubmit
                ? "auto"
                : 400
          }}
        >
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text="Processing..."
          >
            <CardBody>
              <h4 className="p-3">{this.state.heading}</h4>
              <Row>
                <Col sm={12}>
                  {/* <Form
                //  onSubmit={this.handleSubmit}
                 > */}
                  {(!this.state.otpverified ||
                    this.state.otpAfterSubmit ||
                    this.state.specialEntry) && (
                    <div className="p-3">
                      {!this.state.showotpbtn &&
                        !this.state.otpAfterSubmit &&
                        !this.state.specialEntry && (
                          <Form onSubmit={this.verifynumber}>
                            <Row>
                              <Col md="12" className="form-group">
                                <label htmlFor="mobileNumber">
                                  Mobile Number
                                </label>
                                <FormInput
                                  required={true}
                                  placeholder="Mobile Number"
                                  type="number"
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  value={this.state.mobileNumber}
                                  onChange={this.handleChange}
                                />
                                {this.state.numerr ? (
                                  <div className="d-block text-danger mt-3">
                                    {this.state.numerr}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col
                                md="12"
                                className="form-group submit-button-container"
                              >
                                <Button
                                  pill
                                  className="submit-button"
                                  onClick={this.verifynumber}
                                >
                                  <i className="material-icons"></i>Verify
                                </Button>
                              </Col>
                              <Col md="12" className="form-group text-center">
                                <Button
                                  type="button"
                                  pill
                                  className="submit-button btn-secondary"
                                  onClick={() => {
                                    this.setState({ specialEntry: true });
                                    if (!this.state.adminList)
                                      this.fetchAdmins();
                                  }}
                                >
                                  Special Entry
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}

                      {!this.state.showotpbtn &&
                        !this.state.otpAfterSubmit &&
                        this.state.specialEntry && (
                          <Form onSubmit={this.handleSpecialEntry}>
                            {seFields.map(i => (
                              <Col md="12" className="form-group" key={i.name}>
                                <Form.Label
                                  className={i.required ? "required" : ""}
                                >
                                  {i.title}
                                </Form.Label>
                                <FormInput
                                  required={i.required}
                                  placeholder={"Enter " + i.title}
                                  type={i.type ? i.type : "text"}
                                  name={i.name}
                                  value={this.state[i.name]}
                                  onChange={this.handleChange}
                                />
                              </Col>
                            ))}
                            <Col md="12">
                              <Form.Group>
                                <Form.Label className="required">
                                  Whom To Meet
                                </Form.Label>
                                <Form.Control
                                  required={true}
                                  type="text"
                                  name="seThirdPerson"
                                  value={this.state.seThirdPerson}
                                  onChange={this.handleChange}
                                  placeholder="Enter the person name"
                                  list={
                                    this.state.seThirdPerson.length > 0
                                      ? "admin"
                                      : undefined
                                  }
                                  autoComplete={"off"}
                                />
                                {AdminDatalist}
                              </Form.Group>
                            </Col>
                            <Col
                              md="12"
                              className="form-group submit-button-container"
                            >
                              <Button
                                type="submit"
                                pill
                                className="submit-button"
                                onClick={this.handleSpecialEntry}
                              >
                                Proceed
                              </Button>
                            </Col>
                            <Col
                              md="12"
                              className="form-group submit-button-container"
                            >
                              <Button
                                type="button"
                                pill
                                className="submit-button btn-danger"
                                onClick={() => {
                                  this.setState({
                                    specialEntry: false,
                                    seThirdPerson: "",
                                    mobileNumber: ""
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Form>
                        )}

                      {(this.state.showotpbtn || this.state.otpAfterSubmit) && (
                        <Form
                          onSubmit={
                            this.state.otpAfterSubmit
                              ? this.handleOtpAfterSubmit
                              : this.verifyOtp
                          }
                        >
                          <Row>
                            <Col md="12" className="form-group">
                              <label htmlFor="otpEntered">
                                Enter OTP received
                              </label>
                              <OtpInput
                                value={this.state.otpEntered}
                                onChange={otpEntered =>
                                  this.setState({ otpEntered })
                                }
                                numInputs={6}
                                //otpContainerStyle class is in Common.css
                                containerStyle="otpContainerStyle"
                                inputStyle={{
                                  width: 40,
                                  height: 55,
                                  background: "#e8f5f5",

                                  borderRadius: 6
                                }}
                                focusStyle={{ outline: "none" }}
                              />
                              {this.state.otperr ? (
                                <div className="d-block text-danger">
                                  {this.state.otperr}
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col
                              md="12"
                              className="form-group submit-button-container  mt-0"
                            >
                              <Button
                                type="submit"
                                pill
                                className="submit-button"
                                // onClick={this.verifyOtp}
                              >
                                <i className="material-icons"></i>Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </div>
                  )}
                  {personInData ? (
                    <React.Fragment>
                      <PersonForm
                        read={read}
                        customerDatalist={customerDatalist}
                        managersDatalist={managersDatalist}
                        EmployeeDatalist={EmployeeDatalist}
                        handleChange={this.handleChange}
                        updateState={this.updateState}
                        state={this.state}
                        handleCamera={() => {
                          this.setState(pre => ({
                            TakeImageInfo: {
                              ...pre.TakeImageInfo,
                              showUploadModal: true
                            }
                          }));
                        }}
                      />
                      <Modal
                        show={TakeImageInfo.showUploadModal}
                        onHide={() =>
                          this.setState(pre => ({
                            TakeImageInfo: {
                              ...pre.TakeImageInfo,
                              showUploadModal: false
                            }
                          }))
                        }
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Take ID CARD Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {TakeImageInfo.datauri ? (
                            <div>
                              <img
                                src={TakeImageInfo.datauri}
                                className="w-100 d-block"
                                alt="ID Card"
                              />
                              <div>
                                <Button
                                  outline
                                  pill
                                  theme="dark"
                                  className="float-right mt-2"
                                  onClick={() => {
                                    this.setState(pre => ({
                                      TakeImageInfo: {
                                        ...pre.TakeImageInfo,
                                        datauri: ""
                                      }
                                    }));
                                  }}
                                >
                                  Retry
                                </Button>{" "}
                              </div>
                            </div>
                          ) : (
                            <>
                              <Camera
                                onTakePhoto={dataUri => {
                                  this.setState(pre => ({
                                    TakeImageInfo: {
                                      ...pre.TakeImageInfo,
                                      datauri: dataUri
                                    }
                                  }));
                                  //console.log(dataUri);
                                }}
                                onCameraError={err => {
                                  //console.log("camera error");
                                  this.handleAlert(
                                    "error",
                                    "Unable to Access Camera.Try again!"
                                  );
                                  this.setState(pre => ({
                                    TakeImageInfo: {
                                      ...pre.TakeImageInfo,
                                      showUploadModal: false
                                    }
                                  }));
                                }}
                                isImageMirror={false}
                                idealFacingMode={
                                  TakeImageInfo.facingMode ? "front" : "rear"
                                }
                                isDisplayStartCameraError={false}
                                //isFullscreen={true}
                              />
                              <MobileView>
                                <div>
                                  <Button
                                    outline
                                    pill
                                    theme="dark"
                                    className="mt-2 d-block mx-auto"
                                    onClick={() => {
                                      this.setState(pre => ({
                                        TakeImageInfo: {
                                          ...pre.TakeImageInfo,
                                          facingMode: !pre.TakeImageInfo
                                            .facingMode
                                        }
                                      }));
                                    }}
                                  >
                                    Switch Camera
                                  </Button>
                                </div>
                              </MobileView>
                            </>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() =>
                              this.setState(pre => ({
                                TakeImageInfo: {
                                  ...pre.TakeImageInfo,
                                  showUploadModal: false
                                }
                              }))
                            }
                          >
                            Close
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.setState(pre => ({
                                TakeImageInfo: {
                                  ...pre.TakeImageInfo,
                                  showUploadModal: false
                                }
                              }))
                            }
                          >
                            Save Changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        size="sm"
                        show={this.state.formErr}
                        onHide={this.closeModal}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.formErr}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={this.closeModal}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  {this.state.personInData && (
                    <Row>
                      <Col
                        md="12"
                        className="form-group submit-button-container"
                      >
                        <Form.Group>
                          {/* <Form.Control required={true} readOnly={read} type="hidden" name="id" value={personInData.personID||this.state.id} /> */}
                          <Button
                            pill
                            className="submit-button"
                            onClick={this.handleSubmit}
                          >
                            <i className="material-icons"></i>Submit
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </LoadingOverlay>
          <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
          .submit-button-container {
            margin-top: 20px;
            text-align: center;
          }
          .submit-button {
            min-width: 150px;
            padding: 10px 20px;
            font-size: 16px;
          }
          ._loading_overlay_overlay {
            border-radius: .625rem;
          }
        `}</style>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url
});
export default connect(mapStateToProps)(AddEditPerson);
