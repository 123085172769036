import React from "react";
//import { Redirect } from "react-router-dom";
// Layout Types
import { DefaultLayout } from "./layouts";
// Route dashboard
import Dashboard from "./dashboard/Dashboard";
//import Material_InOut from "./dashboard/Material_InOut";
import Errors from "./dashboard/Errors";
//import Person_inOut from "./dashboard/Person_inOut";
//import Vehicle_InOut from "./dashboard/Vehicle_InOut";
import Person_Out from "./components/dashboard_widgets/person_out";
import AddEditPerson from "./components/add_person/AddEditPerson";
import AddEditMaterial from "./components/add_material/AddEditMaterial";
import Material_Out from "./components/dashboard_widgets/material_out";
import AddEditVehicle from "./components/add_vehicle/AddEditVehicle";
import Vehicle_Out from "./components/dashboard_widgets/vehicle_out";
//import Employee_InOut from './dashboard/Employee_InOut';
//import Intern_InOut from './dashboard/Intern_InOut';
//import View_AllPerson from './components/add_person/View_person/table_view_person';
import View_Person from "./components/add_person/View_person/ViewPersonMethod";
import View_Material from "./components/add_material/View_material/ViewMaterialMethod";
import View_Vehicle from "./components/add_vehicle/View_vehicle/ViewVehicleMethod";
import AddEditEmployee from "./components/add_Employee/AddEditEmployee";
//import Add_Delete_Intern from './components/add_Intern/AddEditIntern';
//import Login from "../src/components/Login/Login";
import ViewPersonDetails from "./components/add_person/View_person/ViewPersonDetails";
import ViewVehicleDetails from "./components/add_vehicle/View_vehicle/ViewVehicleDetails";
import ViewMaterialDetails from "./components/add_material/View_material/ViewMaterialDetails";
import SelectedDock from "./components/Docking/SelectedDock";
import View_employees from "./components/add_Employee/View_employees";

import Registration from "./components/registration/Registration";
import Registers from "./components/registers/index";

import AddEditCustomer from "./components/add_customer/AddEditCustomer";
import AddEditLocation from "./components/add_location/AddEditLocation";
import View_Customers from "./components/add_customer/View_Customers";
import View_Locations from "./components/add_location/View_Locations";
import AddEditThirdParty from "./components/IT_Third_Party/AddEditThirdParty";
import View_ThirdParty from "./components/IT_Third_Party/View_ThirdParty";

import AddEditIntern from "./components/Intern/AddEditIntern";
import View_Interns from "./components/Intern/View_Interns";
import View_Attendance from "./components/attendance/View_Attendance";
import View_Admins from "./components/View_Admins";
import Profile from "./components/Profile/Profile";
import ViewMaterialWard from "./components/add_material/View_material/ViewMaterialWard";
import ViewOtherMaterials from "./components/add_material/View_material/ViewOtherMaterials";

export default [
  // {
  //   path: "/Login",
  //   layout: Login,
  //   component: Login
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   layout: Login,
  //   component: () => <Redirect to="/Login" />
  // },
  {
    path: "/registration",
    layout: props => <div {...props} />,
    component: Registration
  },
  {
    path: "/Dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },

  // {
  //   path: "/Material_InOut",
  //   layout: DefaultLayout,
  //   component: Material_InOut
  // },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  // {
  //   path: "/Person_inOut",
  //   layout: DefaultLayout,
  //   component: Person_inOut
  // },
  // {
  //   path: "/Vehicle_InOut",
  //   layout: DefaultLayout,
  //   component: Vehicle_InOut
  // },
  {
    path: "/Vehicle_Out",
    layout: DefaultLayout,
    component: Vehicle_Out
  },
  {
    path: "/AddEditMaterial",
    layout: DefaultLayout,
    component: AddEditMaterial
  },
  {
    path: "/AddEditPerson",
    layout: DefaultLayout,
    component: AddEditPerson
  },
  {
    path: "/AddEditVehicle",
    layout: DefaultLayout,
    component: AddEditVehicle
  },
  {
    path: "/Person_Out",
    layout: DefaultLayout,
    component: Person_Out
  },
  {
    path: "/Material_Out",
    layout: DefaultLayout,
    component: AddEditMaterial
  },
  {
    path: "/View_AllPerson",
    layout: DefaultLayout,
    component: View_Person
  },
  {
    path: "/person",
    layout: DefaultLayout,
    component: ViewPersonDetails
  },
  {
    path: "/View_AllVisitors",
    layout: DefaultLayout,
    component: View_Person
  },
  {
    path: "/View_AllLabours",
    layout: DefaultLayout,
    component: View_Person
  },
  {
    path: "/View_AllInterns",
    layout: DefaultLayout,
    component: View_Person
  },
  {
    path: "/vehicle",
    layout: DefaultLayout,
    component: ViewVehicleDetails
  },
  {
    path: "/material",
    layout: DefaultLayout,
    component: ViewMaterialDetails
  },
  {
    path: "/View_AllMaterial",
    layout: DefaultLayout,
    component: View_Material
  },
  {
    path: "/ViewMaterialInward",
    layout: DefaultLayout,
    component: ViewMaterialWard
  },
  {
    path: "/ViewMaterialOutward",
    layout: DefaultLayout,
    component: ViewMaterialWard
  },
  {
    path: "/View_AllDocuments",
    layout: DefaultLayout,
    component: ViewOtherMaterials
  },
  {
    path: "/View_AllVehicle",
    layout: DefaultLayout,
    component: View_Vehicle
  },
  // {
  //   path: "/Employee_InOut",
  //   layout: DefaultLayout,
  //   component: Employee_InOut
  // },
  {
    path: "/AddEditEmployee",
    layout: DefaultLayout,
    component: AddEditEmployee
  },
  // {
  //   path: "/Intern_InOut",
  //   layout: DefaultLayout,
  //   component: Intern_InOut
  // },
  // {
  //   path: "/Add_Delete_Intern",
  //   layout: DefaultLayout,
  //   component: Add_Delete_Intern
  // },
  {
    path: "/Material_Counter",
    layout: DefaultLayout,
    component: SelectedDock
  },
  {
    path: "/View_employees",
    layout: DefaultLayout,
    component: View_employees
  },
  {
    path: "/registers",
    layout: DefaultLayout,
    component: Registers
  },
  {
    path: "/AddEditCustomer",
    layout: DefaultLayout,
    component: AddEditCustomer
  },
  {
    path: "/AddEditLocation",
    layout: DefaultLayout,
    component: AddEditLocation
  },
  {
    path: "/View_Customers",
    layout: DefaultLayout,
    component: View_Customers
  },
  {
    path: "/View_Locations",
    layout: DefaultLayout,
    component: View_Locations
  },
  {
    path: "/AddEditThirdParty",
    layout: DefaultLayout,
    component: AddEditThirdParty
  },
  {
    path: "/View_ThirdParty",
    layout: DefaultLayout,
    component: View_ThirdParty
  },
  {
    path: "/AddEditIntern",
    layout: DefaultLayout,
    component: AddEditIntern
  },
  {
    path: "/View_Interns",
    layout: DefaultLayout,
    component: View_Interns
  },
  {
    path: "/View_Attendance",
    layout: DefaultLayout,
    component: View_Attendance
  },
  {
    path: "/View_Admins",
    layout: DefaultLayout,
    component: View_Admins
  },
  {
    path: "/Profile",
    layout: DefaultLayout,
    component: Profile
  }
];
