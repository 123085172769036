import React from "react";
import { Table } from "react-bootstrap";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import moment from "moment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import styles from "./table.module.css";
const getFormattedDateTime = dateTimeString => {
  if (typeof dateTimeString !== "string") return "";
  return moment(dateTimeString).format("hh:mma, D MMM");
};

export default ({ data, headings, handleChange }) => {
  return (
    <>
      {data.length === 0 ? (
        <div className="text-center text-secondary">No Data found</div>
      ) : (
        <Table responsive>
          {headings && (
            <thead>
              <tr className="text-center">
                {headings.map((h, i) => (
                  <th key={i}>{h}</th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {data.map(
              (
                {
                  icon,
                  status,
                  pname,
                  title,
                  time,
                  info,
                  url,
                  ptype,
                  pcount,
                  numHours,
                  cumuHours,
                  pMobileNumber,
                  personType,
                  cust,
                  token,
                  oname,
                  odName,
                  ovNum,
                  ...others
                },
                index
              ) => (
                <tr
                  key={index}
                  onClick={() => handleChange && handleChange(info)}
                  style={handleChange && { cursor: "pointer" }}
                >
                  {icon && (
                    <td className="text-center">
                      <Icon
                        style={{
                          color:
                            icon === "in"
                              ? "#17c671"
                              : icon === "out"
                              ? "#c4183c"
                              : "darkcyan", //incase of docking queue
                          fontSize: 20
                        }}
                        className={
                          //incase of docking queue
                          !["in", "out"].includes(icon)
                            ? styles.rotateicon
                            : undefined
                        }
                      >
                        {icon === "in"
                          ? "arrow_downward"
                          : icon === "out"
                          ? "arrow_upward"
                          : "hourglass_full" //incase of docking queue
                        }
                      </Icon>
                    </td>
                  )}
                  {status && (
                    <td className="text-center">
                      <Icon
                        style={{
                          color: status === "approved" ? "#17c671" : "orange",
                          fontSize: 20
                        }}
                      >
                        {status === "approved"
                          ? "check_circle"
                          : "fiber_manual_record"}
                      </Icon>
                    </td>
                  )}

                  {/* pname for material inward outward */}
                  {/* {pname && <td className="text-center">{pname}</td>} */}
                  {url && title ? (
                    <td className="text-center">
                      <Link to={url} style={{ color: "#212529" }}>
                        {title}
                      </Link>
                    </td>
                  ) : (
                    title && <td className="text-center">{title}</td>
                  )}

                  {/*view all person */}
                  {pMobileNumber && (
                    <td className="text-center">{pMobileNumber}</td>
                  )}
                  {personType && <td className="text-center">{personType}</td>}

                  {/* ALl Persons in Facility */}
                  {ptype && <td className="text-center">{ptype}</td>}
                  {pcount >= 0 && <td className="text-center">{pcount}</td>}

                  {/*view attendance date is refrence as title ,rest two are below */}
                  {numHours >= 0 && <td className="text-center">{numHours}</td>}
                  {cumuHours >= 0 && (
                    <td className="text-center">{cumuHours}</td>
                  )}

                  {/* view all material and Docking Queue */}
                  {cust && <td className="text-center">{cust}</td>}

                  {/* view all vehicle */}
                  {token && <td className="text-center">{token}</td>}

                  {/* view other materials */}
                  {oname && <td className="text-center">{oname}</td>}
                  {odName && <td className="text-center">{odName}</td>}
                  {ovNum && <td className="text-center">{ovNum}</td>}

                  <td
                    className="text-center"
                    style={{ color: "#999", fontWeight: "normal" }}
                  >
                    {time && <span>{getFormattedDateTime(time)}</span>}
                  </td>
                  {Object.keys(others).map((k, key) => (
                    <td key={key} className="text-center">
                      {others[k]}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};
