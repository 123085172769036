import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import checkDD from "../components/common/CheckSessionStorage";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  let dashDivs = checkDD().dashboard_divs;
  //console.log(dashDivs);
  let cond = dashDivs.includes("MainSidebar");
  return (
    <Container fluid>
      <Row>
        {cond && <MainSidebar />}
        <Col
          className="main-content p-0"
          //{...(cond && {lg:{size:10}})}
          {...(cond && {
            lg: { size: 10, offset: 2 },
            md: { size: 9, offset: 3 },
            sm: "12",
            tag: "main"
          })}
          style={{ minHeight: "100vh" }}
        >
          {!noNavbar && <MainNavbar />}
          <div
            style={
              !cond
                ? { minHeight: "calc(100vh - 160px)", marginBottom: "4rem" }
                : { marginBottom: "4rem" }
            }
          >
            {children}
          </div>
          {!noFooter && <MainFooter />}
        </Col>
      </Row>
    </Container>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
