import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import "./NavbarDropdown.css";
import { connect } from "react-redux";
import { handle_location } from "../../../actions/postActions";

class NavbarDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: this.props.dashboard_data
        ? this.props.dashboard_data.role[0].locations
        : []
    };
  }

  componentDidMount() {
    // console.log('seting locationsss')
    //this.props.handle_location(this.state.locations[0]);
  }
  handlelocation = e => {
    //console.log(e.target.getAttribute("value"));
    this.setState(
      {
        location: e.target.getAttribute("value")
      },
      this.props.handle_location(e.target.getAttribute("value"))
    );
    sessionStorage.setItem(
      "selectedLocation",
      JSON.stringify(e.target.getAttribute("value"))
    );

    //window.location.reload()
  };

  render() {
    const { locations } = this.state;
    const { location } = this.props;
    //console.log(locations);

    const maplocations = locations.map((location, index) => (
      <Dropdown.Item
        href="#"
        key={index}
        value={location}
        onClick={this.handlelocation}
      >
        {location}
      </Dropdown.Item>
    ));

    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {location ? location : locations[0]}
        </Dropdown.Toggle>

        <Dropdown.Menu>{maplocations}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <h3
    ref={ref}
    className="page-title"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: "pointer", textTransform: "uppercase" }}
  >
    {children} <span style={{ fontSize: 15 }}>&#x25bc;</span>
  </h3>
));

const mapstateToProps = state => ({
  dashboard_data: state.auth.dashboard_data,
  location: state.auth.location
});

export default connect(mapstateToProps, { handle_location })(NavbarDropdown);
