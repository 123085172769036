import React from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroupItem,
  FormInput,
  FormSelect,
  FormCheckbox
} from "shards-react";

import "../../assets/range-date-picker.css";
import AxiosPost from "../common/AxiosPost";

import "../../assets/range-date-picker.css";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";

class AddEditEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      //get all values from form field
      _id: "",
      mobileNumber: "",
      otpEntered: "",
      name: "",
      fathersName: "",
      gender: "",
      id: "",
      address: "",
      designation: "",
      currentAccessTime: "",
      dOB: "",
      visitorCompanyIdCard: "",
      visitorBusinessCard: "",
      roleId: "",
      authorisationId: "",
      presentCompany: "",
      dailyWageRate: "",
      reportingManager: "",
      esicNumber: "",
      labauthorisationId: "",
      whomToMeetorReport: "",
      drivingLicense: "",
      driverPresentCompany: "",
      isDriverSober: "",
      approvalDateAndTime: "",
      employeeType: "null",
      rolesList: [],
      LocationList: [],

      showdetails: true,
      showpassrow: true,
      showcpassbtn: false,
      readOnly: false,
      loadermsg: "",
      role: "",
      username: "",
      password: "",
      repassword: "",
      locations: [],
      showspinner: false
    };
    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this); // bind the method
    this.handleSubmit = this.handleSubmit.bind(this); // bind the method
  }

  componentDidMount() {
    // console.log(this.props);
    if (this.props.history.location.state) {
      let mobileNumber = this.props.history.location.state.EditNumber;

      this.setState(
        {
          mobileNumber,
          showpassrow: false,
          showcpassbtn: true,
          readOnly: true
        },
        () => this.CheckMobile()
      );
    }
    AxiosPost("/get_roles", {}, res => {
      //console.log(res);
      this.setState({ rolesList: res.data });
    });

    AxiosPost("/get_locations", {}, res => {
      // console.log(res);
      this.setState({ LocationList: res.data });
    });
  }
  handleDate = date => {
    // handle date picker
    //  console.log("We are in the handle date function");
    //console.log(date.toDateString());
    this.setState({
      dOB: date
    });
  };
  handleDateAndTime = dateandTime => {
    // handle date&time picker method
    console.log("We are in the date and Time function");
    this.setState({
      approvalDateAndTime: dateandTime
    });
  };
  handleChange(event) {
    // console.log("We are in the change function");
    // console.log(event.target.id);
    const name = event.target.id;
    const value = event.target.value;
    // console.log(name);
    // console.log(value);
    this.setState({
      [name]: value
    });
    // console.log("We are displaying the state");
    // console.log(this.state);
  }
  updateState = event => {
    // update the state according to user type
    const val = event.target.value;
    //  console.log(val);
    this.setState({ role: val });
    // if (val === '1') {
    //   this.setState({
    //     employeeType: 'Customer'
    //   })
    // }
    // else if (val === '2') {
    //   this.setState({
    //     employeeType: 'Labour'
    //   })
    // }
    // else if (val === '3') {
    //   this.setState({
    //     employeeType: 'ContractedEmployee'
    //   })
    // }
    // else if (val === '4') {
    //   this.setState({
    //     employeeType: 'TruckDriver'
    //   })
    // }
    // else if (val === '5') {
    //   this.setState({
    //     employeeType: 'Visitor'
    //   })
    // }
  };

  handleLocations = val => {
    if (this.state.locations.includes(val)) {
      let newarr = this.state.locations.filter(
        (value, ind, arr) => value !== val
      );
      //console.log(newarr);
      this.setState({ locations: newarr });
    } else {
      let locations = this.state.locations.concat(val);
      //console.log(locations);
      this.setState({ locations });
    }
  };

  handlePassword = e => {
    e.preventDefault();
    this.setState(prevs => ({ showpassrow: !prevs.showpassrow }));
  };
  CheckMobile = e => {
    if (e) e.preventDefault();

    if ((this.state.mobileNumber + "").length !== 10) {
      PNotify.error({
        text: "Number should contain 10 digits"
      });
      return;
    }
    this.setState({ loadermsg: "Loading...", showspinner: true });
    AxiosPost(
      "/check_employee_number",
      { mobileNumber: this.state.mobileNumber },
      res => {
        this.setState({ showspinner: false });
        //console.log(res);
        if (Array.isArray(res.data) && res.data.length === 0) {
          this.setState({ showdetails: true, showpassrow: true });
          return;
        }
        const { mobileNumber, username, role, locations } = res.data[0];
        this.setState({ mobileNumber, username, role, locations });
        this.setState({ showdetails: true, showcpassbtn: true });
      }
    );
  };
  handlerror = errmsg => {
    PNotify.error({
      text: errmsg
    });
  };
  handleSubmit(event) {
    // handle submit button method
    event.preventDefault();
    if (this.state.mobileNumber.toString().length !== 10) {
      this.handlerror("Number should be of 10 digits");
      return;
    } else if (this.state.showpassrow && !this.state.password.length > 0) {
      this.handlerror("Password should not be empty");
      return;
    } else if (this.state.password !== this.state.repassword) {
      this.handlerror("Password do not matched");
      this.setState({
        password: "",
        repassword: ""
      });
      return;
    }
    this.setState({
      loadermsg: "Redirecting to Dashboard...",
      showspinner: true
    });
    //console.log("We are in the submit function");
    //console.log(this.state);
    let data = {
      username: this.state.username,
      mobileNumber: this.state.mobileNumber,
      password: this.state.password,
      role: this.state.role,
      locations: this.state.locations,
      employee_type: "employee"
    };
    //console.log("data,before sending ", data);
    AxiosPost(
      "/addemployee",
      data,
      res => {
        //console.log(res);
        if (
          (res.status === 200 && res.data.Status === " Updated! ") ||
          (res.status === 200 && res.data.Status === " Okay! ")
        ) {
          this.setState({ showspinner: false });
          PNotify.info("User Saved");
        }
        this.props.history.push("/dashboard");
      },
      () => {
        this.setState({ showspinner: false });
        PNotify.error({
          text: "OOPS ! Something went wrong."
        });
      }
    );
  }

  render() {
    // let pageTitle;
    // if (this.state.id) {
    //   pageTitle = <h3>Edit Employee</h3>;
    // } else {
    //   pageTitle = <h3>Add Employee</h3>;
    // }

    return (
      <Card small className="m-3 m-md-4">
        <LoadingOverlay
          active={this.state.showspinner}
          spinner
          text={this.state.loadermsg}
        >
          <CardBody>
            <CardTitle className="p-3 h3">{"Add User"}</CardTitle>
            <Row>
              {/* {!this.state.showdetails && (
            <Col sm={12}>
              <Form onSubmit={this.CheckMobile}>
                <ListGroupItem className="p-4">
                  <Row>
                    <Col md="6" className="form-group">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <FormInput
                        required
                        type="number"
                        placeholder="Mobile Number"
                        id="mobileNumber"
                        value={this.state.mobileNumber}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col md="10" className="form-group">
                      <Button type="submit" theme="accent" size="sm">
                        <i className="material-icons"></i>Check
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </Form>
            </Col>
          )} */}

              {this.state.showdetails && (
                <Col sm={12}>
                  <Form onSubmit={this.handleSubmit} autoComplete={"off"}>
                    {/* <ListGroupItem className="px-4">
              <Row>
                <Col md="6" className="form-group">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <FormInput placeholder="Mobile Number" id="mobileNumber" value={this.state.mobileNumber} onChange={this.handleChange} />
                </Col>
                <Col md="6" className="form-group" style={{ marginTop: "30px" }}>
                 
                  <Button theme="accent" size="sm" className="ml-auto"><i className="material-icons"></i>Verify</Button>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="form-group">
                  <label htmlFor="otpEntered">OTP</label>
                  <FormInput placeholder="OTP" id="otpEntered" value={this.state.otpEntered} onChange={this.handleChange} />
                </Col>
                <Col md="6" className="form-group" style={{ marginTop: "30px" }}>
                 
                  <Button theme="accent" size="sm" className="ml-auto"><i className="material-icons"></i>Verify</Button>
                </Col>
              </Row>
            </ListGroupItem> */}

                    {/* <CardHeader className="border-bottom">
                    <h3 className="mb-0">Add User</h3>
                  </CardHeader> */}

                    <ListGroupItem className="p-4 border-0">
                      {this.state.showcpassbtn && (
                        <Row className={"justify-content-end"}>
                          <Col className="col-auto">
                            <Button onClick={this.handlePassword}>
                              Change Password
                            </Button>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col md="6" className="form-group">
                          <label className="required" htmlFor="mobileNumber">
                            Mobile Number
                          </label>
                          <FormInput
                            required
                            type="number"
                            placeholder="Mobile Number"
                            id="mobileNumber"
                            value={this.state.mobileNumber}
                            onChange={this.handleChange}
                            readOnly={this.state.readOnly}
                          />
                        </Col>

                        <Col md="6">
                          <Form.Group controlId="username">
                            <Form.Label className="required">
                              Username
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="username"
                              value={this.state.username}
                              onChange={this.handleChange}
                              placeholder="Enter Username"
                              autoComplete={"off"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {this.state.showpassrow && (
                        <Row>
                          <Col md="6" className="form-group">
                            <label className="required" htmlFor="password">
                              Password
                            </label>
                            <FormInput
                              required
                              type="password"
                              placeholder="Enter Password"
                              id="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                              autoComplete={"off"}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label className="required" htmlFor="repassword">
                              Confirm Password
                            </label>
                            <FormInput
                              required
                              type="password"
                              placeholder="Re-Enter Password"
                              id="repassword"
                              value={this.state.repassword}
                              onChange={this.handleChange}
                              autoComplete={"off"}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="6" className="form-group">
                          <label className="required" htmlFor="role">
                            Choose Role Type
                          </label>
                          <FormSelect
                            required={true}
                            id="role"
                            value={this.state.role}
                            onChange={this.updateState}
                            list="rolesList"
                          >
                            <option value="" disabled>
                              Choose Role Type
                            </option>
                            {this.state.rolesList.map(role => (
                              <option value={role} key={role}>
                                {role}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>

                        <Col md="6" className="form-group">
                          <label className="required" htmlFor="locations">
                            Choose Location
                          </label>

                          {this.state.LocationList.map((location, index) => (
                            <FormCheckbox
                              key={index}
                              checked={this.state.locations.includes(
                                location.locName
                              )}
                              onChange={() =>
                                this.handleLocations(location.locName)
                              }
                              name="locations"
                              required={
                                this.state.locations.length < 1 ? true : false
                              }
                            >
                              {location.locName}
                            </FormCheckbox>
                          ))}

                          {/* <FormSelect id="location" defaultvalue={this.state.location} onChange={this.handleChange} >
                    <option value="" disabled selected>Choose location</option>
                    {this.props.locationsdata.map((location,index)=>
                      <option key={index} value={location}>{location}</option>
                    )}
                   
                  </FormSelect> */}
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        theme="accent"
                        size="sm"
                        className="d-block mx-auto"
                      >
                        <i className="material-icons"></i>Submit
                      </Button>
                    </ListGroupItem>

                    {/* <ListGroupItem className="p-4">
              <Row>
                <Col md="6">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Name" />
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group controlId="father_sName">
                    <Form.Label>Father's Name</Form.Label>
                    <Form.Control type="text" name="father_sName" value={this.state.father_sName} onChange={this.handleChange} placeholder="Father's Name" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control type="text" name="gender" value={this.state.gender} onChange={this.handleChange} placeholder="Gender" />
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group controlId="id">
                    <Form.Label>Choose ID</Form.Label>
                    <Form.Control type="text" name="id" value={this.state.id} onChange={this.handleChange} placeholder="Choose ID" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" name="address" value={this.state.address} onChange={this.handleChange} placeholder="Address" />
                  </Form.Group>
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="role">Choose Role Type</label>
                  <FormSelect id="role" defaultvalue={this.state.role} onChange={this.updateState} >
                    <option value="" disabled selected>Choose Role Type</option>
                    <option value="admin">Admin</option>
                    <option value="HR">HR</option>
                    <option value="dock_supervisor">Dock Supervisor</option>
                    <option value="security_guard">Security Guard</option>
                    <option value="zero">Zero</option>
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group controlId="designation">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" name="designation" value={this.state.designation} onChange={this.handleChange} placeholder="Current Access Date" />
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group controlId="currentAccessTime">
                    <Form.Label>Current Access Time</Form.Label>
                    <Form.Control type="text" name="currentAccessTime" value={this.state.currentAccessTime} onChange={this.handleChange} placeholder="Current Access Time" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group controlId="dob">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Form.Control type="date" name="dob" value={this.state.dob} onChange={this.handleChange} placeholder="Date Of Birth" />
                  </Form.Group>
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="employeeType">Choose Person Type</label>
                  <FormSelect id="employeeType" defaultvalue={this.state.employeeType} onChange={this.updateState} >
                    <option value="" disabled selected>Choose Person Type</option>
                    <option value="1">Customer</option>
                    <option value="2">Labour</option>
                    <option value="3">Contracted Employee</option>
                    <option value="4">Truck Driver</option>
                    <option value="5">Visitor</option>
                  </FormSelect>
                </Col>
              </Row>
            </ListGroupItem>
           
            <div style={{ display: this.state.employeeType === 'Customer' ? 'block' : 'none' }}>
              <CardHeader className="border-bottom">
                <h4 className="mb-0">Customer Details</h4>
              </CardHeader>
              <ListGroupItem className="p-4">
                <Row>
                  <Col md="6">
                    <Form.Group controlId="visitorCompanyIdCard">
                      <Form.Label>Visitor company ID Card</Form.Label>
                      <Form.Control type="text" name="visitorCompanyIdCard" value={this.state.visitorCompanyIdCard} onChange={this.handleChange} placeholder="Visitor company ID Card" />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group controlId="visitorBusinessCard">
                      <Form.Label>Visitor Business Card</Form.Label>
                      <Form.Control type="text" name="visitorBusinessCard" value={this.state.visitorBusinessCard} onChange={this.handleChange} placeholder="Visitor Business Card" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="roleId">
                      <Form.Label>Role Id</Form.Label>
                      <Form.Control type="text" name="roleId" value={this.state.roleId} onChange={this.handleChange} placeholder="Role Id" />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group controlId="authorisationId">
                      <Form.Label>Authorisation Id</Form.Label>
                      <Form.Control type="text" name="authorisationId" value={this.state.authorisationId} onChange={this.handleChange} placeholder="Authorisation Id" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="presentCompany">
                      <Form.Label>Present Company</Form.Label>
                      <Form.Control type="text" name="presentCompany" value={this.state.presentCompany} onChange={this.handleChange} placeholder="Present Company" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="form-group">
                    <Form.Group>
                      <Form.Control type="hidden" name="id" value={this.state.id} />
                      <Button theme="accent" size="sm" className="ml-auto" onClick={this.handleSubmit}><i className="material-icons"></i>Submit</Button>
                    </Form.Group>
                  </Col>
                </Row>
              </ListGroupItem>
            </div>
           
            <div style={{ display: this.state.employeeType === 'Labour' ? 'block' : 'none' }}>
              <CardHeader className="border-bottom">
                <h4 className="mb-0">Labour Details</h4>
              </CardHeader>
              <ListGroupItem className="p-4">
                <Row>
                  <Col md="6">
                    <Form.Group controlId="dailyWageRate">
                      <Form.Label>Daily Wage</Form.Label>
                      <Form.Control type="text" name="dailyWageRate" value={this.state.dailyWageRate} onChange={this.handleChange} placeholder="Daily Wage" />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group controlId="reportingManager">
                      <Form.Label>Reporting Manager</Form.Label>
                      <Form.Control type="text" name="reportingManager" value={this.state.reportingManager} onChange={this.handleChange} placeholder="Reporting Manager" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="esicNumber">
                      <Form.Label>ESIC Number</Form.Label>
                      <Form.Control type="text" name="esicNumber" value={this.state.esicNumber} onChange={this.handleChange} placeholder="ESIC Number" />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group controlId="labAuthorisationId">
                      <Form.Label>Authorisation Id</Form.Label>
                      <Form.Control type="text" name="labAuthorisationId" value={this.state.labAuthorisationId} onChange={this.handleChange} placeholder="Authorisation Id" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Control type="hidden" name="id" value={this.state.id} />
                  <Button theme="accent" size="sm" className="ml-auto" onClick={this.handleSubmit}><i className="material-icons"></i>Submit</Button>
                </Form.Group>
              </ListGroupItem>

            </div>
          

            <div style={{ display: this.state.employeeType === 'TruckDriver' ? 'block' : 'none' }}>
              <CardHeader className="border-bottom">
                <h4 className="mb-0">Driver Details</h4>
              </CardHeader>
              <ListGroupItem className="p-4">
                <Row>
                  <Col md="6">
                    <Form.Group controlId="whomToReport">
                      <Form.Label>Whom to report</Form.Label>
                      <Form.Control type="text" name="whomToReport" value={this.state.whomToReport} onChange={this.handleChange} placeholder="Whom to report" />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group controlId="drivingLicense">
                      <Form.Label>Driving License</Form.Label>
                      <Form.Control type="text" name="drivingLicense" value={this.state.drivingLicense} onChange={this.handleChange} placeholder="Driving License" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="driverPresentCompany">
                      <Form.Label>Present Company</Form.Label>
                      <Form.Control type="text" name="driverPresentCompany" value={this.state.driverPresentCompany} onChange={this.handleChange} placeholder="Present Company" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="isDriverSober">
                      <Form.Label>Is Driver Sober</Form.Label>
                      <Form.Control type="text" name="isDriverSober" value={this.state.isDriverSober} onChange={this.handleChange} placeholder="Is Driver Sober" />
                    </Form.Group>
                  </Col>

                </Row>
                <Form.Group>
                  <Form.Control type="hidden" name="id" value={this.state.id} />
                  <Button theme="accent" size="sm" className="ml-auto" onClick={this.handleSubmit}><i className="material-icons"></i>Submit</Button>
                </Form.Group>
              </ListGroupItem>
            </div>



           
            <div style={{ display: this.state.employeeType === 'Visitor' ? 'block' : 'none' }}>
              <CardHeader className="border-bottom">
                <h4 className="mb-0">Visitor Details</h4>
              </CardHeader>
              <ListGroupItem className="p-4">
                <Row>
                  <Col md="6">
                    <Form.Group controlId="whomToMeetorReport">
                      <Form.Label>Whom To Meet or Report</Form.Label>
                      <Form.Control type="text" name="whomToMeetorReport" value={this.state.whomToMeetorReport} onChange={this.handleChange} placeholder="Whom To Meet or Report" />
                    </Form.Group>
                  </Col>


                  <Col md="6" className="form-group">
                    <Form.Group controlId="approvalDateAndTime">
                      <Form.Label>Approval Date And Time</Form.Label>
                      <Form.Control type="date" name="approvalDateAndTime" value={this.state.approvalDateAndTime} onChange={this.handleChange} placeholder="Approval Date And Time" />
                    </Form.Group>
                   
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="form-group">
                    <Form.Group>
                      <Form.Control type="hidden" name="id" value={this.state.id} />
                      <Button theme="accent" size="sm" className="ml-auto" onClick={this.handleSubmit}><i className="material-icons"></i>Submit</Button>
                    </Form.Group>
                  </Col>
                </Row>
              </ListGroupItem>
            </div>
            
            <div style={{ display: this.state.employeeType === 'ContractedEmployee' ? 'block' : 'none' }}>
              <h1>Include Actual Code of Employee Here</h1>
            </div> */}
                  </Form>
                </Col>
              )}
            </Row>
          </CardBody>
        </LoadingOverlay>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url,
  locationsdata:
    state.auth.dashboard_data !== null
      ? state.auth.dashboard_data.role[0].locations
      : ["demo location"]
});
export default connect(mapStateToProps)(AddEditEmployee);
