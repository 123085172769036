import React from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  ListGroupItem,
  FormInput,
  FormSelect,
  FormCheckbox
} from "shards-react";

import "../../assets/range-date-picker.css";
import axiosPost from "../common/AxiosPost";

import "../../assets/range-date-picker.css";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import PNotify from "pnotify/dist/es/PNotify";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";

class AddEditIntern extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      //get all values from form field
      _id: "",
      mobileNumber: "",
      otpEntered: "",
      name: "",
      fathersName: "",
      gender: "",
      id: "",
      address: "",
      designation: "",
      currentAccessTime: "",
      dOB: "",
      visitorCompanyIdCard: "",
      visitorBusinessCard: "",
      roleId: "",
      authorisationId: "",
      presentCompany: "",
      dailyWageRate: "",
      reportingManager: "",
      esicNumber: "",
      labauthorisationId: "",
      whomToMeetorReport: "",
      drivingLicense: "",
      driverPresentCompany: "",
      isDriverSober: "",
      approvalDateAndTime: "",
      employeeType: "null",
      rolesList: [],
      LocationList: [],

      showdetails: true,
      showpassrow: true,
      showcpassbtn: false,
      loadermsg: "",
      role: "",
      username: "",
      password: "",
      repassword: "",
      locations: [],
      showspinner: false
    };
    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this); // bind the method
    this.handleSubmit = this.handleSubmit.bind(this); // bind the method
  }

  componentDidMount() {
    //console.log(this.props);
    if (this.props.history.location.state) {
      let mobileNumber = this.props.history.location.state.EditNumber;

      this.setState(
        { mobileNumber, showpassrow: false, showcpassbtn: true },
        () => this.CheckMobile()
      );
    }
    axiosPost("/get_roles", {}, res => {
      //console.log(res);
      this.setState({ rolesList: res.data });
    });

    axiosPost("/get_locations", {}, res => {
      //console.log(res);
      this.setState({ LocationList: res.data });
    });
  }
  handleDate = date => {
    // handle date picker
    //console.log("We are in the handle date function");
    //console.log(date.toDateString());
    this.setState({
      dOB: date
    });
  };
  handleDateAndTime = dateandTime => {
    // handle date&time picker method
    //console.log("We are in the date and Time function");
    this.setState({
      approvalDateAndTime: dateandTime
    });
  };
  handleChange(event) {
    // //console.log("We are in the change function");
    // //console.log(event.target.id);
    const name = event.target.id;
    const value = event.target.value;
    // //console.log(name);
    // //console.log(value);
    this.setState({
      [name]: value
    });
    // //console.log("We are displaying the state");
    // //console.log(this.state);
  }
  updateState = event => {
    // update the state according to user type
    const val = event.target.value;
    //  //console.log(val);
    this.setState({ role: val });
    // if (val === '1') {
    //   this.setState({
    //     employeeType: 'Customer'
    //   })
    // }
    // else if (val === '2') {
    //   this.setState({
    //     employeeType: 'Labour'
    //   })
    // }
    // else if (val === '3') {
    //   this.setState({
    //     employeeType: 'ContractedEmployee'
    //   })
    // }
    // else if (val === '4') {
    //   this.setState({
    //     employeeType: 'TruckDriver'
    //   })
    // }
    // else if (val === '5') {
    //   this.setState({
    //     employeeType: 'Visitor'
    //   })
    // }
  };

  handleLocations = val => {
    if (this.state.locations.includes(val)) {
      let newarr = this.state.locations.filter(
        (value, ind, arr) => value !== val
      );
      //console.log(newarr);
      this.setState({ locations: newarr });
    } else {
      let locations = this.state.locations.concat(val);
      //console.log(locations);
      this.setState({ locations });
    }
  };

  handlePassword = e => {
    e.preventDefault();
    this.setState(prevs => ({ showpassrow: !prevs.showpassrow }));
  };
  CheckMobile = e => {
    if (e) e.preventDefault();

    if ((this.state.mobileNumber + "").length !== 10) {
      PNotify.error({
        text: "Number is not valid"
      });
      return;
    }
    this.setState({ loadermsg: "Loading...", showspinner: true });
    axiosPost(
      "/check_employee_number",
      {
        mobileNumber: this.state.mobileNumber
      },
      res => {
        this.setState({ showspinner: false });
        //console.log(res);
        if (Array.isArray(res.data) && res.data.length === 0) {
          this.setState({ showdetails: true, showpassrow: true });
          return;
        }
        const { mobileNumber, username, role, locations } = res.data[0];
        this.setState({ mobileNumber, username, role, locations });
        this.setState({ showdetails: true, showcpassbtn: true });
      }
    );
  };
  handlerror = errmsg => {
    PNotify.error({
      text: errmsg
    });
  };
  handleSubmit(event) {
    // handle submit button method
    event.preventDefault();
    if (this.state.mobileNumber.toString().length !== 10) {
      this.handlerror("Number should be of 10 digits");
      return;
    } else if (this.state.showpassrow && !this.state.password.length > 0) {
      this.handlerror("Password should not be empty");
      return;
    } else if (this.state.password !== this.state.repassword) {
      this.handlerror("Password do not matched");
      this.setState({
        password: "",
        repassword: ""
      });
      return;
    }
    this.setState({
      loadermsg: "Redirecting to Dashboard...",
      showspinner: true
    });
    //console.log("We are in the submit function");
    //console.log(this.state);
    let data = {
      username: this.state.username,
      mobileNumber: this.state.mobileNumber,
      password: this.state.password,
      role: this.state.role,
      locations: this.state.locations,
      employee_type: "intern"
    };
    //console.log("data,before sending ", data);
    axiosPost(
      "/addemployee",
      data,
      res => {
        this.setState({ showspinner: false });
        //console.log(res);
        if (
          (res.status === 200 && res.data.Status === " Updated! ") ||
          (res.status === 200 && res.data.Status === " Okay! ")
        )
          this.props.history.push("/dashboard");
      },
      () => {
        this.setState({ showspinner: false });
        PNotify.error({
          text: "OOPS ! Something went wrong."
        });
      }
    );
  }

  render() {
    // let pageTitle;
    // if (this.state.id) {
    //   pageTitle = <h3>Edit Employee</h3>;
    // } else {
    //   pageTitle = <h3>Add Employee</h3>;
    // }

    return (
      <Card small className="m-3 m-md-4">
        <CardBody>
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text={this.state.loadermsg}
          >
            <CardTitle className="p-3 h3">{"Add Intern"}</CardTitle>
            <Row>
              {this.state.showdetails && (
                <Col sm={12}>
                  <Form onSubmit={this.handleSubmit} autoComplete={"off"}>
                    <ListGroupItem className="p-4 border-0">
                      {this.state.showcpassbtn && (
                        <Row className={"justify-content-end"}>
                          <Col className="col-auto">
                            <Button onClick={this.handlePassword}>
                              Change Password
                            </Button>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col md="6" className="form-group">
                          <label htmlFor="mobileNumber" className="required">
                            Mobile Number
                          </label>
                          <FormInput
                            required
                            type="number"
                            placeholder="Mobile Number"
                            id="mobileNumber"
                            value={this.state.mobileNumber}
                            onChange={this.handleChange}
                          />
                        </Col>

                        <Col md="6">
                          <Form.Group controlId="username">
                            <Form.Label className="required">
                              Username
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="username"
                              value={this.state.username}
                              onChange={this.handleChange}
                              placeholder="Enter Username"
                              autoComplete={"off"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {this.state.showpassrow && (
                        <Row>
                          <Col md="6" className="form-group">
                            <label className="required" htmlFor="password">
                              Password
                            </label>
                            <FormInput
                              required
                              type="password"
                              placeholder="Enter Password"
                              id="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                              autoComplete={"off"}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label className="required" htmlFor="repassword">
                              Confirm Password
                            </label>
                            <FormInput
                              required
                              type="password"
                              placeholder="Re-Enter Password"
                              id="repassword"
                              value={this.state.repassword}
                              onChange={this.handleChange}
                              autoComplete={"off"}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="6" className="form-group">
                          <label className="required" htmlFor="role">
                            Choose Role Type
                          </label>
                          <FormSelect
                            required={true}
                            id="role"
                            value={this.state.role}
                            onChange={this.updateState}
                            list="rolesList"
                          >
                            <option value="" disabled>
                              Choose Role Type
                            </option>
                            {this.state.rolesList.map(role => (
                              <option value={role} key={role}>
                                {role}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>

                        <Col md="6" className="form-group">
                          <label className="required" htmlFor="locations">
                            Choose Location
                          </label>

                          {this.state.LocationList.map((location, index) => (
                            <FormCheckbox
                              key={index}
                              checked={this.state.locations.includes(
                                location.locName
                              )}
                              onChange={() =>
                                this.handleLocations(location.locName)
                              }
                              name="locations"
                              required={
                                this.state.locations.length < 1 ? true : false
                              }
                            >
                              {location.locName}
                            </FormCheckbox>
                          ))}
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        theme="accent"
                        size="md"
                        className="d-block mx-auto"
                      >
                        <i className="material-icons"></i>Submit
                      </Button>
                    </ListGroupItem>
                  </Form>
                </Col>
              )}
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  location: state.auth.location,
  url: state.auth.url,
  locationsdata:
    state.auth.dashboard_data !== null
      ? state.auth.dashboard_data.role[0].locations
      : ["demo location"]
});
export default connect(mapStateToProps)(AddEditIntern);
