import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import {
  Card,
  CardTitle,
  CardBody,
  FormSelect,
  FormRadio,
  Button
} from "shards-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

import SGActionsCard from "../dashboard_widgets/SGActionsCard";
import PNotify from "pnotify/dist/es/PNotify";
import AxiosPost from "../common/AxiosPost";
import "./AddEditVehicle.css";

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome5";
PNotify.defaults.delay = 8000;
class AddEditVehicle extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.data) this.initialState = this.props.data;
    else
      this.initialState = {
        //get all values from form field

        name: "",
        vehicleType: "",
        owner_Type: "",
        otherOwnerType: "",
        ownerName: "",
        mobileNumber: "",
        vehicle_Insurance: "",
        insurance_Expiry: "",
        vehicleNumber: "",
        vehicle_RC: "",
        containMaterial: "",

        showspinner: false,
        successmsg: false,
        showform: false,
        showCheckMobile: true,
        readOnly: false,
        isAlreadyRegistered: false //whther to show edit btn ot not
      };

    if (props.product) {
      this.state = props.product;
    } else {
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this); // bind the method
    this.handleSubmit = this.handleSubmit.bind(this); // bind the method
    this.customersList = React.createRef([]);
    this.rcRef = React.createRef();
    this.insuranceRef = React.createRef();
  }
  componentDidMount() {
    this.setState({ showspinner: false });
    //console.log(this.props.data);
    if (this.props.data) this.setState({ readOnly: true });
    else this.setState({ readOnly: false });

    //if mobile number passed from addeditperson(name,mobilenumber)
    if (this.props.location.mobileNumber) {
      this.setState({
        showCheckMobile: false,
        mobileNumber: this.props.location.mobileNumber,
        name: this.props.location.name
      });
    }
    //vehicle num ,typemay come from add material
    if (this.props.location.vehicleNumber) {
      this.setState({
        vehicleNumber: this.props.location.vehicleNumber,
        name: this.props.location.name,
        vehicleType: this.props.location.vehicleType,
        containMaterial: "Yes"
      });
      this.checkNumber();
    }
  }

  addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    //console.log(date);
    return date;
  };
  checkNumber = e => {
    if (e) e.preventDefault();
    this.setState({ showspinner: true });
    let url = `/check_vehicle_number`;
    let body = {
      vehicleNumber: this.state.vehicleNumber
    };
    //console.log("checking number at:", url);
    //will check whether vehicle is lready in or not,if not then call another api to get its data.
    AxiosPost(
      "/check_vehicle_inorout",
      body,
      res => {
        //console.log(res);

        if (
          res.data.Status ===
            "This vehicle is not present inside the facility." ||
          res.data.Status === "This vehicle never entered in the facility"
        ) {
          AxiosPost(
            "/check_vehicle_number",
            body,
            res => {
              this.setState({ showspinner: false });
              //console.log(res);
              let data = res.data;
              if (data.length === 1) {
                Object.keys(data[0]).forEach((key, index) => {
                  this.setState({ [key]: data[0][key] });
                  this.setState({ isAlreadyRegistered: true });
                  //console.log(key, data[0][key]);
                });
                if (this.props.location.mobileNumber) {
                  this.setState({
                    mobileNumber: this.props.location.mobileNumber
                  });
                }
                this.setState({
                  readOnly: true,
                  showform: true,
                  showspinner: false
                });

                //checking insureance expired or about to expire in one month
                let insDate = new Date(res.data[0].insurance_Expiry);
                let today = new Date();

                let nextMonth = new Date(this.addMonths(new Date(), 1));
                ////console.log(insDate, today, nextMonth);
                if (today > insDate || insDate < nextMonth) {
                  PNotify.info("Please update your Insurance");
                }
                //console.log("state is", this.state);
              } else {
                //console.log("data is is :", data.length);
                this.setState({
                  showform: true,
                  showspinner: false,
                  isAlreadyRegistered: false,
                  readOnly: false
                });
              }
            },
            () => {
              PNotify.error("Something Went Wrong !!");
              this.setState({
                vehicleNumber: ""
              });
            }
          );
        } else if (res.data.Status === "Already In") {
          PNotify.info("This Vehicle is Already In");
          this.setState({
            vehicleNumber: "",
            showspinner: false
          });
        }
      },
      () => {
        this.setState({ showspinner: false });
        PNotify.error("Something Went Wrong !!");
      }
    );
  };
  checkMobile = e => {
    e.preventDefault();
    this.setState({ submitmsg: false, showspinner: true });

    if (this.state.mobileNumber.toString().length === 10) {
      const data = {
        mobileNumber: this.state.mobileNumber,
        auth_status: "phone_entered"
      };

      AxiosPost(
        "/checkphone",
        data,
        res => {
          // this.setState({ showspinner: false });
          //console.log(res.data);

          if (
            res.data.Status === "This person is already inside the facility."
          ) {
            AxiosPost(
              "/get_person_inout_entry",
              { mobileNumber: this.state.mobileNumber },
              res => {
                //console.log(res);
                let person = res.data[0];
                this.setState({
                  name: person.name,
                  showCheckMobile: false,
                  showspinner: false
                });
              },
              () => {
                PNotify.error("Something Went Wrong. Please Try again !!");
              }
            );
          } else if (
            res.data.Status ===
            "OTP already sent to this number. Either enter it in Person In form or wait for 5 minutes."
          ) {
            PNotify.error(
              "OTP already sent to this number. Either enter it in Person In form or wait for 5 minutes."
            );
            this.setState({ showspinner: false });
            return;
          } else if (res.data.Status === " Okay! ") {
            PNotify.info("Please Add Person first");
            this.props.history.push({
              pathname: "/AddEditPerson",
              state: {
                OTPtype: "FirstPerson",
                mobileNumber: this.state.mobileNumber
              }
            });
            this.setState({ showspinner: false });
            return;
          } else {
            PNotify.error("Something Went Wrong !!");
            this.setState({ showspinner: false });
          }
        },
        () => {
          this.setState({ showspinner: false });
          PNotify.error("Something went Wrong.Try Again !!");
        }
      );
    } else {
      this.setState({
        showspinner: false,
        mobileNumber: ""
      });
      PNotify.error("Number should contain 10 digits only");
    }
  };
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    // handle submit button method
    const state = this.state;
    //console.log("Vehicle In submit function..");
    //console.log(this.state);
    event.preventDefault();
    //for docking.js
    if (this.props.click) {
      this.props.click(event);
      return;
    }

    if (
      !(
        state.vehicleNumber &&
        state.vehicleType &&
        state.owner_Type &&
        state.ownerName &&
        state.mobileNumber &&
        state.vehicle_Insurance &&
        state.insurance_Expiry &&
        state.vehicle_RC &&
        state.containMaterial
      )
    ) {
      PNotify.error("All fields are required");
      //console.log(state);
      return;
    } else if (state.mobileNumber.toString().length !== 10) {
      PNotify.error("Number should contain 10 digits");
      return;
    } else if (new Date(this.state.insurance_Expiry) < new Date()) {
      PNotify.error("Insurance is expired.Please Upadte it");
      return;
    } else if (state.owner_Type === "Other" && !state.otherOwnerType) {
      PNotify.error("Please Enter Other Owner Type");
      return;
    }

    const data = {
      ...state,
      inorout: "in",
      location: this.props.location2
    };

    delete data["id"];
    delete data["readOnly"];
    delete data["showspinner"];
    delete data["successmsg"];
    delete data["showCheckMobile"];
    delete data["submitmsg"];
    delete data["showform"];
    delete data["isAlreadyRegistered"];
    delete data["containMaterial"];
    if (state.owner_Type !== "Other") delete data["otherOwnerType"];
    //console.log("data after removing uneccesary data:", data);
    this.setState({ showspinner: true });
    AxiosPost(
      "/addvehicle",
      data,
      res => {
        window.scrollTo(0, 0);
        //console.log(res);
        this.setState({ showspinner: false, successmsg: true });
        if (this.state.containMaterial === "Yes") {
          PNotify.info("Successfully Submit");
          // console.log(
          //   "redirecting to material out ",
          //   this.props.history.location.isMaterialOut
          // );
          if (!this.props.history.location.isMaterialOut)
            this.props.history.push({
              pathname: "/AddEditMaterial",
              vehicleNumber: this.state.vehicleNumber,
              mobileNumber: this.state.mobileNumber,
              //vehicleType: this.state.vehicleType,
              name: this.state.name || this.props.history.location.name,
              customerName: this.state.customerName
              //personID: this.props.history.location.personID,
              //idType: this.props.history.location.idType,
              //personType: this.props.history.location.personType
            });
          else
            this.props.history.push({
              pathname: "/Material_Out",
              vehicleNumber: this.state.vehicleNumber,
              mobileNumber: this.state.mobileNumber,
              //vehicleType: this.state.vehicleType,
              name: this.state.name || this.props.history.location.name,
              customerName: this.state.customerName
            });
        }
      },
      () => {
        this.setState({ showspinner: false });
      }
    );
  }

  mapFields = fields => {
    return fields.map(item => (
      <Col md="6" key={item.name}>
        <Form.Group controlId={item.name}>
          <Form.Label className="required">{item.title}</Form.Label>
          <Form.Control
            disabled={item.disabled ? true : this.state.readOnly}
            required
            type={item.type ? item.type : "text"}
            name={item.name}
            defaultValue={this.state[item.name]}
            onChange={!item.disabled ? this.handleChange : undefined}
            placeholder={"Enter " + item.title}
          />
        </Form.Group>
      </Col>
    ));
  };

  mapDropdown = data => {
    return (
      <Col md="6">
        <Form.Group controlId={data.name}>
          <Form.Label className="required">{data.title}</Form.Label>
          <FormSelect
            disabled={
              data.name === "containMaterial" ? false : this.state.readOnly
            }
            required
            id={data.name}
            name={data.name}
            value={this.state[data.name] || ""}
            onChange={
              !this.state.readOnly || data.name === "containMaterial"
                ? this.handleChange
                : undefined
            }
          >
            <option value="" disabled>
              Choose {data.title}
            </option>
            {data.values.map(val => (
              <option value={val} key={val}>
                {val}
              </option>
            ))}
          </FormSelect>
        </Form.Group>
      </Col>
    );
  };
  render() {
    let pageTitle = "Add Vehicle";
    if (this.props.data) pageTitle = "Check Vehicle";

    let Fields = [
      {
        title: "Vehicle Number",
        name: "vehicleNumber",
        disabled: true
      },
      {
        title: "Mobile Number",
        name: "mobileNumber",
        disabled: true
      },
      {
        title: "Owner Name",
        name: "ownerName"
      },
      {
        title: "Insurance Expiry",
        name: "insurance_Expiry",
        type: "date"
      },
      {
        title: "Vehicle RC",
        name: "vehicle_RC"
      }
    ];

    let insuranceCompanies = [
      "Bajaj Allianz General Insurance Co. Ltd",
      "Bharti AXA General Insurance Co. Ltd",
      "Cholamandalam MS General Insurance Co. Ltd.",
      "Future Generali India Insurance Co. Ltd.",
      "HDFC ERGO General Insurance Co.Ltd.",
      "ICICI Lombard General Insurance Co. Ltd.",
      "IFFCO TOKIO General Insurance Co. Ltd.",
      "Kotak Mahindra General Insurance Co. Ltd.",
      "Liberty General Insurance Ltd.",
      "Magma HDI General Insurance Co. Ltd.",
      "National Insurance Co. Ltd.",
      "Reliance General Insurance Co.Ltd",
      "Royal Sundaram General Insurance Co. Ltd.",
      "SBI General Insurance Co. Ltd.",
      "Shriram General Insurance Co. Ltd.",
      "Tata AIG General Insurance Co. Ltd.",
      "The New India Assurance Co. Ltd",
      "The Oriental Insurance Co. Ltd.",
      "United India Insurance Co. Ltd.",
      "Universal Sompo General Insurance Co. Ltd."
    ];
    if (this.state.successmsg)
      return (
        <div style={{ margin: 20, minHeight: 500, paddingTop: 100 }}>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10
            }}
          >
            <Col md="3" className="mb-4">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <SGActionsCard type="home" title="Go Home" hideFooter />
              </Link>
            </Col>
            <Col md="3">
              <Link
                to={{
                  pathname: "/AddEditMaterial",
                  vehicleNumber: this.state.vehicleNumber,
                  mobileNumber: this.state.mobileNumber,
                  customerName: this.state.customerName,
                  name: this.state.name || this.props.history.location.name
                }}
                style={{ textDecoration: "none" }}
              >
                <SGActionsCard
                  type="material"
                  title="Add Material Entry"
                  hideFooter
                />
              </Link>
            </Col>
          </Row>
        </div>
      );

    return (
      <div
        style={{
          minHeight: "calc(100vh - 120px)",
          display: this.state.showform ? "block" : "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card
          small
          className="m-3 m-md-4"
          style={{ width: this.state.showform ? "auto" : 400 }}
        >
          <LoadingOverlay
            active={this.state.showspinner}
            spinner
            text="Processing..."
          >
            <CardBody>
              <h3 className="p-3">{pageTitle}</h3>
              <div className="p-3">
                {this.state.showCheckMobile && (
                  <Row>
                    <Col sm="12">
                      <Form onSubmit={this.checkMobile}>
                        <Row>
                          <Col sm="12">
                            <Form.Group controlId="mobileNumber">
                              <Form.Label>Mobile Number</Form.Label>
                              <Form.Control
                                // readOnly={this.state.readOnly}
                                autoComplete={"off"}
                                required
                                type="number"
                                name="mobileNumber"
                                value={this.state.mobileNumber}
                                onChange={this.handleChange}
                                placeholder="Enter Your Mobile Number"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm="12" className="submit-button-container">
                            <Button pill className="submit-button">
                              <i className="material-icons"></i>Check
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                )}
                {!this.state.showform && !this.state.showCheckMobile && (
                  <Row>
                    <Col sm="12">
                      <Form onSubmit={this.checkNumber}>
                        <Row>
                          <Col sm="12">
                            <Form.Group controlId="vehicleNumber">
                              <Form.Label>Vehicle Number</Form.Label>
                              <Form.Control
                                // readOnly={this.state.readOnly}
                                required
                                type="text"
                                name="vehicleNumber"
                                value={this.state.vehicleNumber}
                                onChange={this.handleChange}
                                placeholder="Enter Your Vehicle Number"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm="12" className="submit-button-container">
                            <Button pill className="submit-button">
                              <i className="material-icons"></i>Check
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                )}
                {this.state.showform && !this.state.showCheckMobile && (
                  <Row>
                    <Col sm={12}>
                      <Form onSubmit={this.handleSubmit}>
                        <Row>
                          {this.mapFields(Fields.slice(0, 3))}
                          {this.mapDropdown({
                            name: "owner_Type",
                            title: "Owner Type",
                            values: [
                              "Private",
                              "Transporter",
                              "Company Owned",
                              "Other"
                            ]
                          })}

                          {this.state.owner_Type === "Other" &&
                            this.mapFields([
                              { name: "otherOwnerType", title: "Other Type" }
                            ])}
                          {this.mapDropdown({
                            name: "vehicle_Insurance",
                            title: "Vehicle Insurance",
                            values: insuranceCompanies
                          })}
                          {this.mapFields(Fields.slice(3))}

                          {this.mapDropdown({
                            name: "vehicleType",
                            title: "Vehicle Type",
                            values: [
                              "Personal Vehicle(4 wheeler)",
                              "Personal Vehicle(2 wheeler)",
                              "Commercial Vehicle(4 seater)",
                              "Commercial Vehicle(6 seater)",
                              "Commercial Vehicle(7 seater)",
                              "Material Carrier"
                            ]
                          })}
                          {/* {this.state.vehicleType === "Material Carrier" &&
                            this.props.data === undefined && (
                              <Col md="6" className="my-3">
                                <FormRadio
                                  required
                                  name="docking"
                                  checked={this.state.docking === "in"}
                                  onChange={() => {
                                    this.setState({ docking: "in" });
                                  }}
                                >
                                  Material In
                                </FormRadio>
                                <FormRadio
                                  name="docking"
                                  checked={this.state.docking === "out"}
                                  onChange={() => {
                                    this.setState({ docking: "out" });
                                  }}
                                >
                                  Material Out
                                </FormRadio>
                              </Col>
                            )} */}

                          {this.mapDropdown({
                            name: "containMaterial",
                            title: "Contains Material ?",
                            values: ["Yes", "No"]
                          })}
                        </Row>

                        <Row>
                          {[
                            { name: "RC", ref: this.rcRef },
                            { name: "Insurance", ref: this.insuranceRef }
                          ].map(el => (
                            <Col
                              xs="6"
                              className="upload-btn-wrapper"
                              style={
                                el.name === "RC"
                                  ? { textAlign: "right" }
                                  : undefined
                              }
                              key={el.name}
                            >
                              <button className="btn btn-outline-primary">
                                Upload {el.name}
                              </button>
                              <input type="file" name={el.name} ref={el.ref} />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          {this.state.isAlreadyRegistered && (
                            <Col xs="12" className="mt-3">
                              <Form.Group>
                                <Button
                                  className="btn-outline-dark"
                                  onClick={e => {
                                    e.preventDefault();
                                    this.setState({
                                      readOnly: false,
                                      isAlreadyRegistered: false
                                    });
                                    PNotify.info("Edit Enabled");
                                  }}
                                >
                                  <i className="material-icons"></i>Edit
                                </Button>
                              </Form.Group>
                            </Col>
                          )}

                          <Col xs="12" className="submit-button-container">
                            <Form.Group>
                              <Button pill className="submit-button btnBLock">
                                <i className="material-icons"></i>Submit
                              </Button>
                              {/* {this.state.showspinner?<div className="spinner-border d-block  mt-3"></div>
                   :''}  */}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                )}
              </div>
            </CardBody>
          </LoadingOverlay>
          <style>{`
          .form-group label, .form-label {
            color: #487bc9;
          }
          input.form-control, input.form-control:focus{
            color: #000;
            font-weight: normal;
          }
          .submit-button-container {
            margin-top: 20px;
            text-align: center;
          }
          .submit-button {
            min-width: 150px;
            padding: 10px 20px;
            font-size: 16px;
          }
          ._loading_overlay_overlay {
            border-radius: .625rem;
          }
        `}</style>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location2: state.auth.location,
  url: state.auth.url
});
export default connect(mapStateToProps)(AddEditVehicle);
