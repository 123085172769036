import React from "react";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import { Container, Row, Col, Card } from "shards-react";

// import PageTitle from "../components/common/PageTitle";
//import Person from "../components/dashboard_widgets/Person";
//import Material from "../components/dashboard_widgets/Material";
//import Vehicle from "../components/dashboard_widgets/Vehicle";
//import Notification from "../components/dashboard_widgets/Notification";
//import GraphOverview from "../components/dashboard_widgets/GraphOverview";
//import Docking from "../components/dashboard_widgets/Docking";
//import PieChart from "../components/dashboard_widgets/PieChart";
import { connect } from "react-redux";
import SGActionsCard from "../components/dashboard_widgets/SGActionsCard";
import NavbarDropdown from "../components/layout/MainNavbar/NavbarDropdown";
import ViewCustomers from "../components/add_customer/View_Customers";
import ViewLocations from "../components/add_location/View_Locations";

import checkDD from "../components/common/CheckSessionStorage";

const Dashboard = ({ smallStats, history }) => {
  //console.log("%c inside dashboard..", "background:blue");
  // if (!store.getState().auth.dashboard_data)
  // history.push("/");
  let dashDivs = checkDD().dashboard_divs;
  //console.log(dashDivs);
  function checkComp(compoName) {
    return dashDivs.includes(compoName);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {checkComp("LocationsDropdown") && (
          <Col sm="12" className="mb-2">
            <Card
              className="p-4"
              style={{
                boxShadow: "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div className="pr-3 mt-2">
                <Icon style={{ color: "#eee", fontSize: 42 }}>location_on</Icon>
              </div>
              <div>
                <span className="text-uppercase page-subtitle">Location</span>
                <NavbarDropdown />
              </div>
            </Card>
          </Col>
        )}
      </Row>

      <Row>
        {checkComp("PersonWidget") && (
          <Col lg="3" md="6" sm="6" className="mb-4">
            <SGActionsCard type="person" />
          </Col>
        )}

        {checkComp("VehicleWidget") && (
          <Col lg="3" md="6" sm="6" className="mb-4">
            <SGActionsCard type="vehicle" />
          </Col>
        )}

        {checkComp("MaterialWidget") && (
          <Col lg="3" md="6" sm="6" className="mb-4">
            <SGActionsCard type="material" />
          </Col>
        )}

        {checkComp("Registers") && (
          <Col lg="3" md="6" sm="6" className="mb-4">
            <SGActionsCard type="registers" />
          </Col>
        )}
      </Row>

      <Row>
        {checkComp("PersonInOut") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="person" adminView />
          </Col>
        )}

        {checkComp("VehicleInOut") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="vehicle" adminView />
          </Col>
        )}

        {checkComp("MaterialInOut") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="material" adminView />
          </Col>
        )}

        {checkComp("QueueWidget") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="queue" adminView />
          </Col>
        )}
      </Row>
      {/* <Row>
        {checkComp("DockingInward") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="DockingInward" adminView />
          </Col>
        )}
        {checkComp("DockingOutward") && (
          <Col lg="6" md="6" sm="12" className="mb-4">
            <SGActionsCard type="DockingOutward" adminView />
          </Col>
        )}
      </Row> */}

      <Row>
        {checkComp("View_Customers") && (
          <Col>
            <ViewCustomers />
          </Col>
        )}

        {checkComp("View_Locations") && (
          <Col>
            <ViewLocations />
          </Col>
        )}
      </Row>

      <Row>
        {/*<Col lg="6" md="12" sm="12" className="mb-4">
          <GraphData />
        </Col>
        <Col lg="6" md="12" sm="12" className="mb-4">
          <MultiseriesChart />
        </Col>*/}
        {/* <Col lg="12" md="12" sm="12" className="mb-4">
          <Docking />
          //docking.js is also commented
        </Col> */}

        {/* <Col lg="8" md="12" sm="12" className="mb-4">
          <GraphOverview />
        </Col>

        {/* Users by Device */}
        {/* <Col lg="4" md="6" sm="12" className="mb-4">
          <PieChart />
        </Col>  */}
        {/*Material */}
      </Row>

      {/* Small Stats Blocks */}

      <Row>
        {/* {smallStats.map((stats, idx) => (
          <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
            <SmallStats id={`small-stats-${idx}`}
              variation="1"
              chartData={stats.datasets}
              chartLabels={stats.chartLabels}
              label={stats.label}          
              value={stats.value}
              percentage={stats.percentage}
              increase={stats.increase}
              decrease={stats.decrease}
            />
          </Col>
        ))} */}
      </Row>

      {/* <Row style={{ marginTop: "" }}>
        {/* Person */}
      {/* <Col lg="6" md="12" sm="12" className="mb-4">
          <Person />
        </Col> */}

      {/*Material */}
      {/* <Col lg="6" md="12" sm="12" className="mb-4">
          <Material />
        </Col> */}

      {/* Vehicle */}
      {/* <Col lg="6" md="12" sm="12" className="mb-4">
          <Vehicle />
        </Col> */}

      {/* Notification */}
      {/* <Col lg="6" md="12" sm="12" className="mb-4">
          <Notification />
        </Col> */}
      {/* </Row>  */}
    </Container>
  );
};
Dashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

Dashboard.defaultProps = {
  smallStats: [
    {
      //label: "Temperatures",
      // label: "Chamber",
      label: "humidity",
      value: "2,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Chamber",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    }
  ]
};

const mapStateToProps = state => ({
  dashboard_data: state.auth.dashboard_data
});
export default connect(mapStateToProps)(Dashboard);
