import React from "react";
import { Line } from "react-chartjs-2";
// const data={
//     labels:['one','two','three','four'],
//     datasets:[
//         {
//         label:'first label',
//         data:[212,123,232,133,222,233],
//         backgroundColor:["green"]
//     },
//         {
//         label:'Second label',
//         data:[112,223,122,233,322,233],
//         backgroundColor:["red"]
//     },
// ]
// }
function LineChart(props) {
  return (
    <div>
      <Line data={props.data}></Line>
    </div>
  );
}

export default LineChart;
