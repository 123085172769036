import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Row } from "shards-react";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";

import PersonIcon from "../../assets/person.png";
import VehicleIcon from "../../assets/vehicle.png";
import MaterialIcon from "../../assets/material.png";
import QueueIcon from "../../assets/queue.png";
import HomeIcon from "../../assets/home.png";
import DockIcon from "../../assets/self.png";

import ViewAllPersonWrapper from "../add_person/View_person/ViewAllPersonWrapper";
import ViewAllVehicleWrapper from "../add_vehicle/View_vehicle/ViewAllVehicleWrapper";
import ViewAllMaterialWrapper from "../add_material/View_material/ViewAllMaterialWrapper";
import QueueTable from "../dashboard_widgets/QueueTable";
import DockingTable from "../Docking/DockingTable";
//import ViewAllinFacility from '../registers/ViewAllInFacility'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ViewAllInFacility from "../registers/ViewAllInFacility";
import styles from "./SGActionsCard.module.css";
const CardAction = ({ icon, title, type }) => {
  return (
    <div
      style={{ padding: "5px 10px" }}
      id={`${type}-${title.replace(" ", "-")}`}
      className={styles.brightArrow}
    >
      <Icon style={{ fontSize: 30 }}>{icon}</Icon>
    </div>
  );
};

const SGActionsCard = ({ title, type, adminView, hideFooter }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [totalPersonsInFacility, setNumber] = React.useState(null);
  const cardTypes = {
    home: {
      title: "Home",
      src: HomeIcon,
      backgroundColor: "rgba(210, 210, 210, 0.4)",
      footerColor: "rgba(255, 255, 255, 0.4)"
    },
    person: {
      title: "Person",
      src: PersonIcon,
      table: (
        <ViewAllPersonWrapper limitRecords setNumber={num => setNumber(num)} />
      ),
      backgroundColor: "#3d4a89",
      footerColor: "rgba(96, 100, 162, 0.4)",
      links: {
        entry: "/AddEditPerson",
        exit: "/Person_Out",
        logs: "/View_AllPerson"
      }
    },
    vehicle: {
      title: "Vehicle",
      src: VehicleIcon,
      table: <ViewAllVehicleWrapper limitRecords />,
      backgroundColor: "#52bcaa",
      footerColor: "rgba(94, 214, 194, 0.4)",
      links: {
        entry: "/AddEditVehicle",
        exit: "/Vehicle_Out",
        logs: "/View_AllVehicle"
      }
    },
    material: {
      title: "Material",
      src: MaterialIcon,
      table: <ViewAllMaterialWrapper limitRecords />,
      backgroundColor: "#f6b042",
      footerColor: "rgba(240, 202, 14, 0.4)",
      links: {
        entry: "/AddEditMaterial",
        exit: "/Material_Out",
        logs: "/View_AllMaterial"
      }
    },
    queue: {
      title: "Queue",
      src: QueueIcon,
      table: <QueueTable />,
      backgroundColor: "#adaeb2",
      footerColor: "rgba(255, 255, 255, 0.4)"
      // links: {
      //   logs: "/registration"
      // }
    },
    registers: {
      title: "Registers",
      src: QueueIcon,
      backgroundColor: "#adaeb2",
      footerColor: "rgba(255, 255, 255, 0.4)",
      links: {
        logs: "/registers"
      }
    },
    DockingInward: {
      title: "Docking Inward",
      src: DockIcon,
      table: <DockingTable inorout="in" />,
      backgroundColor: "rgba(28,31,41,1)",
      footerColor: "rgba(28,31,41,.60)"
    },
    DockingOutward: {
      title: "Docking Outward",
      src: DockIcon,
      table: <DockingTable inorout="out" />,
      backgroundColor: "rgba(28,31,41,1)",
      footerColor: "rgba(28,31,41,.60)"
    }
    // personsInFacility: {
    //   title: "All Persons in Facilty",
    //   src: DockIcon,
    //   table: <ViewAllinFacility setNumber={(num)=>setNumber(num)}/>,
    //   backgroundColor: "#00b8d8",
    //   footerColor: "#2a2e2f4d",
    //   links: {
    //     logs: "/View_AllMaterial"
    //   }
    // }
  };

  return (
    <Card style={{ backgroundColor: cardTypes[type].backgroundColor }}>
      <CardHeader
        style={{
          backgroundColor: cardTypes[type].backgroundColor,
          display: "flex",
          flexDirection: adminView ? "row" : "column",
          alignItems: "center",
          justifyContent: adminView ? "flex-start" : "center",
          borderRadius: hideFooter ? ".625rem" : ".625rem .625rem 0 0"
        }}
        className={adminView ? "px-3" : "py-4"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: adminView ? 50 : 100,
            height: adminView ? 50 : 100,
            background: "rgba(255,255,255,0.4)",
            borderRadius: "50%",
            marginBottom: adminView ? 0 : 20,
            marginRight: adminView ? 20 : 0,
            padding: adminView ? 0 : "10%"
          }}
        >
          <img
            alt="card"
            src={cardTypes[type].src}
            style={{
              maxWidth: adminView ? 25 : "100%"
            }}
          />
        </div>
        <h5
          className="m-0"
          style={{ color: type === "home" ? "#666" : "#FFF", flex: 2 }}
        >
          {title ? title : cardTypes[type].title}
        </h5>
        {type === "person" && adminView && (
          <div
            onClick={handleShow}
            style={{ color: "#ccc", cursor: "pointer" }}
          >
            Total count:{" "}
            <span style={{ fontSize: 16 }}>{totalPersonsInFacility}</span>
          </div>
        )}

        {/* {type === "personsInFacility" && adminView && (
        <div onClick={handleShow} style={{ color: "#ccc",cursor:'pointer' }}>
          Total count: <span style={{ fontSize: 16 }}>{totalPersonsInFacility}</span>
        </div>
      )} */}
      </CardHeader>
      {adminView && (
        <CardBody className="px-3 py-0" style={{ backgroundColor: "#fff" }}>
          {cardTypes[type].table}
        </CardBody>
      )}
      {!hideFooter && (
        <CardFooter style={{ backgroundColor: cardTypes[type].footerColor }}>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            {type !== "queue" &&
              type !== "registers" &&
              type !== "DockingInward" &&
              type !== "DockingOutward" &&
              type !== "personsInFacility" && (
                <>
                  <Link to={cardTypes[type].links["entry"]}>
                    <CardAction
                      icon="arrow_downward"
                      title="Add Entry"
                      type={type}
                    />
                  </Link>
                  <Link to={cardTypes[type].links["exit"]}>
                    <CardAction
                      icon="arrow_upward"
                      title="Add Exit"
                      type={type}
                    />
                  </Link>
                </>
              )}
            {type !== "DockingInward" &&
              type !== "DockingOutward" &&
              type !== "personsInFacility" &&
              type !== "queue" && (
                <Link to={cardTypes[type].links["logs"]}>
                  <CardAction icon="notes" title="View Logs" type={type} />
                </Link>
              )}
          </Row>
        </CardFooter>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>All Persons In Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewAllInFacility />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};
SGActionsCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  adminView: PropTypes.bool,
  hideFooter: PropTypes.bool
};

export default SGActionsCard;
