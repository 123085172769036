import React, { useState, useEffect } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { useHistory } from "react-router-dom";
import StarsIcon from "@material-ui/icons/Stars";
const QueueTable = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const stateAuth = useSelector(state => state.auth);
  // console.log(stateAuth.location);
  const [SelectedLocation, setLoc] = useSelector(state => state.auth.location);

  useEffect(() => {
    setLoader(true);
    const fetchdata = async () => {
      const first = await axios.post(
        `${stateAuth.url}/get_third_person_auth_queue`,
        {
          location: sessionStorage.selectedLocation
            ? JSON.parse(sessionStorage.selectedLocation)
            : null,
          token: sessionStorage.token ? JSON.parse(sessionStorage.token) : null
        }
      );
      const second = await axios.post(`${stateAuth.url}/get_person_otp_queue`, {
        location: sessionStorage.selectedLocation
          ? JSON.parse(sessionStorage.selectedLocation)
          : null,
        token: sessionStorage.token ? JSON.parse(sessionStorage.token) : null
      });
      const third = await axios.post(
        `${stateAuth.url}/get_special_entry_auth_queue`,
        {
          location: sessionStorage.selectedLocation
            ? JSON.parse(sessionStorage.selectedLocation)
            : null,
          token: sessionStorage.token ? JSON.parse(sessionStorage.token) : null
        }
      );
      setLoader(false);
      //console.log(first.data, second.data, third.data);
      setData([...first.data, ...second.data, ...third.data]);
    };
    fetchdata();
  }, [SelectedLocation]);
  const handleChange = data => {
    //console.log("handleChange called");
    // console.log(otpPerson, mobileNumber);
    let a = { OTPtype: data.OTPtype, mobileNumber: data.mobileNumber };
    if (data.OTPtype === "SpecialPerson")
      a = {
        ...a,
        seThirdPerson: data.third_person.username,
        sePurpose: data.sePurpose,
        seRemarks: data.seRemarks
      };
    history.push({
      pathname: "/AddEditPerson",
      state: a
    });
  };
  if (loader)
    return (
      <div className="p-3">
        <div className="spinner-border d-block m-auto" />
      </div>
    );

  return (
    <>
      {data.length === 0 ? (
        <div className="text-center text-secondary">No data found</div>
      ) : (
        <Table responsive>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} onClick={() => handleChange(item)}>
                {item.OTPtype === "ThirdPerson" && (
                  <>
                    <td> {<PeopleAltIcon style={{ color: "cadetblue" }} />}</td>
                    {item.mobileNumber && <td> {item.mobileNumber}</td>}
                    <td>
                      {" "}
                      {"Otp Sent To "}
                      {item.whomToMeet && item.whomToMeet.username}
                      {item.customer && item.customer.username}
                      {item.reportingManager && item.reportingManager.empName}
                    </td>
                  </>
                )}
                {item.OTPtype === "FirstPerson" && (
                  <>
                    <td> {<ContactMailIcon style={{ color: "brown" }} />}</td>
                    {item.mobileNumber && <td> {item.mobileNumber}</td>}
                    <td> {"Otp Sent To You"}</td>
                  </>
                )}
                {item.OTPtype === "SpecialPerson" && (
                  <>
                    <td> {<StarsIcon style={{ color: "#4d5592" }} />}</td>
                    {item.mobileNumber && <td> {item.mobileNumber}</td>}
                    <td> {"Special Entry OTP"}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default QueueTable;
