import React from "react";
import { Nav } from "shards-react";
import MetisMenu from 'react-metismenu';
import './../../../assets/react-metismenu-standart.min.css';

import { Store } from "../../../flux";
import {connect} from 'react-redux'

import RouterLink from 'react-metismenu-router-link'
class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
      menudata:this.props.dashboard_data?this.props.dashboard_data.role[0].navbarMenus:[],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  componentDidMount(){
    //console.log('dashboard_data:',this.state.menudata);
    
  }
  render() {
    //const { navItems: items } = this.state;
  //   const menudata=this.props.dashboard_data?this.props.dashboard_data[0].navbarMenus:[]
  //  console.log("Menu data: ",menudata)
  
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
        <MetisMenu content={this.state.menudata}
         //activeLinkFromLocation 
        LinkComponent={RouterLink}
        />

        </Nav>
      </div>
    )
  }
}
const mapStateToProps=state=>({
  dashboard_data:state.auth.dashboard_data
})
export default connect(mapStateToProps)(SidebarNavItems);

