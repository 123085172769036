import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Navbar } from "shards-react";
import { Link } from "react-router-dom";
import NavbarNav from "./NavbarNav/NavbarNav";
//common.css is used
const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar"
    // "bg-white",
    // stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>
      <div className="p-0">
        <Navbar
          type="light"
          className="align-items-stretch  flex-md-nowrap p-0 flex-column flex-sm-row"
        >
          {/* <NavbarSearch /> */}
          {/* <NavbarDropdown /> */}
          <Link to="/Dashboard" className="text-decoration-none">
            <div
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "#003131",
                width: "100%",
                paddingTop: 10
              }}
            >
              <span style={{ fontWeight: 900 }}>mool</span>
              <span style={{ fontWeight: 100 }}>WMS</span>
            </div>
          </Link>
          <NavbarNav />

          {/* <NavbarToggle /> */}
        </Navbar>
      </div>
      <style>{`
        .main-navbar {
          box-shadow: none!important;
          padding: 0 30px;
        }
      `}</style>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
